import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../actions/userActions'

import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { listSubscriptions } from '../../../actions/subscriptionActions'
import Loader from '../../../components/Loader'
import { FaPowerOff } from 'react-icons/fa'

const SubscriptionList = ({ history, match }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const listSubscriptionRes = useSelector((state) => state.listSubscription)
  const { success, subscriptions, loading } = listSubscriptionRes
  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    }
    if (userLoggedIn && !userLoggedIn.isSubscribed) {
      history.push(`/app/profile/billing`)
    }
    dispatch(listSubscriptions())
  }, [dispatch, history, userLoggedIn])
  return (
    <>
      {loading && !success ? (
        <Loader></Loader>
      ) : (
        <Container style={{ marginTop: '60px' }}>
          <Row>
            <p
              className='text-center'
              style={{ textTransform: 'capitalize', fontSize: '20px' }}
            >
              {' '}
              Hello,{' '}
              <strong>
                {userLoggedIn.name ? userLoggedIn.name : userLoggedIn.email}
              </strong>
            </p>
          </Row>
          <Row>
            <Col>
              {subscriptions && subscriptions.length == 0 && (
                <div style={{ textAlign: 'center' }}>
                  <h3>Why Subscribe?</h3>

                  <p>
                    <strong>Save Time</strong> Get the key insights from major
                    non-fiction books in just a few minutes.
                  </p>
                  <p>
                    <strong>Learn More </strong>
                    Explore new topics without committing to hundreds of pages.
                  </p>
                  <br />
                  <Link to={'/app/profile/billing'}>
                    <Button className='subprofile'>Subscribe Now</Button>
                  </Link>
                </div>
              )}
              {subscriptions &&
                subscriptions.length > 0 &&
                subscriptions.map((s) => (
                  <div style={{ textAlign: 'center' }}>
                    {s.name}
                    <br />
                    Your Subscription is
                    <br />
                    <span className='topic'>Active</span>
                  </div>
                ))}
            </Col>
          </Row>
          <Row>
            <Col className='logoutSection'>
              <Button
                variant='primary'
                className='btn'
                style={{ float: 'center', marginTop: '5%' }}
                onClick={logoutHandler}
              >
                <FaPowerOff style={{ color: '#fff' }} />
              </Button>
              <br />
              <small>Logout</small>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}
{
}

export default SubscriptionList
