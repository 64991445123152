import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col,Table,Modal, Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {flowDetails,listFlows,createFlowTrigger,deleteFlowTrigger} from '../actions/flowActions'


const FlowDetailScreen = ({ match, history }) => {
    const flowId = match.params.id

    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [flow, setFlow] = useState();
    const [template, setTemplate] = useState('');
    const [subjectLine, setSubjectLine] = useState('');
    const [delayType, setDelayType] = useState('');
    const [delayTime, setDelayTime] = useState('');

    const flowList = useSelector(state => state.flowList)
    const { success:flowListSuccess, loading:flowListLoading,flows } = flowList

    const flowDetail = useSelector(state => state.flowDetail)
    const { success,loading, error, flowTriggers } = flowDetail
    

    const flowTriggerDelete = useSelector(state => state.flowTriggerDelete)
    const { loading:flowTriggerDeleteSuccess } = flowTriggerDelete

    useEffect(() => {
            dispatch(flowDetails(flowId))
    }, [dispatch,flowId,flowTriggerDeleteSuccess])

    const createFlowTriggerHandler=()=>{
        dispatch(listFlows())
        setShow(true)
    }
    const submitFlowTriggerHandler=()=>{
       dispatch(createFlowTrigger(flow,template,subjectLine,delayType,delayTime))
    }
    const deleteHandler=(id)=>{
        dispatch(deleteFlowTrigger(id))
    }
    return (
            <Container>
            <Row className='align-items-center'>
            <Col>
                <h1>Flow Details</h1>
            </Col>
            <Col className='text-right'>
                <Button className='my-3' onClick={createFlowTriggerHandler}>
                    <i className='fas fas-plus'>Create Trigger</i>
                </Button>
            </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {loading?<Loader></Loader>:error?<Message variant='danger'>{error}</Message>:(
                        <>
                        <Table striped bordered hover responsive className='table-sm'>
                        <thead>
                            <tr>
                            <th>Flow</th>
                            <th>Template</th>
                            <th>Subject Line</th>
                            <th>Delay</th>
                            <th>Last 24 Hours</th>
                            <th>Last 7 days</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {flowTriggers.map(trigger => (
                                <tr key={trigger._id}>
                                    <td>{trigger.flow.name} </td>
                                    <td>{trigger.template}</td>
                                    <td>{trigger.subjectLine}</td>
                                    <td>{trigger.delayTime} {trigger.delayType==1?'Seconds':trigger.delayType == 2?'Minutes':trigger.delayType==3?'Hours':'Days'}</td>
                                    <td>{trigger.actionsCountLast24hours}</td>
                                    <td>{trigger.actionsCountLast7days}</td>
                                    <td>
                                    <Button variant='danger' className='btn-sm' onClick={()=>
                                    deleteHandler(trigger._id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </Button>
                                    </td>  
                                </tr>
                            ))}
                        </tbody>
                        </Table>
                        </>
                        )}
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Create Trigger</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={submitFlowTriggerHandler}>
                <Form.Group controlId='flow'>
                        <Form.Label>
                           Select Flow
                        </Form.Label>
                        {(flowListLoading) ? <Loader></Loader> : 
                        (
                            flows.map(flow => (
                                <Row key={flow.name}>
                                    <Col xs={1}><Form.Check type='radio' id={flow.name} name='flow' value={flow.name}  onChange={(e) => setFlow(e.target.value)}>
                                    </Form.Check>
                                        </Col>
                                        <Col xs={11}><Form.Label> {flow.name}</Form.Label></Col>
                                </Row>
                            ))
                            ) } 
                    </Form.Group>
                    <Form.Group controlId='template'>
                    <Form.Label>
                        Template Name
                    </Form.Label>
                    <Form.Control type='text' placeholder='Enter Template Name' value={template} onChange={(e) => setTemplate(e.target.value)}>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='subjectline'>
                    <Form.Label>
                        Subject Line
                    </Form.Label>
                    <Form.Control type='text' placeholder='Enter Subject Line' value={subjectLine} onChange={(e) => setSubjectLine(e.target.value)}>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="delayType">
                    <Form.Label>Select Delay Type</Form.Label>
                    <Form.Control
                    as="select"
                    value={delayType}
                    onChange={e => {
                        console.log("e.target.value", e.target.value);
                        setDelayType(e.target.value);
                    }}
                    name="delayType"
                    >
                    <option value="">Select Delay Type</option>
                    <option value="1">Seconds</option>
                    <option value="2">Minutes</option>
                    <option value="3">Hours</option>
                    <option value="4">Days</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='delayTime'>
                    <Form.Label>
                        Delay Time
                    </Form.Label>
                    <Form.Control type='text' placeholder='Enter Delay Time' value={delayTime} onChange={(e) => setDelayTime(e.target.value)}>
                    </Form.Control>
                </Form.Group>
                <Button type='submit' varaint='primary'>
                    Create Flow
                </Button><br/>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            </Container>
        
    )
}

export default FlowDetailScreen
