import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { isWebview } from '../helpers/helpers'
import SocialShare from './SocialShare'
const cookies = new Cookies()

const Footer = ({}) => {
  const location = useLocation()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const urlParams = new URLSearchParams(location.search)
  const utmSource = urlParams.get('utm_source')
  const utmMedium = urlParams.get('utm_medium')
  const utmCampaign = urlParams.get('utm_campaign')
  if (utmSource !== null) {
    cookies.set('utm_source', utmSource, {
      path: '/',
      maxAge: 34550000,
    })
    cookies.remove('utm_medium')
    cookies.remove('utm_campaign')
  }
  if (utmMedium !== null) {
    cookies.set('utm_medium', utmMedium, {
      path: '/',
      maxAge: 34550000,
    })
  }
  if (utmCampaign !== null) {
    cookies.set('utm_campaign', utmCampaign, {
      path: '/',
      maxAge: 34550000,
    })
  }
  if (urlParams.get('country') !== null) {
    cookies.set('country', urlParams.get('country'), {
      path: '/',
      maxAge: 34550000,
    })
  }

  return (
    <Container>
      <footer>
        {location.pathname.indexOf('app') > 0 ||
        location.pathname.indexOf('register') > 0 ||
        location.pathname.indexOf('login') > 0 ||
        !isWebview ? (
          ''
        ) : location.pathname.indexOf('99bookscart-surprise-box') > 0 ? (
          ''
        ) : (
          <>
            <Row className='p-2 rounded '>
              <Col md={12}>
                <div style={{ width: '100%' }} className='site-footer'>
                  <div style={{ float: 'left' }}>
                    {' '}
                    <h5>Social</h5>
                    <a href='https://www.facebook.com/NexNuggets' target='new'>
                      <i className='fab fa-facebook'></i>
                    </a>
                    &nbsp;
                    <a href='https://www.instagram.com/NexNuggets' target='new'>
                      <i className='fab fa-instagram'></i>
                    </a>
                    &nbsp;
                    <a href='https://twitter.com/NexNuggets' target='new'>
                      <i className='fab fa-twitter'></i>
                    </a>
                    &nbsp;
                    <a
                      href='https://www.linkedin.com/company/nexnuggets'
                      target='new'
                    >
                      <i className='fab fa-linkedin'></i>
                    </a>
                  </div>
                  <div style={{ float: 'right', color: '#022335' }}>
                    <div>
                      <Link to='/pages/terms-and-condition'>
                        Terms and Condition
                      </Link>{' '}
                      | <Link to='/pages/privacy-policy'>Privacy Policy</Link>|
                      <Link to='/pages/about-us'>Login</Link> |{' '}
                      <Link to='/contact-us'>Pricing</Link>
                    </div>
                    <i className='fas fa-envelope-square'></i>{' '}
                    hello@NexNuggets.com
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </footer>{' '}
      <div className='site-copyright'>
        <Row>
          <Col className='text-center py-3 '>
            &copy; NexNuggets 2024 <br />{' '}
            <Link to='/pages/about-us'>About Us</Link> |{' '}
            <Link to='/contact-us'>Contact Us</Link>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Footer
