import { ASSIGN_AWB_FAIL, ASSIGN_AWB_REQUEST, ASSIGN_AWB_SUCCESS, GENERATE_SHIPPMENT_FAIL, GENERATE_SHIPPMENT_REQUEST, GENERATE_SHIPPMENT_SUCCESS, GET_SHIPPMENT_FAIL, GET_SHIPPMENT_REQUEST, GET_SHIPPMENT_SUCCESS } from "../constants/shipmentConstants"

export const shipmentCreateReducer = (state={success:false}, action)=>{
    switch(action.type){
        case GENERATE_SHIPPMENT_REQUEST:
            return{
                loading:true
            }
        case GENERATE_SHIPPMENT_SUCCESS:
            return{
                loading:false,
                success:true,
                shipment:action.payload
            }
        case GENERATE_SHIPPMENT_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        default:
            return state
    }
}

export const assignAWBReducer = (state={success:false}, action)=>{
    switch(action.type){
        case ASSIGN_AWB_REQUEST:
            return{
                loading:true
            }
        case ASSIGN_AWB_SUCCESS:
            return{
                loading:false,
                success:true,
                shipment:action.payload
            }
        case ASSIGN_AWB_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        default:
            return state
    }
}

export const getShipmenteducer = (state={success:false}, action)=>{
    switch(action.type){
        case GET_SHIPPMENT_REQUEST:
            return{
                loading:true
            }
        case GET_SHIPPMENT_SUCCESS:
            return{
                loading:false,
                success:true,
                shipment:action.payload
            }
        case GET_SHIPPMENT_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        default:
            return state
    }
}