import React, { useState } from 'react'
import { Card, Button, Modal, Image } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen, FaInfoCircle } from 'react-icons/fa'

const Product = ({ product, history, match, addtocart = true }) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const slug = convertToSlug(product.name)
  let shortlistedBook = false
  cartItems.map((item) => {
    if (item.product == product._id) {
      shortlistedBook = true
    }
  })

  const goToCartHandler = () => {
    history.push(`/cart`)
  }
  const addToCartHandler = () => {
    dispatch(addToCart(product._id, 1))
    setDisable(true)
  }
  return (
    <>
      <Card className='rounded py-1'>
        <Link to={`/book/${product.isbn}/${slug}`} className='book-image'>
          <amp-img
            src={product.image}
            variant='top'
            alt={product.name}
            className='book-image'
            layout='responsive'
            height='300'
            width='200'
          ></amp-img>
        </Link>
        <Card.Body>
          <Link
            to={`/book/${product.isbn}/${slug}`}
            style={{ textDecoration: 'none' }}
          >
            <Card.Title as='div' className='book-title'>
              <span className='title-main'>{product.name.toLowerCase()}</span>
            </Card.Title>
          </Link>
          {product.type == 'New' && (
            <Card.Subtitle className='new-books text-center'>
              New Book
            </Card.Subtitle>
          )}

          {product.grating > 0 && <Rating value={product.grating} />}

          {/* <small className='float-right instock'>{ (product.countInStock < 3 && product.countInStock!=0) &&product.countInStock+" left"}</small> */}

          {/* { product.rating != 0 ?
                    <Card.Text as='div' className='product-page-rating'>
                        <Rating value={product.rating} text={`${product.numReviews}`}></Rating>
                    </Card.Text>
                :''} */}

          <Card.Text className='text-center'>
            <small>
              {/* {product.isAttribute && <FaInfoCircle></FaInfoCircle>}{' '} */}
              <span className='productPrice'>&#8377;{product.salePrice}</span>{' '}
              {/* <strike>&#8377;{product.price}</strike> */}
              <small className='red-text'>
                -{Math.round(100 - (product.salePrice / product.price) * 100)}%
              </small>
            </small>
          </Card.Text>

          {/* {addtocart && (
            <div className='text-center'>
              <Button
                className='btn-block mt-auto'
                size='sm'
                onClick={addToCartHandler}
                type='button'
                disabled={
                  product.countInStock == 0 || disable || shortlistedBook
                }
              >
                {!shortlistedBook ? (
                  product.countInStock == 0 ? (
                    'No Stock'
                  ) : (
                    <i className='fa fa-box-open'> Add to cart</i>
                  )
                ) : (
                  'In cart'
                )}
              </Button>
              <div>
                {shortlistedBook && (
                  <>
                    <div className='very-small-line-height'>
                      <Link to='/cart'>View Cart</Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          )} */}

          {/* <small>{cartItems.length+"Books Shortlisted"}</small> */}
        </Card.Body>
      </Card>
      {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Box Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>Box Type: {cartType}<br/>Books: {cartItems.length} 
            <p><BoxProgressBar value={cartItems.length}/></p>
            <Link to='/cart'><Button className='float-right'>Go to Cart</Button></Link>
            </Modal.Body>
      </Modal>
       */}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default Product
