import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  ListGroupItem,
  Modal,
  Container,
} from 'react-bootstrap'
import { register } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import SocialLogin from '../components/SocialLogin'
import LoginForm from './litplots/LoginForm'
import StepFunnel from './litplots/StepFunnel'

const SubNav = ({ history }) => {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const userRegister = useSelector((state) => state.userRegister)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const location = useLocation()
  var url = ''
  if (userLoggedIn) {
    url = '/app/profile/billing'
  } else {
    url = '/'
  }

  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const showMore = () => {
    if (userLoggedIn) {
      history.push('/app')
    } else {
      setShow(true)
      //history.push('/register')
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  return (
    <Container>
      <Row>
        <Col>
          <Link to={url}>
            <img
              src='../../images/logo.png'
              className='siteLogo2'
              alt='litplots logo'
            />
          </Link>
        </Col>
        {location.pathname.indexOf('register') == -1 && (
          <Col>
            <div style={{ float: 'right', marginTop: '30px' }}>
              <Button onClick={showMore}>Sign up</Button>
            </div>
          </Col>
        )}
      </Row>
      <Modal show={show} onHide={handleClose} backdrop='static'>
        <StepFunnel history={history} />
      </Modal>
    </Container>
  )
}

export default SubNav
