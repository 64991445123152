import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, listStoryByCat } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'

import ReactHtmlParser from 'react-html-parser'
import { register } from '../../../actions/userActions'
import SocialLogin from '../../../components/SocialLogin'
import Loader from '../../../components/Loader'
import Story from '../../../components/Story'
import SubNav from '../../../components/SubNav'
import PlotListPaginate from '../../../components/PlotListPaginate'
import { makeTitle } from '../../../helpers/helpers'
import { Helmet } from 'react-helmet'
import LoginForm from '../../../components/litplots/LoginForm'
import { useLocation } from 'react-router-dom'
const PlotListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const category = match.params.category || ''
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const storybyCat = useSelector((state) => state.storybyCat)
  const { stories, loading, error, suceess, pages, page } = storybyCat
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  var currency = searchParams.get('currency') || 'INR'
  if (currency != 'INR') {
    localStorage.setItem('currency', 'USD')
  } else {
    localStorage.setItem('currency', 'INR')
  }
  const handleClose = () => {
    setShow(false)
  }

  const showMore = () => {
    setShow(true)
  }
  useEffect(() => {
    // if (!userInfo) {
    //   history.push('/register')
    // }
    window.scrollTo(0, 0)
    dispatch(listStoryByCat(category, pageNumber))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app')
      }
    }
  }, [dispatch, pageNumber, category, userInfo, registerSuccess, emailError])
  return (
    <>
      <Container>
        <Helmet>
          <title>
            Read key ideas from {category.replace(/-/g, ' ')} Books! Read Smart
            & Read Fast
          </title>
          <meta charset='UTF-8' />
          <meta
            name='title'
            content={
              'Read key ideas from' +
              match.params.category.replace(/-/g, ' ') +
              ' Books! Read Smart & Read Fast'
            }
          ></meta>
          <meta
            name='description'
            content={
              'Read key ideas from' +
              match.params.category.replace(/-/g, ' ') +
              ' Books! Read Smart & Read Fast'
            }
          ></meta>
          <meta
            name='keyword'
            content={
              'Read key ideas from' +
              match.params.category.replace(/-/g, ' ') +
              ' Books! Read Smart & Read Fast'
            }
          ></meta>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0'
          ></meta>
          <meta name='robots' content='index, follow' />
        </Helmet>
        <SubNav history={history}></SubNav>

        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <>
            <div style={{ fontSize: '24px' }}>{makeTitle(category)} Books</div>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <Story history={history} story={story} />
                      )}
                    />
                  </Col>
                ))}
              <PlotListPaginate
                pages={pages}
                page={page}
                category={match.params.category}
              ></PlotListPaginate>
            </Row>
            <Modal show={show} onHide={handleClose} backdrop='static'>
              <LoginForm history={history}></LoginForm>
            </Modal>
          </>
        )}
      </Container>
    </>
  )
}
{
}

export default PlotListScreen
