import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Table, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  createDiscountCode,
  listDiscountCode,
} from '../actions/discountCodeActions'
import { subscriberEmailList } from '../actions/cartActions'

const SubscriberListScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [value, setValue] = useState('')

  const dispatch = useDispatch()

  const subscriberedEmailList = useSelector(
    (state) => state.subscriberedEmailList
  )
  const { success, loading, error, subscribers } = subscriberedEmailList

  useEffect(() => {
    dispatch(subscriberEmailList())
  }, [dispatch])

  return (
    <Container>
      <h1>Email Subscribers</h1>
      <Row>
        <Col xs={12}>
          {loading ? (
            <Loader></Loader>
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>CreatedAt</th>
                  </tr>
                </thead>
                <tbody>
                  {subscribers.map((subscriber) => (
                    <tr key={subscriber._id}>
                      <td>{subscriber.email}</td>
                      <td>
                        <small>
                          {subscriber.createdAt.substring(0, 10)}
                          {subscriber.createdAt.substring(19, 11)}
                        </small>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default SubscriberListScreen
