import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../actions/userActions'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import SocialLogin from '../SocialLogin'
import { LinkContainer } from 'react-router-bootstrap'
import { TextField } from '@mui/material'
function NewLogin({ history, userInputs }) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [emailError, setEmailError] = useState('')
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  useEffect(() => {
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (phone.length < 10) {
      setPhoneError('Enter valid phone number')
    } else if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({
          name,
          email,
          password,
          isSocial: false,
          shorts: true,
          userInputs,
          phone,
        })
      )
    }
  }
  return (
    <Modal.Body>
      <Form onSubmit={submitHandler2}>
        {/* <div>
          <Button className='close-button' onClick={handleClose}>
            X
          </Button>
        </div> */}
        <div style={{ padding: '20px' }}>
          <Form.Group>
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
              <div className='social-logins'>
                <SocialLogin userInputs={userInputs} />
              </div>
            </div>
            <Form.Text className='red-text'>
              {errorMessage && <span>{errorMessage}</span>}
            </Form.Text>
            {/* <TextField
              id='outlined-basic'
              label='Full Name'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            /> */}
            <Form.Text className='red-text'>
              {phoneError && <span>{phoneError}</span>}
            </Form.Text>
            <TextField
              id='outlined-basic'
              label='Phone'
              variant='outlined'
              required
              type='tel'
              autoComplete='off'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Text className='red-text'>
            {emailError && <span>{emailError}</span>}
          </Form.Text>
          <TextField
            id='outlined-basic'
            label='Email Address'
            variant='outlined'
            required
            type='Email'
            autoComplete='off'
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <Form.Text className='red-text'>
            {passwordError && <span>{passwordError}</span>}
          </Form.Text>
          <TextField
            id='outlined-basic'
            label='Password'
            variant='outlined'
            required
            type='Password'
            autoComplete='off'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />

          <Row></Row>
        </div>
        <Row>
          <Button type='submit' variant='primary' className='float-right'>
            Sign Up
          </Button>
        </Row>
      </Form>
    </Modal.Body>
  )
}

export default NewLogin
