import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import { convertToSlug } from '../helpers/helpers'
const SocialShare = ({ story, verticle = false }) => {
  const url = `https://nexnuggets.com/en/book/${convertToSlug(story.title)}/${
    story._id
  }`
  return (
    <div style={{marginTop:'15px'}}>
      <h5>Share</h5>
      <FacebookShareButton
        url={url}
        quotes={'Hey NexNuggets.com is amazing! Check this Book'}
        hashtag={'#NexNuggets'}
      >
        <FacebookIcon size={36} round={true} />
      </FacebookShareButton>
      {verticle && <br />}
      <TwitterShareButton
        url={url}
        title={story.title}
        related={['NexNuggets']}
        hashtag={'#NexNuggets'}
      >
        <TwitterIcon size={36} round={true} />
      </TwitterShareButton>
      {verticle && <br />}
      <WhatsappShareButton title={story.title} url={url}>
        <WhatsappIcon size={36} round={true} />
      </WhatsappShareButton>
      {verticle && <br />}
      <LinkedinShareButton
        url={url}
        summary={'Hey NexNuggets.com is amazing! Check this Book'}
        source={'NexNuggets'}
      >
        <LinkedinIcon size={36} round={true} />
      </LinkedinShareButton>
    </div>
  )
}

export default SocialShare
