import axios from 'axios'
import { AI_FAIL, AI_REQUEST, AI_SUCCEES } from '../constants/aiConstants'

import {
  COLLECTION_GET_FAIL,
  COLLECTION_GET_REQUEST,
  COLLECTION_GET_SUCCESS,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
} from '../constants/collectionConstants'

export const listCollection = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COLLECTION_LIST_REQUEST })

    const { data } = await axios.get(`/api/collection`)
    dispatch({
      type: COLLECTION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COLLECTION_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getCollection = (slug) => async (dispatch, getState) => {
  try {
    dispatch({ type: COLLECTION_GET_REQUEST })

    const { data } = await axios.get(`/api/collection/${slug}`)
    dispatch({
      type: COLLECTION_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COLLECTION_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
