import React, { useState } from 'react'
import { Card, Button, Modal, Image, Row, Col } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions.js'
import { useDispatch, useSelector } from 'react-redux'
import BoxProgressBar from './BoxProgressBar'
import { FaBoxOpen } from 'react-icons/fa'
import { FaFontAwesome, FontAwesomeIcon } from 'react-icons/fa'
import { FaBookReader, FaStar, FaList, FaClock } from 'react-icons/fa'
const StoryStats = ({ story, history, match, addtocart = true }) => {
  const dispatch = useDispatch()
  // const [show, setShow] = useState(false);
  const [disable, setDisable] = React.useState(false)
  return (
    <>
      <Row className='storystatsrow'>
        <Col xs={3}>
          <div className='storiestatslabel'>
            <FaBookReader /> Reads
          </div>
          <div className='storiestatsvalue'>{story.totalReadCount}</div>
        </Col>
        <Col xs={3}>
          <div className='storiestatslabel'>
            <FaStar /> Votes
          </div>
          <div className='storiestatsvalue'>{story.totalVoteCount}</div>
        </Col>
        <Col xs={3}>
          <div className='storiestatslabel'>
            <FaList /> Ideas
          </div>
          <div className='storiestatsvalue'>{story.episodes.length}</div>
        </Col>
        <Col xs={3}>
          <div className='storiestatslabel'>
            <FaClock /> Time
          </div>
          <div className='storiestatsvalue'>{story.totalReadingTime}</div>
        </Col>
      </Row>
    </>
  )
}

export default StoryStats
