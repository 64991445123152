import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  getOrderDetails,
  deliverOrder,
  cancelOrder,
  confirmOrder,
  createOrderComment,
  addTagtoOrder,
} from '../../actions/orderActions'
import {
  createShipment,
  assignAwb,
  getShipmentDetails,
} from '../../actions/shipmentActions'
import {
  ORDER_COMMENT_RESET,
  ORDER_DELEVERED_RESET,
} from '../../constants/orderConstants'
import { jsPDF } from 'jspdf'

const AdminOrderScreen = ({ match, history }) => {
  const orderId = match.params.id
  const razorId = match.params.razid

  const dispatch = useDispatch()
  const [height, setHeight] = useState(false)
  const [weight, setWeight] = useState(false)
  const [length, setLength] = useState(false)
  const [width, setWidth] = useState(false)

  const [show, setShow] = useState(false)
  const [tLink, setTLink] = useState('')
  const [courierId, setCourierId] = useState('')
  const [shipmentId, setShipmentId] = useState('')
  const [showDimensions, setShowDimensions] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [tags, setTags] = useState([])

  const [showAvailableCourier, setShowAvailableCourier] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseDimension = () => setShowDimensions(false)
  const handleCloseAvailableCourier = () => setShowAvailableCourier(false)

  const [comment, setComment] = useState('')
  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error, success, existingOrders, countOrders } =
    orderDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver

  const orderPayStatus = useSelector((state) => state.orderPayStatus)
  const { loading: orderPayStatusLoading } = orderPayStatus

  const orderComments = useSelector((state) => state.orderCommentList)
  const {
    success: successComment,
    error: errorComments,
    comments,
  } = orderComments

  const shipmentCreate = useSelector((state) => state.shipmentCreate)
  const {
    success: successShipment,
    error: errorShipment,
    loading: loadingShipment,
    shipment,
  } = shipmentCreate

  const assignAWB = useSelector((state) => state.assignAWB)
  const {
    success: successIShipment,
    error: errorIShipment,
    loading: loadingIShipment,
    shipment: iShipment,
  } = assignAWB

  const getShipment = useSelector((state) => state.getShipment)
  const {
    success: successOrderShipment,
    error: errorOrderShipment,
    loading: loadingOrderShipment,
    shipment: OrderShipment,
  } = getShipment

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
    if (
      (!order ||
        order._id !== orderId ||
        successDeliver ||
        orderPayStatusLoading) &&
      !loading
    ) {
      dispatch(getOrderDetails(orderId))
      // dispatch(getShipmentDetails(orderId))
      if (successDeliver) {
        dispatch({ type: ORDER_DELEVERED_RESET })
      }
    }
    if (successComment) {
      setComment('')
      dispatch({ type: ORDER_COMMENT_RESET })
    }
    if (successIShipment) {
      setShowAvailableCourier(false)
      history.go(0)
    }
  }, [
    dispatch,
    order,
    history,
    orderId,
    successDeliver,
    orderPayStatusLoading,
    loading,
    successComment,
    successIShipment,
  ])
  const addTags = () => {
    dispatch(addTagtoOrder(tags, order._id))
    lineItems.push(tags)
    setTags('')
  }
  const removeTags = (item, e) => {
    e.preventDefault()
    const index = lineItems.indexOf(item)
    if (index > -1) {
      lineItems.splice(index, 1)
    }
    setLineItems(lineItems)
  }
  const deliverHandler = () => {
    // dispatch(deliverOrder(order, tLink))
    alert("ASDasdsad")
    setShow(false)
    setTLink('')
  }
  const cancelHandler = () => {
    dispatch(cancelOrder(order))
    history.push('/admin/orderlist')
  }
  const confirmHandler = () => {
    dispatch(confirmOrder(order))
    history.push('/admin/orderlist')
  }
  const commentHandler = (e) => {
    e.preventDefault()
    dispatch(
      createOrderComment(match.params.id, {
        comment,
      })
    )
  }
  const printHandler = () => {
    const doc = new jsPDF('p', 'pt', 'a4')
    document.querySelector('.print-label').style.display = 'block'
    doc.html(document.querySelector('.print-label'), {
      callback: function (pdf) {
        doc.output('dataurlnewwindow')
      },
    })
  }
  const generateShipment = () => {
    setShowDimensions(true)
  }

  const createShipmentHandler = () => {
    dispatch(createShipment(order, weight, height, width, length))
    setShowDimensions(false)
    setShowAvailableCourier(true)
  }
  const selectCourierServiceHandler = (e) => {
    e.preventDefault()
    dispatch(assignAwb(order._id, shipment.shipmentId, courierId))
  }
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <Row className='print-test'>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p>
                    <strong>{order.orderName}</strong>
                    <br />
                    {order.isCancel ? 'CANCELLED' : order.status}
                    <br />
                    {order.status == 'SHIPPED' && (
                      <a href={`${order.trackingLink}`} target='_blank'>
                        Track your order
                      </a>
                    )}
                  </p>
                  {/* <p><strong>Email:</strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p> */}
                  <p className='text-transform'>
                    {' '}
                    <strong>Name: </strong>{' '}
                    {order.user ? order.user.name : order.shippingAddress.name}
                    <br />
                    <strong>Email: </strong>{' '}
                    {order.user
                      ? order.user.email
                      : order.shippingAddress.email}
                    <br />
                    <strong>Address: </strong>
                    {order.shippingAddress.address},
                    {order.shippingAddress.address2},<br />
                    <strong>City: </strong> {order.shippingAddress.city}
                    <br />
                    <strong>State: </strong> {order.shippingAddress.state}
                    <br />
                    <strong>Zip Code: </strong>{' '}
                    {order.shippingAddress.postalCode}
                    <br />
                    <strong>Phone: </strong> {order.shippingAddress.phone}
                  </p>
                </ListGroup.Item>
                {userInfo.isAdmin && (
                  <ListGroup.Item>
                    <Form.Group className='mb-3' controlId='tags'>
                      <Form.Label>Tags</Form.Label>
                      <Row>
                        <Col xs={12}>
                          <Form.Control
                            type='text'
                            placeholder='Tags'
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                          />
                        </Col>
                        <Col xs={12}>
                          <Button
                            variant='primary'
                            className='mb-10'
                            style={{ float: 'left' }}
                            onClick={addTags}
                          >
                            Add Tags
                          </Button>
                        </Col>
                      </Row>

                      {lineItems.length > 0 && (
                        <div>
                          {lineItems.map((item) => (
                            <small className='topic' key={item}>
                              {item}{' '}
                              <Link to='' onClick={(e) => removeTags(item, e)}>
                                X
                              </Link>
                            </small>
                          ))}
                        </div>
                      )}
                    </Form.Group>
                    <Form onSubmit={commentHandler}>
                      <FormGroup controlId='comment'>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as='textarea'
                          value={comment}
                          row='3'
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </FormGroup>
                      <Button type='submit' variant='primary'>
                        Comment
                      </Button>
                    </Form>
                    <h5>Comments</h5>
                    {order.comments.length == 0 && (
                      <Message>No Comments</Message>
                    )}
                    <ListGroup varaint='flush'>
                      {order.comments.reverse().map((comment, index) => (
                        <ListGroup.Item key={index}>
                          <Card>
                            <Card.Body>
                              <Card.Title>{comment.name}</Card.Title>
                              <Card.Text>
                                at{' '}
                                {comment.commentedAt &&
                                  comment.commentedAt.substring(19, 0)}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <h5>Order Items</h5>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='orderImage'
                              />
                            </Col>
                            <Col m={5}>
                              <Link to={`/book/${item.isbn}/${item.name}`}>
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              <br />
                              <span>
                                {userInfo.isAdmin &&
                                  `Location: ` + item.location}
                              </span>
                            </Col>
                            <Col md={2} xs={2}>
                              <span className='productPrice'>
                                &#8377;{item.salePrice}
                              </span>
                              <br />
                              <strike>&#8377;{item.price}</strike>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <ListGroupItem>
                      <h5> Order Summary</h5>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Row>
                        <Col>Items</Col>
                        <Col>&#8377;{order.itemPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Row>
                        <Col>Shipping</Col>
                        <Col>&#8377;{order.shippingPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    {order.codPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>COD Charges</Col>
                          <Col>&#8377;{order.codPrice}</Col>
                        </Row>
                      </ListGroupItem>
                    )}

                    {order.taxPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>Tax</Col>
                          <Col>&#8377;{order.taxPrice}</Col>
                        </Row>
                      </ListGroupItem>
                    )}

                    {order.discountPrice > 0 && (
                      <ListGroupItem>
                        <Row>
                          <Col>Discount</Col>
                          <Col>-&#8377;{order.discountPrice}</Col>
                        </Row>
                      </ListGroupItem>
                    )}

                    <ListGroupItem>
                      <Row>
                        <Col>Total</Col>
                        <Col>&#8377;{order.totalPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroup.Item>
                      <h5>Payment Method</h5>
                      <p>
                        <strong>Method: </strong>
                        {order.codPrice > 0 ? 'Cash On Delivery' : 'Paid'}
                      </p>
                    </ListGroup.Item>
                    <h5> User Details</h5>
                    <div>Total Orders:{countOrders}</div>
                    {existingOrders &&
                      existingOrders.map((order) => (
                        <div
                          style={{
                            paddingBottom: '5px',
                            borderBottom: '1px solid #333',
                          }}
                        >
                          {order.orderName}
                          {' ==> '}
                          {order.status}
                          <br />
                          &#8377;{order.totalPrice}
                          {order.tags &&
                            order.tags.map((tag) => (
                              <span className='topic'>{tag.name}</span>
                            ))}
                          <br />
                          Paid:{' '}
                          {order.isPaid ? (
                            <i
                              className='fas fa-check'
                              style={{ color: 'green' }}
                            ></i>
                          ) : (
                            <i
                              className='fas fa-times'
                              style={{ color: 'red' }}
                            ></i>
                          )}{' '}
                          Confirmed:
                          {order.isConfirmed ? (
                            <>
                              {' '}
                              <i
                                className='fab fa-diaspora'
                                style={{ color: 'blue' }}
                              ></i>
                            </>
                          ) : (
                            <i
                              className='fas fa-times'
                              style={{ color: 'red' }}
                            ></i>
                          )}
                        </div>
                      ))}
                  </ListGroupItem>
                </ListGroup>

                <ListGroup variant='flash'>
                  {userInfo.isAdmin && (
                    <ListGroup.Item>
                      <Button
                        type='button'
                        className='btn btn-block'
                        onClick={printHandler}
                      >
                        Print
                      </Button>
                    </ListGroup.Item>
                  )}
                  {userInfo.isAdmin &&
                    !order.isConfirmed &&
                    !order.isFufilled && (
                      <ListGroup.Item>
                        <Button
                          type='button'
                          className='btn btn-block'
                          onClick={confirmHandler}
                        >
                          Mark as Confirmed
                        </Button>
                      </ListGroup.Item>
                    )}
                  {userInfo.isAdmin &&
                    !order.isDelivered &&
                    !order.isFufilled && (
                      <>
                        <ListGroup.Item>
                          <Button
                            type='button'
                            className='btn btn-block'
                            onClick={handleShow}
                          >
                            Mark as Delivered
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Button
                            type='button'
                            className='btn btn-block'
                            onClick={generateShipment}
                          >
                            Generate Shipping Label
                          </Button>
                        </ListGroup.Item>
                      </>
                    )}
                  {userInfo.isAdmin &&
                    !order.isCancelled &&
                    !order.isFufilled && (
                      <ListGroup.Item>
                        <Button
                          type='button'
                          className='btn btn-block'
                          onClick={cancelHandler}
                        >
                          Mark as Cancelled
                        </Button>
                      </ListGroup.Item>
                    )}
                  {userInfo.isAdmin && successOrderShipment && (
                    <ListGroup.Item>
                      {order.isFufilled ? (
                        <Message variant='success'>
                          Fulfilled on:{order.fulfilledAt.substring(0, 10)}
                        </Message>
                      ) : (
                        <Message variant='danger'>Not Fulfilled</Message>
                      )}
                      <ListGroup>
                        {OrderShipment.map((shipment, index) => (
                          <ListGroup.Item key={index}>
                            AWB: {shipment.awb}
                            <br />
                            Frieght Charge: {shipment.totalCharge}
                            <br />
                            COD Charges: {shipment.codCharge}
                            <br />
                            Courier: {shipment.courierName}
                            <br />
                            Shipping Label:{' '}
                            <a
                              href={shipment.shiprocketLabelURL}
                              target='_blank'
                            >
                              Download
                            </a>
                            <br />
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Card>
            </Col>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enter Tracking Link</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={deliverHandler}>
                  <FormGroup controlId='comment'>
                    <Form.Label>Tracking</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={tLink}
                      row='3'
                      onChange={(e) => setTLink(e.target.value)}
                      required
                    ></Form.Control>
                  </FormGroup>
                  <Button type='submit' variant='primary'>
                    Fullfill Order
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Row>
          {userInfo.isAdmin && (
            <Row className='print-label'>
              <Col xs={7}>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <h1>99bookscart</h1>
                    <strong>Order Number: {order.orderName}</strong>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h4>Shipping</h4>

                    {/* <p><strong>Name:</strong>{order.user.name}</p> */}
                    {/* <p><strong>Email:</strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p> */}
                    <p>
                      {' '}
                      <strong>Name: </strong>{' '}
                      {order.user
                        ? order.user.name
                        : order.shippingAddress.name}
                      <br />
                      <strong>Email: </strong>{' '}
                      {order.user
                        ? order.user.email
                        : order.shippingAddress.email}
                      <br />
                      <strong>Address:</strong>
                      <br />
                      {order.shippingAddress.address}, <br />
                      {order.shippingAddress.address2},<br />
                      {order.shippingAddress.city},{order.shippingAddress.state}
                      , {order.shippingAddress.postalCode}
                      <br />
                      Payment Method: {order.paymentMethod}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h1>99bookscart</h1>
                    <strong>Order Number: {order.orderName}</strong>
                    <br />
                    <strong>Name: </strong>{' '}
                    {order.user ? order.user.name : order.shippingAddress.name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h4>Order Items</h4>
                    {order.orderItems.length == 0 ? (
                      <Message>Order is Empty</Message>
                    ) : (
                      <ListGroup variant='flush'>
                        {order.orderItems.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col md={9} xs={9}>
                                <Link to={`/book/${item.isbn}/${item.name}`}>
                                  {item.name}
                                </Link>
                                <br />
                                {/* <span>{userInfo.isAdmin && (`Location: `+item.location)}</span> */}
                              </Col>
                              <Col md={3} xs={3}>
                                <span className='productPrice'>
                                  Rs.{item.salePrice}
                                </span>
                                <strike>Rs.{item.price}</strike>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={6}>
                <Card>
                  <ListGroup variant='flash'>
                    <ListGroupItem>
                      <h4> Order Summary</h4>
                    </ListGroupItem>
                    <ListGroupItem style={{ paddingBottom: '2px' }}>
                      <Row>
                        <Col>Items</Col>
                        <Col>Rs.{order.itemPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem style={{ paddingBottom: '2px' }}>
                      <Row>
                        <Col>Shipping</Col>
                        <Col>Rs.{order.shippingPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem style={{ paddingBottom: '2px' }}>
                      <Row>
                        <Col>COD Charges</Col>
                        <Col>Rs.{order.codPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem style={{ paddingBottom: '2px' }}>
                      <Row>
                        <Col>Discount</Col>
                        <Col>-Rs.{order.discountPrice}</Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem style={{ paddingBottom: '2px' }}>
                      <Row>
                        <Col>Total</Col>
                        <Col>Rs.{order.totalPrice}</Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
              <Col xs={12}>
                <ListGroup.Item>
                  <br />
                  <br />
                  <h5>Reference</h5>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={12} xs={12}>
                              <Link to={`/book/${item.isbn}/${item.name}`}>
                                {item.name}
                              </Link>
                              <span>
                                {userInfo.isAdmin &&
                                  `Location: ` + item.location}
                              </span>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
                <ListGroupItem style={{ paddingBottom: '2px' }}>
                  <Row>
                    <Col>Notes</Col>
                  </Row>
                </ListGroupItem>
              </Col>
            </Row>
          )}
          <Modal show={showDimensions} onHide={handleCloseDimension}>
            <Modal.Body>
              <Form onSubmit={createShipmentHandler} className='cart-form'>
                <Form.Group>
                  <Form.Label>Weight</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Weight'
                    required
                    value={weight || ''}
                    onChange={(e) => setWeight(e.target.value)}
                  ></Form.Control>
                  <Form.Label>Length</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Length'
                    required
                    value={length || ''}
                    onChange={(e) => setLength(e.target.value)}
                  ></Form.Control>
                  <Form.Label>Width</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Width'
                    required
                    value={width || ''}
                    onChange={(e) => setWidth(e.target.value)}
                  ></Form.Control>
                  <Form.Label>Height</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Height'
                    required
                    value={height || ''}
                    onChange={(e) => setHeight(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Button type='submit' variant='primary' className='float-right'>
                  Generate Shipment
                </Button>
              </Form>
              <Button
                variant='secondary'
                onClick={handleCloseDimension}
                className='center-link'
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
          <Modal show={showAvailableCourier} onHide={handleCloseDimension}>
            <Modal.Body>
              {loadingShipment ? (
                <Loader></Loader>
              ) : errorShipment ? (
                <Message>{errorShipment}</Message>
              ) : (
                <>
                  {' '}
                  <Form
                    onSubmit={selectCourierServiceHandler}
                    className='cart-form'
                  >
                    <Form.Group>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Courier</th>
                            <th>Freight Charges</th>
                            <th>COD Charges</th>
                            <th>RTO Charges</th>
                            <th>Rate</th>
                          </tr>
                        </thead>
                        {successShipment &&
                          shipment.data.status != 404 &&
                          shipment.data.data.available_courier_companies.map(
                            (courier, index) => (
                              <tr>
                                <td>
                                  <Form.Check
                                    type='radio'
                                    id='courier'
                                    name='courier'
                                    value={courier.courier_company_id}
                                    onChange={(e) =>
                                      setCourierId(e.target.value)
                                    }
                                  ></Form.Check>
                                </td>
                                <td>{courier.courier_name}</td>
                                <td>{courier.freight_charge}</td>
                                <td>{courier.cod_charges}</td>
                                <td>{courier.rto_charges}</td>
                                <td>{courier.rate}</td>
                              </tr>
                            )
                          )}
                        {shipment && (
                          <tr>
                            <td colSpan='6'>
                              {shipment.data.status == 404 && (
                                <Message>NO Courier Available</Message>
                              )}
                            </td>
                          </tr>
                        )}
                      </table>
                    </Form.Group>

                    <Button
                      type='submit'
                      variant='primary'
                      className='float-right'
                    >
                      Generate Label
                    </Button>
                  </Form>
                  <Button
                    variant='secondary'
                    onClick={handleCloseAvailableCourier}
                    className='center-link'
                  >
                    Close
                  </Button>
                </>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  )
}

export default AdminOrderScreen
