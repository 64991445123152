import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import SubNav from '../../components/SubNav'
import { Helmet } from 'react-helmet'

const AboutUsScreen = ({ match, history }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container>
      <Helmet>
        <meta charset='UTF-8' />
        <title>About Us | NexNuggets</title>
        <meta name='title' content='About Us | NexNuggets'></meta>
        <meta
          name='description'
          content='Discover concise summaries of Non Fiction Books on NexNuggets'
        ></meta>
        <meta
          name='keyword'
          content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
        ></meta>
      </Helmet>
      <Container>
        <SubNav history={history}></SubNav>
        <Row>
          <Container class='py-3'>
            <div class='container'>
              <h2>About Us</h2>
              <p>
                A team consisting of avid readers, talented writers, skilled
                developers, creative designers, and strategic planners have come
                together to deliver the best ideas from nonfiction books to
                individuals with the busiest lifestyles.
              </p>
              <h2>Why NexNuggets?</h2>
              <p>
                With busy schedules, it's nearly impossible to read all the
                books we want to. We often waste precious hours on mundane tasks
                like commuting, chores, and mindlessly scrolling through our
                phones. But what if we could turn these small pockets of time
                into opportunities for learning and reflection? In 2022,
                NexNuggets was founded to do just that. Through 15-minute key
                Ideas from non fictions, NexNuggets has connected over 1 million
                readers worldwide to the most significant concepts in popular
                nonfiction books. So, what motivates us?
              </p>
              <p>Contact us at nexnuggets@gmail.com</p>
              <strong>Learn More Read Less</strong>
            </div>
          </Container>
        </Row>
      </Container>
    </Container>
  )
}

export default AboutUsScreen
// dispatch(listProductsDetails('BookscartBox1', 'surprise-box-15-books'))
