import { Route, BrowserRouter } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/litplots/public/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import TermsConditionScreen from './screens/static/TermsConditionScreen'
import PrivacyPolicyScreen from './screens/static/PrivacyPolicyScreen'
import ReturnRefundScreen from './screens/static/ReturnRefundScreen'
import AboutUsScreen from './screens/static/AboutUsScreen'
import CategoryProductScreen from './screens/CategoryProductScreen'
import OrderConfirmScreen from './screens/OrderConfirmScreen'
import BasicInformationScreen from './screens/BasicInformationScreen'
import AbandonCartListScreen from './screens/AbandonCartListScreen'
import { AdminDashboardScreen } from './screens/AdminDashboardScreen'
import DiscountCodeScreen from './screens/DiscountCodeScreen'
import SubscriberListScreen from './screens/SubscriberListScreen'
import AuthorProductScreen from './screens/AuthorProductScreen'
import AbandonCart from './screens/AbandonCartScreen'
import FlowListScreen from './screens/FlowListScreen'
import FlowDetailScreen from './screens/FlowDetailScreen'
import ProductScanScreen from './screens/ProductScanScreen'
import UserOrderListScreen from './screens/UserOrderListScreen'
import UserAddressListScreen from './screens/UserAddressListScreen'
import UserSettingScreen from './screens/UserSettingScreen'
import UserCoinListScreen from './screens/UserCoinListScreen'
import LanguageProductScreen from './screens/LanguageProductScreen'
import UserWishListScreen from './screens/UserWishListScreen'
import UserEbookScreen from './screens/UserEbookScreen'
import UserSubscriptionScreen from './screens/UserSubscriptionScreen'
import EbooksScreen from './screens/EbooksScreen'
import ProductNewScreen from './screens/ProductNewScreen'
import ContactFormScreen from './screens/ContactFormScreen'
import SubmissionScreen from './screens/SubmissionScreen'
import ContactFromSubmissionScreen from './screens/ContactFromSubmissionScreen'
import EbookSubmissionsScreen from './screens/EbookSubmissionsScreen'
import SearchScreen from './screens/SearchScreen'
import RedirectProductScreen from './screens/RedirectProductScreen'
import RedirectCategoryProductScreen from './screens/RedirectCategoryProductScreen'
import BookFairNewScreen from './screens/BookFairNewScreen'
import BookFairListScreen from './screens/BookFairListScreen'
import BookFairScreen from './screens/BookFairScreen'
import BookFairEntryNewScreen from './screens/BookFairEntryNewScreen'
import BookFairEntryStatsScreen from './screens/BookFairEntryStatsScreen'
import GenreScreen from './screens/static/GenreScreen'
import ReviewScreen from './screens/ReviewScreen'
import AddressSelectionScreen from './screens/AddressSelectionScreen'
import CartListScreen from './screens/CartListScreen'
import BoxScreen from './screens/BoxScreen'
import IntrestListScreen from './screens/IntrestListScreen'
import ExpressCheckout from './screens/ExpressCheckout'
import BookFairPScreen from './screens/BookFairPScreen'
import BookFairListPScreen from './screens/BookFairListPScreen'
import BookFairIntrestScreen from './screens/BookFairIntrestScreen'
import CretaeEpisodeScreen from './screens/stories/CretaeEpisodeScreen'
import CretaeStoryScreen from './screens/stories/CretaeStoryScreen'
import StoryListScreen from './screens/stories/StoryListScreen'
import WriteEpisodeScreen from './screens/stories/WriteEpisodeScreen'
import StoryViewScreen from './screens/ideas/StoryViewScreen'
import MyStoryListScreen from './screens/stories/MyStoryListScreen'
import ReadEpisodeScreen from './screens/app/AppReadEpisodeScreen'
import FollowerScreen from './screens/stories/FollowerScreen'
import FollowingScreen from './screens/stories/FollowingScreen'
import LoginOTPScreen from './screens/LoginOTPScreen'
import PasswordResetScreen from './screens/PasswordResetScreen'
import UserScreen from './screens/UserScreen'
import StoriesScreen from './screens/ideas/StoriesScreen'
import AllStoryListScreen from './screens/stories/AllStoryListScreen'
import SummariesScreen from './screens/summaries/SummariesScreen'
import SubscribeScreen from './screens/summaries/SubscribeScreen'
import BillingScreen from './screens/litplots/app/BillingScreen'
import CreateBlogScreen from './screens/blog/CreateBlogScreen'
import ListBlogScreen from './screens/blog/ListBlogScreen'
import ViewBlogScreen from './screens/blog/ViewBlogScreen'
import AdminOrderScreen from './screens/admin/AdminOrderScreen'
import SuprieseBoxScreen from './screens/landingPages/SuprieseBoxScreen'
import AllLinksScreen from './screens/all/AllLinksScreen'

import StoriesListScreen from './screens/ideas/StoriesListScreen'

import AppHomeScreen from './screens/litplots/app/AppHomeScreen'
import AppStoriesListScreen from './screens/app/AppStoriesListScreen'
import AppStoryViewScreen from './screens/app/AppStoryViewScreen'
import AppReadEpisodeScreen from './screens/app/AppReadEpisodeScreen'
import UserSubscriptionList from './screens/app/UserSubscriptionList'
import SubscriptionSuccess from './screens/litplots/app/SubscriptionSuccess'
import ReadingsScreen from './screens/app/ReadingsScreen'
import StoriesScreenUS from './screens/ideas/StoriesScreenUS'
import BillingScreenUS from './screens/litplots/app/BillingScreenUS'
import PlotListScreen from './screens/litplots/public/PlotListScreen'
import PlotViewScreen from './screens/litplots/public/PlotViewScreen'
import AppPlotListScreen from './screens/litplots/app/AppPlotListScreen'
import AppPlotViewScreen from './screens/litplots/app/AppPlotViewScreen'
import AppReadPlotsScreen from './screens/litplots/app/AppReadPlotsScreen'
import SubscriptionList from './screens/litplots/app/SubscriptionList'
import AppPlotSearchScreen from './screens/litplots/app/AppPlotSearchScreen'
import AppReadPlotListScreen from './screens/litplots/app/AppReadPlotListScreen'
import ReadFreePlotsScreen from './screens/litplots/public/ReadFreePlotsScreen'
import AppReadFreePlotsScreen from './screens/litplots/app/AppReadFreePlotsScreen'
import AppGoalListScreen from './screens/litplots/app/AppGoalListScreen'
import CollectionListScreen from './screens/litplots/public/CollectionListScreen'

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <>
        <main className='py-0.5'>
          {/* public */}
          <Route path='/search/:keyword/:search' component={HomeScreen} exact />
          <Route path='/page/:pageNumber' component={HomeScreen} exact />
          <Route
            path='/search/:keyword/page/:pageNumber'
            component={HomeScreen}
            exact
          />
          <Route path='/' component={HomeScreen} exact />
          <Route
            path='/collection/:slug'
            component={CollectionListScreen}
            exact
          />
          <Route path='/category/:category' component={PlotListScreen} exact />
          <Route
            path='/category/:category/page/:pageNumber'
            component={PlotListScreen}
            exact
          />
          <Route
            path='/en/category/:category'
            component={PlotListScreen}
            exact
          />
          <Route
            path='/en/category/:category/page/:pageNumber'
            component={PlotListScreen}
            exact
          />
          <Route
            path='/book-summary/:title/:id'
            component={PlotViewScreen}
            exact
          />
          <Route path='/en/book/:title/:id' component={PlotViewScreen} exact />
          <Route
            path='/book-summary/:title/:id/:sid'
            component={ReadFreePlotsScreen}
            exact
          />
          <Route
            path='/en/plot/free/:id/:sid'
            component={ReadFreePlotsScreen}
            exact
          />
          {/* end of public */}
          {/* app */}
          <Route
            path='/app/en/plot/free/:id/:sid'
            component={AppReadFreePlotsScreen}
            exact
          />
          <Route
            path='/app/en/search/:keyword'
            component={AppPlotSearchScreen}
            exact
          />
          <Route
            path='/app/en/search/:keyword/page/:pageNumber'
            component={AppPlotSearchScreen}
            exact
          />
          <Route path='/app' component={AppHomeScreen} exact />
          <Route
            path='/app/en/category/:category'
            component={AppPlotListScreen}
            exact
          />
          <Route
            path='/app/en/category/:category/page/:pageNumber'
            component={AppPlotListScreen}
            exact
          />
          <Route
            path='/app/en/goal/:goal'
            component={AppGoalListScreen}
            exact
          />
          <Route
            path='/app/en/goal/:goal/page/:pageNumber'
            component={AppGoalListScreen}
            exact
          />
          <Route
            path='/app/en/book/:title/:id'
            component={AppPlotViewScreen}
            exact
          />

          <Route
            path='/app/en/plot/:id/'
            component={AppReadPlotsScreen}
            exact
          />

          <Route
            path='/app/profile/readings'
            component={AppReadPlotListScreen}
            exact
          />
          <Route
            path='/app/profile/subscriptions'
            component={SubscriptionList}
          />
          <Route
            path='/app/subscribed/:id/:id2/:id3'
            component={SubscriptionSuccess}
            exact
          />
          <Route path='/app/profile/billing' component={BillingScreen} />
          <Route path='/app/profile/us/billing' component={BillingScreenUS} />

          <Route
            path='/app/admin/story/all'
            component={AllStoryListScreen}
            exact
          />
          <Route
            path='/app/admin/story/all/page/:pageNumber'
            component={AllStoryListScreen}
            exact
          />
          {/* end of app */}

          <Route
            path='/app/profile/book-ideas'
            component={ReadingsScreen}
            exact
          />
          {/* app end*/}

          {/* public app */}
          <Route path='/book-ideas' component={StoriesScreen} exact />
          <Route path='/book-ideas-us' component={StoriesScreenUS} exact />

          <Route
            path='/book-ideas/category/:category'
            component={StoriesListScreen}
            exact
          />
          {/* <Route
            path='/book-ideas/category/:category/page/:pageNumber'
            component={StoriesListScreen}
            exact
          /> */}

          <Route
            path='/idea/book-ideas/:id/:title'
            component={StoryViewScreen}
            exact
          />

          {/* public app end */}

          {/* landing-page */}
          <Route
            path='/99bookscart-surprise-box'
            component={SuprieseBoxScreen}
            exact
          />
          {/* landing-page */}
          {/* sitemap*/}
          <Route path='/all-books' component={AllLinksScreen} exact />
          {/* sitemap */}
          {/* blog */}
          <Route
            path='/blog/view/:id/:title'
            component={ViewBlogScreen}
            exact
          />
          <Route path='/blog/create' component={CreateBlogScreen} exact />
          <Route path='/blog' component={ListBlogScreen} exact />
          {/* end blog */}

          <Route path='/plotsummary' component={SummariesScreen} exact />

          <Route path='/subscribe' component={SubscribeScreen} exact />

          {/* Stories */}

          <Route
            path='/write/story/:id/:sid'
            component={CretaeStoryScreen}
            exact
          />
          <Route path='/create/episode' component={CretaeEpisodeScreen} exact />
          <Route
            path='/create/episode/:sid'
            component={CretaeEpisodeScreen}
            exact
          />
          <Route
            path='/write/episode/:id/:sid'
            component={WriteEpisodeScreen}
            exact
          />
          <Route
            path='/profile/mystories'
            component={MyStoryListScreen}
            exact
          />

          <Route
            path='/profile/subscriptions'
            component={UserSubscriptionList}
          />

          <Route path='/profile/followers' component={FollowerScreen} exact />
          <Route path='/profile/following' component={FollowingScreen} exact />
          <Route path='/story/all' component={AllStoryListScreen} exact />
          <Route path='/story/list' component={StoryListScreen} exact />
          {/* <Route
            path='/story/list/page/:pageNumber'
            component={StoryListScreen}
            exact
          /> */}

          {/* End of Stories */}

          <Route
            path='/passwordreset/:id/:reset'
            component={PasswordResetScreen}
            exact
          />
          <Route path='/admin/intrests' component={IntrestListScreen} exact />

          <Route path='/categories' component={GenreScreen} exact />
          <Route
            path='/admin/bookfair/:id/stats'
            component={BookFairEntryStatsScreen}
            exact
          />
          <Route
            path='/admin/bookfair/:id/new'
            component={BookFairEntryNewScreen}
          />
          <Route path='/bookfairs' component={BookFairListPScreen} exact />
          <Route
            path='/admin/bookfair/intrest/:id'
            component={BookFairIntrestScreen}
            exact
          />
          <Route path='/bookfair/:id' component={BookFairPScreen} exact />
          <Route path='/admin/bookfair/:id' component={BookFairScreen} exact />
          <Route
            path='/admin/bookfair/:id/page/:pageNumber'
            component={BookFairScreen}
            exact
          />
          <Route path='/admin/bookfairList' component={BookFairListScreen} />
          <Route path='/admin/bookfairNew' component={BookFairNewScreen} />
          <Route path='/admin/order/:id' component={AdminOrderScreen} exact />

          <Route
            path='/products/:slug'
            component={RedirectProductScreen}
            exact
          />
          <Route
            path='/collections/:slug'
            component={RedirectCategoryProductScreen}
          />
          <Route path='/search' component={SearchScreen} exact />
          <Route path='/contact-us' component={ContactFormScreen} exact />
          <Route path='/submission' component={SubmissionScreen} exact />

          <Route path='/order/:id' component={OrderScreen} exact />
          <Route
            path='/order/:id/orderconfirm/:razid'
            component={OrderConfirmScreen}
            exact
          />
          <Route path='/ebooks' component={EbooksScreen} exact />
          {/* <Route
            path='/category/:category'
            component={CategoryProductScreen}
            exact
          /> */}
          <Route path='/author/:author' component={AuthorProductScreen} exact />
          <Route
            path='/language/:language'
            component={LanguageProductScreen}
            exact
          />
          <Route
            path='/language/:language/page/:pageNumber'
            component={LanguageProductScreen}
            exact
          />
          {/* <Route
            path='/category/:category/page/:pageNumber'
            component={CategoryProductScreen}
            exact
          /> */}
          <Route path='/checkout/placeorder' component={PlaceOrderScreen} />

          <Route path='/review/:id/:slug' component={ReviewScreen} exact />
          <Route path='/book/:id/:slug' component={ProductScreen} />
          <Route path='/landing/:id/:slug' component={BoxScreen} exact />
          {/* <Route path='/read/:id/:slug' component={ReadScreen} /> */}
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/login2' component={LoginOTPScreen} />
          <Route path='/login' component={LoginScreen} />

          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} exact />
          <Route path='/user/:id' component={UserScreen} exact />

          <Route path='/profile/orders' component={UserOrderListScreen} exact />
          <Route
            path='/profile/addresses'
            component={UserAddressListScreen}
            exact
          />
          <Route path='/profile/setting' component={UserSettingScreen} exact />
          <Route path='/profile/coins' component={UserCoinListScreen} exact />
          <Route path='/profile/ebooks' component={UserEbookScreen} exact />
          <Route
            path='/profile/wishlist'
            component={UserWishListScreen}
            exact
          />
          <Route
            path='/profile/subscription'
            component={UserSubscriptionScreen}
          />
          <Route path='/checkout/express' component={ExpressCheckout} />
          <Route path='/checkout/shipping' component={ShippingScreen} />
          <Route path='/checkout/payment' component={PaymentScreen} />
          <Route
            path='/checkout/basic-information'
            component={BasicInformationScreen}
          />
          <Route
            path='/checkout/select-address'
            component={AddressSelectionScreen}
          />
          <Route path='/admin/dashboard' component={AdminDashboardScreen} />

          <Route path='/admin/userList' component={UserListScreen} exact />
          <Route
            path='/admin/userList/:pageNumber'
            component={UserListScreen}
            exact
          />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route
            path='/admin/product/scan'
            component={ProductScanScreen}
            exact
          />
          <Route
            path='/admin/formsubmissions'
            component={ContactFromSubmissionScreen}
            exact
          />
          <Route
            path='/admin/ebooksubmissions'
            component={EbookSubmissionsScreen}
            exact
          />

          <Route
            path='/admin/productlist'
            component={ProductListScreen}
            exact
          />
          <Route path='/admin/product/new' component={ProductNewScreen} exact />
          <Route
            path='/admin/productlist/:pageNumber'
            component={ProductListScreen}
            exact
          />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/orderlist' component={OrderListScreen} exact />
          <Route
            path='/admin/orderlist/:pageNumber'
            component={OrderListScreen}
            exact
          />
          <Route
            path='/admin/orderlist/:pageNumber/:keyword'
            component={OrderListScreen}
            exact
          />
          <Route
            path='/admin/orderlist/:pageNumber/type/:cancel'
            component={OrderListScreen}
            exact
          />
          <Route
            path='/admin/abandoncartlist'
            component={AbandonCartListScreen}
          />
          <Route path='/admin/abandoncartdetails/:id' component={AbandonCart} />
          <Route path='/admin/cartlist' component={CartListScreen} exact />

          <Route path='/admin/discountCode' component={DiscountCodeScreen} />
          <Route path='/admin/flowlist' component={FlowListScreen} exact />
          <Route path='/admin/flow/:id' component={FlowDetailScreen} exact />
          <Route
            path='/admin/subscriberList'
            component={SubscriberListScreen}
          />
          <Route
            path='/pages/terms-and-condition'
            component={TermsConditionScreen}
          />
          <Route path='/pages/privacy-policy' component={PrivacyPolicyScreen} />
          <Route path='/pages/return-refund' component={ReturnRefundScreen} />
          <Route path='/pages/about-us' component={AboutUsScreen} />
        </main>
      </>
      <Footer />
    </BrowserRouter>
  )
}

export default App
