import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
  Form,
} from 'react-bootstrap'
import { productListAll } from '../../actions/productActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Rating from '../../components/Rating'
import SurpriseStats from '../../components/SurpriseStats'

import { BoxDescription } from '../../components/BoxDescription'
import { addToCart } from '../../actions/cartActions.js'

const AllLinksScreen = ({ match, history }) => {
  const dispatch = useDispatch()

  const productListAllRes = useSelector((state) => state.productListAll)
  const { loading, error, products, success } = productListAllRes

  useEffect(() => {
    window.scrollTo(0, 0)
    if (products && products.length == 0) {
      dispatch(productListAll())
    }
  }, [products])

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <Row>
            {products.map((product) => (
              <Col md={4}>
                <Link
                  to={`/book/${product.isbn}/${convertToSlug(product.name)}`}
                >
                  {product.name} by {product.author}
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default AllLinksScreen
