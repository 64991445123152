import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'

const SubscribeScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [dispatch])
  return (
    <>
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <div className='story-hero-text'>
              <h1 className='hero-text'>Holla, We are 99bookscart</h1>
              <p>99bookscart is India's social storytelling platform</p>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className='story-hero-text'>
              <h1 className='hero-text'>Reading & Writting is Self Care</h1>
              <p>One to One connection between readers and writters</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='story-action-section'>
              <div>
                <small>Read Stories for FREE</small>
              </div>
              <br />
              <Link to={'/subscribe'}>
                <Button>Subscribe</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      
      <Container></Container>
    </>
  )
}
{
}

export default SubscribeScreen
