import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

export const BoxDescription = (val) => {
  return (
    <Container style={{paddingLeft:'14px'}}>
      <Row>
        <Col xs={12}>
          <br />
          <p>
            Are you tired of constantly searching for your next great read? Look
            no further! With our unbeatable deal, you can get 15 fantastic books
            for only ₹999. And with more than 10 genres available, you're sure
            to find something to suit your reading preferences.
          </p>

          <p>
            But that's not all! With each order, you'll receive free bookmarks
            to help you keep track of your reading progress. And with over 1500
            five-star reviews, you can trust that our books will not disappoint.
          </p>

          <p>
            Build your own library and immerse yourself in captivating stories,
            inspiring memoirs, and informative non-fiction. Reading has been
            shown to boost cognitive function, improve memory, and reduce stress
            levels. And with our incredible offer, you can invest in your
            personal growth and well-being at an unbeatable price.
          </p>

          <p>
            So, why wait? Take advantage of our amazing offer and start building
            your own personal library today. With free bookmarks and more than
            10 genres available, you'll never run out of reading material. And
            with our 1500+ five-star reviews, you can trust that you're getting
            top-quality books at an unbeatable price.
          </p>

          <p>
            Order now and discover the joy of reading with our amazing offer of
            15 books for only ₹999.
          </p>

          <p>Here’s how it works:</p>
          <ul>
            <li>
              <strong>You Choose Your Favorite Genre:</strong> Our Experts pick
              15 books from your selected genre.
            </li>
            <li>
              <strong>You Select Shipping Method:</strong> We pack your books
              and deliver it to your doorstep.
            </li>
          </ul>

          <p>
            <span>SURPRISE BOX Contains:</span>
          </p>
          <ul>
            <li>
              <span>15 Excellent Quality Books from your selected Genre</span>
            </li>
            <li>
              <span>Thank you card with discount code</span>
            </li>
            <li>
              <span>Stickers</span>
            </li>
            <li>
              <span>Bookmarks</span>
            </li>
            <li>
              <span>Reading Calendar</span>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}
