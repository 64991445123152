import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Image, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { register, sendOTP, verifyOtp } from '../actions/userActions'
import SocialLogin from '../components/SocialLogin'
import SubNav from '../components/SubNav'
import { TextField } from '@mui/material'
import { Helmet } from 'react-helmet'
import StepFunnel from '../components/litplots/StepFunnel'

const RegisterScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rpassword, setRpassword] = useState('')

  const [passwordMessage, setPasswordMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const [message, setMessage] = useState(null)
  const [showMainForm, setShowMainForm] = useState(true)
  const [showSocials, setSocials] = useState(false)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success: registerSuccess, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      history.push(redirect)
    }

    if (registerSuccess && userInfo) {
      if (userInfo.error) {
        setErrorMessage(userInfo.errorMessage)
      } else {
        if (window.fbq) {
          window.fbq('track', 'CompleteRegistration')
        } else {
          console.log('Facebook Pixel not initialized')
        }
        if (redirect == 'subscription') {
          history.push('/app/profile/billing')
        } else {
          //history.push(redirect)
          history.push('/app/profile/billing')
        }
      }
    }
  }, [history, userInfo, redirect, registerSuccess])

  // const responseFacebook = (response) => {
  //   if (response.name) {
  //     var isSocial = true
  //     dispatch(
  //       register({
  //         name: response.name,
  //         email: response.email,
  //         isSocial: isSocial,
  //         source: 'facebook',
  //         externalId: response.id,
  //         accessToken: response.accessToken,
  //         profileImage: response.picture
  //           ? response.picture.data
  //             ? response.picture.data.url
  //             : ''
  //           : '',
  //       })
  //     )
  //   }
  // }

  const submitHandler = (e) => {
    e.preventDefault()
    setPasswordMessage('')
    if (password.length < 5) {
      setPasswordMessage('Password is too short')
    } else {
      var isSocial = false
      dispatch(register({ name, email, password, isSocial }))
    }
  }
  const backtoSocialHandler = (e) => {
    setShowMainForm(false)
    setSocials(true)
  }
  const loginwithEmailHandler = (e) => {
    setShowMainForm(true)
    setSocials(false)
  }
  return (
    <Container>
      <Helmet>
        <meta charset='UTF-8' />
        <title>Sign up | NexNuggets</title>
        <meta name='title' content='Sign up | NexNuggets'></meta>
        <meta
          name='description'
          content='Discover concise summaries of Non Fiction Books on NexNuggets'
        ></meta>
        <meta
          name='keyword'
          content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
        ></meta>
      </Helmet>
      <Row>
        <Col xs={12} md={12}>
          {showMainForm && (
            <div style={{}} className='login-box'>
              <StepFunnel />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default RegisterScreen
