import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'


const SummariesScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [dispatch])
  return (
    <>
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <div className='story-hero-text'>
              <h1 className='hero-text'>Holla, We are 99bookscart</h1>
              <p>99bookscart is India's social storytelling platform</p>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className='story-hero-text'>
              <h1 className='hero-text'>Reading & Writting is Self Care</h1>
              <p>One to One connection between readers and writters</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='story-action-section'>
              <div>
                <small>Read Stories for FREE</small>
              </div>
              <br />
              <Link to={'/subscribe'}>
                <Button>Subscribe</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Row className='story-bg-points'>
        <div className='text-center mb-8'>
          <h3>How it works</h3>
          <br />
        </div>
        <Col xs={12} sm={4}>
          <h3>Write</h3>
          <p>
            Share your unique story on 99bookscart and improve your writting and
            imagination.
          </p>
        </Col>
        <Col xs={12} sm={4}>
          <h3>Publish</h3>
          <p>
            99bookscart has more than 1.6 lakh readers to explore and share
            their opinion.
          </p>
        </Col>
        <Col xs={12} sm={4}>
          <h3>Connect</h3>
          <p>Connect with readers and redefine your final script</p>
        </Col>
      </Row>
      <Container></Container>
    </>
  )
}
{
}

export default SummariesScreen
