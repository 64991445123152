import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { readFreeEpisode, voteEpisode } from '../../../actions/episodeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import Loader from '../../../components/Loader'
import ReactDOMServer from 'react-dom'
import crypto from 'crypto'
import { addFollower } from '../../../actions/followerActions'
import { FaBookReader, FaStar, FaComment } from 'react-icons/fa'
import Comments from '../../../components/Comments'
import SocialShare from '../../../components/SocialShare'
import { convertToSlug, makeTitle } from '../../../helpers/helpers'
import { getCategory, convertToSlug2 } from '../../../helpers/helpers'
import AudioPlayer from '../../../components/litplots/player/AudioPlayer'
import { Helmet } from 'react-helmet'
const ReadFreePlotsScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const [content, SetContent] = useState('')
  const [follow, setFollow] = useState(false)
  const [voteText, setVoteText] = useState('Vote')
  const [flag, setFlag] = useState(false)

  const readFreeEpisodeRes = useSelector((state) => state.readFreeEpisode)
  const { episode, nexchapterId, loading, error, suceess } = readFreeEpisodeRes

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const voteEpisodeHandler = () => {
    if (userLoggedIn) {
      dispatch(voteEpisode(match.params.id))
      setVoteText('Voted')
    } else {
      history.push('/register')
    }
  }
  const followHandler = () => {
    dispatch(addFollower({ following: episode.user._id }))
    setFollow(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn._id) {
      history.push(`/register`)
    } else {
      if (
        (!suceess || (episode && episode._id != match.params.id)) &&
        !loading
      ) {
        dispatch(readFreeEpisode(match.params.id, match.params.sid))
      }

      if (suceess) {
        var algorithm = 'aes256'
        var key = 'password'
        var decipher = crypto.createDecipher(algorithm, key)
        var decrypted =
          decipher.update(episode.content, 'hex', 'utf8') +
          decipher.final('utf8')
        SetContent(decrypted)
        setFlag(true)
      }
    }
  }, [
    suceess,
    episode,
    content,
    match.params.id,
    match.params.sid,
    loading,
    userLoggedIn,
    history,
  ])

  return (
    <Container style={{ marginTop: '35px' }}>
      {loading || !suceess ? (
        <Loader></Loader>
      ) : (
        <>
          <Helmet>
            <meta charset='UTF-8' />
            <title>${episode.story.title} | NexNuggets</title>
            <meta
              name='title'
              content={`${episode.story.title} | NexNuggets`}
            ></meta>
            <meta
              name='description'
              content='Discover concise summaries of Non Fiction Books on NexNuggets'
            ></meta>
            <meta
              name='keyword'
              content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
            ></meta>
          </Helmet>
          <Row>
            <Col>
              <Container>
                <div style={{ cursor: 'pointer' }}>
                  <Link
                    style={{ float: 'left' }}
                    to={`/book-summary/${convertToSlug(episode.story.title)}/${
                      episode.story._id
                    }`}
                  >
                    Back
                  </Link>
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <span onClick={voteEpisodeHandler} style={{ float: 'right' }}>
                    <FaStar style={{ color: '#666' }}></FaStar> {voteText}
                  </span>
                </div>
              </Container>
            </Col>
          </Row>
          <div className='episodereadheader'>
            <small>{episode.story.title}</small>
            <h2 className='episodetitle-name'>{episode.title}</h2>
            <div className='storystatslist'>
              <FaBookReader></FaBookReader> {episode.totalReadCount}{' '}
              <FaStar></FaStar> {episode.totalVoteCount} <FaComment></FaComment>{' '}
              {episode.totalComment}
            </div>
          </div>
          <Row>
            <Col xs={12} md={12}>
              <div
                className='episodecontent'
                dangerouslySetInnerHTML={{
                  __html: content.replace(/(<? *script)/gi, 'illegalscript'),
                }}
              ></div>
              <div style={{ textAlign: 'center' }}>
                <br />
                {nexchapterId != null ? (
                  <div>
                    {' '}
                    <Link
                      to={`/book-summary/${convertToSlug2(
                        episode.story.title
                      )}/${nexchapterId}/${episode.story._id}`}
                    >
                      <Button>Next Idea</Button>
                    </Link>{' '}
                    <br />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* <Comments episode={episode}></Comments> */}
            </Col>
            {episode.audio && (
              <AudioPlayer src={episode.audio} name={episode.title} />
            )}
            {userLoggedIn && userLoggedIn.isAdmin && (
              <Row>
                <Link
                  to={`/write/episode/${match.params.id}/${episode.story._id}`}
                  className='newbuttons'
                >
                  Edit
                </Link>
              </Row>
            )}
            {/* <Col xs={12} md={3} style={{ textAlign: 'center' }}>
              <div>
                {nexchapterId != null ? (
                  <div>
                    <Link to={`/app/idea/${nexchapterId}`}>
                      <Button>Next Chapter</Button>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <br />
              <div style={{ fontSize: '12px' }}>
                by{' '}
                <Link to={`/user/${episode.user._id}`} className='writer-name'>
                  {episode.user.name}
                </Link>
              </div>
              <Button onClick={followHandler} disabled={follow ? true : false}>
                Follow
              </Button>
              <SocialShare
                url={url}
                title={episode.title}
                verticle={true}
              ></SocialShare>
            </Col> */}
          </Row>
        </>
      )}
    </Container>
  )
}

export default ReadFreePlotsScreen
