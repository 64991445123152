import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, listStoryByKeyword } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../../../components/Loader'
import AppStory from '../../../components/AppStory'
import AppPlotSearchPaginate from '../../../components/litplots/AppPlotSearchPaginate'
import SearchBox from '../../../components/litplots/SearchBox'
const AppPlotSearchScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const keyword = match.params.keyword || ''
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const storybyKey = useSelector((state) => state.storybyKey)
  const { stories, loading, error, suceess, pages, page } = storybyKey

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } 
    dispatch(listStoryByKeyword(keyword, pageNumber))
  }, [dispatch, pageNumber, keyword, history])
  return (
    <>
      <Container>
        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <Container>
            <SearchBox history={history} key={keyword}></SearchBox>
            <h1>{keyword} Books</h1>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <AppStory history={history} story={story} />
                      )}
                    />
                  </Col>
                ))}
              {stories && stories.length > 20 && (
                <AppPlotSearchPaginate
                  pages={pages}
                  page={page}
                  category={match.params.keyword}
                ></AppPlotSearchPaginate>
              )}
            </Row>
          </Container>
        )}
      </Container>
    </>
  )
}
{
}

export default AppPlotSearchScreen
