import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserCoins } from '../actions/coinActions'
import Paginate from '../components/Paginate'
import { MdAddAlert } from 'react-icons/md'
import { BsBoxSeam, BsCoin } from 'react-icons/bs'
import { FaTruck, FaCreditCard } from 'react-icons/fa'
import {
  reedemDiscountCode,
  listUserDiscountCode,
} from '../actions/discountCodeActions'
const UserCoinListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userCoins = useSelector((state) => state.userCoin)
  const { loading, error, coins } = userCoins

  const reedemDiscount = useSelector((state) => state.reedemDiscount)
  const { success: successDiscountCode, discountCode } = reedemDiscount

  const listUserDiscounts = useSelector((state) => state.listUserDiscounts)
  const { success: successListUserDiscountCode, discounts } = listUserDiscounts

  var getCoinCount = 0
  if (!loading) {
    getCoinCount = coins.reduce((x, y) => y.status == 0 && x + y.coins, 0)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserCoins())
      dispatch(listUserDiscountCode())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])
  const redeemCoins = () => {
    dispatch(reedemDiscountCode())
  }
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <Row>
            <Col>
              <h4>Reading Coins</h4>
            </Col>
            <Col>
              <Link to='/profile' className='btn btn-light float-right'>
                Go back
              </Link>
            </Col>
          </Row>

          {getCoinCount > 0 && !successDiscountCode && (
            <Row>
              <Col xs={12} md={6}>
                <p>Total coins earned {getCoinCount}</p>
                <p>&#8377;{getCoinCount * 2} OFF</p>
              </Col>
              <Col xs={12} md={6}>
                {/* <Button varaint='primary' onClick={() => redeemCoins()}>
                  Reedem Coins
                </Button> */}
              </Col>
            </Row>
          )}
          {successDiscountCode && (
            <p>
              Your discount Code <strong>{discountCode.name}</strong>
            </p>
          )}
          <br />
          {/* {successListUserDiscountCode && (
            <>
              <h1>Discounts</h1>
              {discounts.length == 0 && (
                <Message>No Discount available yet!</Message>
              )}
              {discounts.map((discount) => (
                <Row key={discount._id}>
                  <Col xs={12}>
                    Code <strong>{discount.name}</strong> for &#8377;{' '}
                    {discount.value}{' '}
                    {discount.status == 0 ? <p>Active</p> : <p>Used</p>}
                  </Col>
                </Row>
              ))}
            </>
          )} */}
          {coins.length == 0 && (
            <Message>No Coins! Place Orders to Earn Coins</Message>
          )}
          {coins.map((coin) => (
            <Row key={coin._id} className='order-row'>
              <Col xs={12}>
                Earned <strong>{coin.coins}</strong> coins <BsCoin></BsCoin> on
                order {coin.order.orderName} <br />
                {coin.order.orderTotal}
                {coin.status == 0 ? <p>Active</p> : <p>Inactive</p>}
              </Col>
            </Row>
          ))}
        </Container>
      )}
    </>
  )
}

export default UserCoinListScreen
