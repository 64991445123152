import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storyListByGoal } from '../../actions/storyActions'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
const HomePageKiller = () => {
  const goals = [
    'Have a successful career',
    'Build a close-knit family',
    'Increase productivity',
    'Achieve life balance',
    'Lead a healthy lifestyle',
    'Become confident',
    'Improve sex life',
    'Develop healthy relationships',
    'Boost intelligence',
    'Reach happiness',
  ]

  const dispatch = useDispatch()
  const scrollRef = useRef(null)

  const { stories, loading, error, success } = useSelector(
    (state) => state.storyListByGoal
  )
  const [currentBook, setCurrentBook] = useState(0)
  useEffect(() => {
    // Reset the book view to the first book when new books are loaded successfully
    if (success) {
      setCurrentBook(0)
    }
  }, [success])
  useEffect(() => {
    dispatch(storyListByGoal(goals[1]))
  }, [dispatch])

  const selectGoal = (goal) => {
    dispatch(storyListByGoal(goal))
  }

  const scroll = (direction) => {
    if (direction === 'left') {
      scrollRef.current.scrollLeft -= 150
    } else {
      scrollRef.current.scrollLeft += 150
    }
  }

  const scrollBooks = (direction) => {
    setCurrentBook((prev) =>
      direction === 'left'
        ? Math.max(0, prev - 1)
        : Math.min(stories.length - 1, prev + 1)
    )
  }

  return (
    <div
      className='mt-4 mb-1'
      style={{
        backgroundColor: '#2A2A2A',
        paddingTop: '10px',
        paddingBottom: '20px',
        padding: '5px',
        color: '#EAECEE',
      }}
    >
      <div className='goals-container d-flex justify-content-center'>
        <button
          onClick={() => scroll('left')}
          className='btn btn-secondary round-button'
        >
          {'<'}
        </button>
        <div
          ref={scrollRef}
          className='d-flex flex-row overflow-hidden'
          style={{ width: '900px' }}
        >
          {goals.map((goal) => (
            <button
              key={goal}
              onClick={() => selectGoal(goal)}
              className={`btn small-tab-btn ${
                stories && stories.length && stories[0].title.includes(goal)
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              } m-2`}
            >
              {goal}
            </button>
          ))}
        </div>
        <button
          onClick={() => scroll('right')}
          className='btn btn-secondary round-button'
        >
          {'>'}
        </button>
      </div>
      <div className='book-details mt-4 text-center'>
        {loading ? (
          <div style={{ minHeight: '280px' }}>Loading...</div> // Replace with your Loader component
        ) : error ? (
          <div>Error: {error}</div> // Error handling
        ) : (
          stories.length > 0 && (
            <div
              style={{
                maxWidth: '700px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Row>
                <Col xs={12} sm={6}>
                  <img
                    src={stories[currentBook].coverImage}
                    alt='Book Cover'
                    className='img-fluid'
                    width={'120px'}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  {/* <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    {stories[currentBook].title}
                  </h3> */}
                  {/* <p>{stories[currentBook].user.name}</p> */}
                  <p
                    style={{
                      padding: '12px',
                      fontSize: '14px',
                      textAlign: 'left',
                    }}
                  >
                    {stories[currentBook].description.slice(0, 250)}
                  </p>
                </Col>
              </Row>
              <div className='d-flex justify-content-between'>
                <button
                  onClick={() => scrollBooks('left')}
                  className='btn btn-secondary round-button'
                >
                  {'<'}
                </button>
                <button
                  onClick={() => scrollBooks('right')}
                  className='btn btn-secondary round-button'
                >
                  {'>'}
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default HomePageKiller
