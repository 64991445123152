import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserCoins } from '../actions/coinActions'
import Paginate from '../components/Paginate'
import { MdAddAlert } from 'react-icons/md'
import { BsBoxSeam, BsCoin } from 'react-icons/bs'
import { FaTruck, FaCreditCard } from 'react-icons/fa'
import {
  reedemDiscountCode,
  listUserDiscountCode,
} from '../actions/discountCodeActions'
import { getWishlist, removeWishlist } from '../actions/wishlistActions'
import Product from '../components/Product'
import { Link } from 'react-router-dom'

const UserWishListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userWishLists = useSelector((state) => state.wishlistGet)
  const { loading, error, success: sucessWishlist, wishlists } = userWishLists

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userWishlistRemove = useSelector((state) => state.wishlistRemove)
  const { success: wishlistRemoveSucces } = userWishlistRemove

  useEffect(() => {
    if (userInfo) {
      dispatch(getWishlist())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, wishlistRemoveSucces])

  const removeFromWishlist = (id, e) => {
    e.preventDefault()
    dispatch(removeWishlist(id))
  }
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          {sucessWishlist && (
            <>
              <Row>
                <Col>
                  <h4>My Library</h4>
                </Col>
                <Col>
                  <Link to='/profile' className='btn btn-light float-right'>
                    Go back
                  </Link>
                </Col>
              </Row>
              <Row>
                {wishlists.length == 0 && (
                  <Message>No Books in your Library!</Message>
                )}
                {wishlists.map((wishlist) => (
                  // <Row key={wishlist._id}>
                  //         <Col xs={12}>
                  //          Code <strong>{wishlist.product.name}</strong>
                  //         </Col>
                  // </Row>
                  <Col key={wishlist.product._id} sm={6} md={4} lg={2} xs={4}>
                    <Route
                      render={({ history }) => (
                        <Product
                          history={history}
                          product={wishlist.product}
                          addtocart={false}
                        />
                      )}
                    />
                    <Link
                      className='text-center'
                      onClick={(e) =>
                        removeFromWishlist(wishlist.product._id, e)
                      }
                      to=''
                    >
                      <i className='fas fa-trash'></i>
                    </Link>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Container>
      )}
    </>
  )
}

export default UserWishListScreen
