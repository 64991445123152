import axios from 'axios'

import {
  USER_LIST_RESET,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCESS,
  USER_UPDATE_FAIL,
  USER_ADDRESS_REQUEST,
  USER_ADDRESS_SUCESS,
  USER_ADDRESS_FAIL,
  USER_ADDRESS_DELETE_REQUEST,
  USER_ADDRESS_DELETE_SUCESS,
  USER_ADDRESS_DELETE_FAIL,
  USER_OTP_VERIFY_REQUEST,
  USER_OTP_VERIFY_SUCESS,
  USER_OTP_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCESS,
  USER_VERIFY_FAIL,
  USER_OTP_VERIFY_RESET,
  USER_VERIFY_RESET,
  USER_REGISTER_RESET,
  USER_GUEST_CREATE_REQUEST,
  USER_GUEST_CREATE_SUCESS,
  USER_GUEST_CREATE_FAIL,
  USER_DESC_SUCESS,
  USER_DESC_REQUEST,
  USER_DESC_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCESS,
  USER_PROFILE_FAIL,
  USER_REGISTER_LP_FAIL,
  USER_REGISTER_LP_REQUEST,
  USER_REGISTER_LP_SUCESS,
  USER_ADDRESS_LP_REQUEST,
  USER_ADDRESS_LP_FAIL,
  USER_ADDRESS_LP_SUCESS,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCESS,
  OTP_VERIFY_FAIL,
  USER_ONBOARD_REQUEST,
  USER_ONBOARD_SUCESS,
  USER_ONBOARD_FAIL,
} from '../constants/userConstants'
import { MY_ORDERS_DETAILS_RESET } from '../constants/orderConstants'
import Cookies from 'universal-cookie'
import {
  USER_READING_LIST_REQUEST,
  USER_READING_LIST_SUCESS,
} from '../constants/readingConstants'

const cookies = new Cookies()
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      '/api/users/login',
      { email, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCESS,
      payload: data,
    })
    cookies.set('_incf', data, {
      path: '/',
      maxAge: 34550000,
    })
    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const logout = () => (dispatch) => {
  cookies.remove('_incf', {
    path: '/',
  })
  dispatch({
    type: USER_LOGOUT,
  })
  dispatch({
    type: USER_DETAILS_RESET,
  })
  dispatch({
    type: MY_ORDERS_DETAILS_RESET,
  })
  dispatch({
    type: USER_LIST_RESET,
  })
  dispatch({
    type: USER_LIST_RESET,
  })
  dispatch({
    type: USER_OTP_VERIFY_RESET,
  })
  dispatch({
    type: USER_VERIFY_RESET,
  })
  dispatch({
    type: USER_REGISTER_RESET,
  })
}

export const register = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post('/api/users', user, config)

    if (data.error) {
      dispatch({
        type: USER_REGISTER_SUCESS,
        payload: data,
      })
    } else {
      dispatch({
        type: USER_REGISTER_SUCESS,
        payload: data.user,
      })

      dispatch({
        type: USER_LOGIN_SUCESS,
        payload: data.user,
      })
      cookies.set('_incf', data.user, {
        path: '/',
        maxAge: 34550000,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    }
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/users/${id}`, config)

    dispatch({
      type: USER_DETAILS_SUCESS,
      payload: data[0],
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getUserProfile = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/users/profile/${id}`, config)

    dispatch({
      type: USER_PROFILE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({
      type: USER_UPDATE_PROFILE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listUsers = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/users?pageNumber=${pageNumber}`,
      config
    )

    dispatch({
      type: USER_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.delete(`/api/users/${id}`, config)

    dispatch({
      type: USER_DELETE_SUCESS,
    })
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_RESET,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/users/${user._id}`, user, config)

    dispatch({
      type: USER_UPDATE_SUCESS,
    })
    dispatch({ type: USER_DETAILS_SUCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const userAddressList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ADDRESS_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/users/shippingAddress/list`, config)

    dispatch({
      type: USER_ADDRESS_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_ADDRESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteUserAddress = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ADDRESS_DELETE_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(
      `/api/users/shippingAddress/delete/${id}`,
      config
    )

    dispatch({
      type: USER_ADDRESS_DELETE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_ADDRESS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const sendOTP = (phone) => async (dispatch) => {
  dispatch({
    type: USER_VERIFY_REQUEST,
  })
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get('/api/users/sendotp/' + phone, config)
    dispatch({
      type: USER_VERIFY_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const verifyOtp = (phone, otp, reg) => async (dispatch) => {
  try {
    dispatch({
      type: USER_OTP_VERIFY_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      `/api/users/verifyOtp`,
      { phone, otp, reg },
      config
    )

    dispatch({
      type: USER_OTP_VERIFY_SUCESS,
      payload: data,
    })
    if (data.result) {
      dispatch({
        type: USER_REGISTER_SUCESS,
        payload: data.user,
      })

      dispatch({
        type: USER_LOGIN_SUCESS,
        payload: data.user,
      })
      cookies.set('_incf', data.user, {
        path: '/',
        maxAge: 34550000,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_OTP_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const createGuestUserAddress = (datar) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_GUEST_CREATE_REQUEST,
    })
    var config = {
      method: 'post',
      url: '/api/users/guest',
      headers: {
        'Content-Type': 'application/json',
      },
      data: datar,
    }
    axios(config)
      .then(function (response) {
        if (response.data.result) {
          dispatch({
            type: USER_LOGIN_SUCESS,
            payload: response.data.user,
          })
          dispatch({
            type: USER_REGISTER_SUCESS,
            payload: response.data.user,
          })
          cookies.set('_incf', response.data.user, {
            path: '/',
            maxAge: 34550000,
          })
          dispatch({
            type: USER_GUEST_CREATE_SUCESS,
            payload: response.data.user,
          })
        } else {
          dispatch({
            type: USER_GUEST_CREATE_FAIL,
            payload: response.data,
          })
        }
      })
      .catch(function (error) {})
  } catch (error) {
    dispatch({
      type: USER_GUEST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const updateUserDesc = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DESC_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/users/updatedesc`, user, config)

    dispatch({ type: USER_DESC_SUCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_DESC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const forgotPassword = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    })

    const { data } = await axios.post(`/api/users/forgotpassword`, email)

    dispatch({ type: USER_FORGOT_PASSWORD_SUCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resetPasswordUser =
  (uid, rid, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST,
      })

      const { data } = await axios.post(`/api/users/resetpassword`, {
        id: uid,
        rid: rid,
        password,
      })

      dispatch({ type: USER_RESET_PASSWORD_SUCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_RESET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const userRegisterLP = (data1) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_LP_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post('/api/users/login/lp', data1, config)

    dispatch({
      type: USER_REGISTER_LP_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_REGISTER_LP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const userAddressLP = (data1) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADDRESS_LP_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      '/api/users/login/addAddress',
      data1,
      config
    )
    if (data.result) {
      dispatch({
        type: USER_LOGIN_SUCESS,
        payload: data.user,
      })
      dispatch({
        type: USER_REGISTER_SUCESS,
        payload: data.user,
      })
      cookies.set('_incf', data.user, {
        path: '/',
        maxAge: 34550000,
      })
      dispatch({
        type: USER_ADDRESS_LP_SUCESS,
        payload: data.user,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_ADDRESS_LP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const otpVerify = (data1) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OTP_VERIFY_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post('/api/users/userOtp', data1, config)

    dispatch({
      type: OTP_VERIFY_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: OTP_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const onboardUser = (data1) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ONBOARD_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post('/api/users/onboard', data1, config)

    dispatch({
      type: USER_ONBOARD_SUCESS,
      payload: data,
    })
    localStorage.setItem('onBorded', true)
  } catch (error) {
    dispatch({
      type: USER_ONBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
