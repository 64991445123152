export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const MY_ORDERS_DETAILS_REQUEST = 'MY_ORDERS_DETAILS_REQUEST'
export const MY_ORDERS_DETAILS_SUCCESS = 'MY_ORDERS_DETAILS_SUCCESS'
export const MY_ORDERS_DETAILS_FAIL = 'MY_ORDERS_DETAILS_FAIL'
export const MY_ORDERS_DETAILS_RESET = 'MY_ORDERS_DETAILS_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET'

export const ORDER_DELEVERED_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_DELEVERED_SUCCESS = 'ORDER_DELEVERED_SUCCESS'
export const ORDER_DELEVERED_FAIL = 'ORDER_DELEVERED_FAIL'
export const ORDER_DELEVERED_RESET = 'ORDER_DELEVERED_RESET'

export const ORDER_CANCEL_REQUEST = 'ORDER_CANCEL_REQUEST'
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS'
export const ORDER_CANCEL_FAIL = 'ORDER_CANCEL_FAIL'
export const ORDER_CANCEL_RESET = 'ORDER_CANCEL_RESET'

export const ORDER_CONFIRM_REQUEST = 'ORDER_CONFIRM_REQUEST'
export const ORDER_CONFIRM_SUCCESS = 'ORDER_CONFIRM_SUCCESS'
export const ORDER_CONFIRM_FAIL = 'ORDER_CONFIRM_FAIL'
export const ORDER_CONFIRM_RESET = 'ORDER_CONFIRM_RESET'

export const ORDER_PAYMENT_CREATE_REQUEST = 'ORDER_PAYMENT_CREATE_REQUEST'
export const ORDER_PAYMENT_CREATE_SUCCESS = 'ORDER_PAYMENT_CREATE_SUCCESS'
export const ORDER_PAYMENT_CREATE_FAIL = 'ORDER_PAYMENT_CREATE_FAIL'

export const ORDER_ADMIN_REQUEST = 'ORDER_ADMIN_REQUEST'
export const ORDER_ADMIN_SUCCESS = 'ORDER_ADMIN_SUCCESS'
export const ORDER_ADMIN_FAIL = 'ORDER_ADMIN_FAIL'

export const ORDER_COMMENT_REQUEST = 'ORDER_COMMENT_REQUEST'
export const ORDER_COMMENT_SUCCESS = 'ORDER_COMMENT_SUCCESS'
export const ORDER_COMMENT_FAIL = 'ORDER_COMMENT_FAIL'
export const ORDER_COMMENT_RESET = 'ORDER_COMMENT_RESET'

export const SHIPPING_STATE = ['Andhra Pradesh','Arunachal Pradesh','Assam,','Bihar','Chhattisgarh','Delhi','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu & Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal']