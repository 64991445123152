import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import Cookies from 'universal-cookie'

import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productDetailsReducer,
  productListReducer,
  productDeleteReducer,
  productBestSellingReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productRedirectReducer,
  productScanReducer,
  productUpsertReducer,
  getProductAttributesReducer,
  productListAllReducer,
} from './reducers/productReducers'
import {
  abandonCartReducer,
  cartReducer,
  abandonCartListReducer,
  emailPopUpReducer,
  subscriberedEmailListReducer,
  abandonCartDetailReducer,
  userCartReducer,
  userCartRemoveItemeducer,
  removeCartDiscountReducer,
  setCartPaymentMethodReducer,
  cartListReducer,
  setCartEmailReducer,
} from './reducers/cartReducers'
import {
  userAddressDeleteReducer,
  userAddressListReducer,
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userOtpVerifyReducer,
  userVerifyReducer,
  guestUserReducer,
  updateUserDescReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  userProfileReducer,
  userRegisterLPReducer,
  userAddressLPReducer,
  otpVerifyReducer,
  onBoardReducer,
} from './reducers/userReducers'
import {
  adminOrderListReducer,
  orderCommentListReducer,
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderConfirmReducer,
  orderListMyReducer,
  orderCancelReducer,
  orderListReducer,
  orderDeliverReducer,
  payOrderReducer,
} from './reducers/orderReducers'
import { CART_READ } from './constants/cartConstants'
import {
  categoryProductReducer,
  authorProductReducer,
} from './reducers/categoryReducers'
import {
  listUserDiscountsReducer,
  discountCodeCreateReducer,
  reedemDiscountReducer,
  discountCodeGetReducer,
  discountCodeListReducer,
} from './reducers/discountCodeReducer'
import {
  flowCreateReducers,
  flowDetailReducers,
  flowListReducers,
  flowTriggerCreateReducer,
  flowTriggerDeleteReducer,
} from './reducers/flowReducers'
import {
  assignAWBReducer,
  getShipmenteducer,
  shipmentCreateReducer,
} from './reducers/shipmentReducer'
import { userCoinReducer } from './reducers/coinReducers'
import { languageProductReducer } from './reducers/languageReducers'
import {
  wishlistRemoveReducer,
  alreadyReadRemoveReducer,
  intrestRemoveReducer,
  getAlreadyReadsByProductReducer,
  getIntrestsByProductReducer,
  wishlistAddReducer,
  wishlistGetReducer,
  intrestAddReducer,
  intrestGetReducer,
  alreadyReadAddReducer,
  alreadyReadGetReducer,
} from './reducers/wishlistReducer'
import {
  getUserReadingReducer,
  getReadingCountReducer,
  getReadingStatusReducer,
} from './reducers/readingReducers'
import { listEbookReducer } from './reducers/ebookReducers'
import {
  addToFormReducer,
  listContactFormReducer,
} from './reducers/formReducers'
import {
  addToSubmissionReducer,
  listEbookSubmissionReducer,
} from './reducers/submissionReducers'
import {
  createBookFairReducer,
  listBookFairReducer,
  getBookFairReducer,
  getBookFairIntrestReducer,
} from './reducers/bookFairReducers'
import {
  createBookFairEntryReducer,
  listBookFairEntryReducer,
  listBookFairEntryStatsReducer,
} from './reducers/bookFairEntryReducers'
import { listUserIntrestReducer } from './reducers/intrestReducers'
import {
  createSubscriptionReducer,
  updateSubscriptionReducer,
  listSubscriptionReducer,
} from './reducers/subscriptionReducers'
import {
  createEpisodeReducer,
  getEpisodeReducer,
  readEpisodeReducer,
  commentEpisodeReducer,
  listCommentEpisodeReducer,
  deleteEpisodeReducer,
  readFreeEpisodeReducer,
} from './reducers/episodeReducers'
import {
  createStoryReducer,
  listStoryReducer,
  getStoryReducer,
  listMyStoryReducer,
  deleteStoryReducer,
  myReadingStoryReducer,
  storybyCatReducer,
  storybyKeyReducer,
  storyListByGoalReducer,
  storyReadListReducer,
} from './reducers/storyReducers'
import {
  getFollowerReducer,
  getFollowingReducer,
} from './reducers/followerReducers'
import { callAiReducer } from './reducers/aiReducers'
import {
  createBlogReducer,
  listBlogReducer,
  getBlogReducer,
  editBlogReducer,
  deleteBlogReducer,
} from './reducers/blogReducers'
import {
  listCollectionReducer,
  getCollectionReducer,
} from './reducers/collectionReducers'
const reducer = combineReducers({
  productList: productListReducer,
  productTopRated: productTopRatedReducer,
  productBestSelling: productBestSellingReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  cart: cartReducer,
  abandonCart: abandonCartReducer,
  abandonCartList: abandonCartListReducer,
  cartList: cartListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userAddressList: userAddressListReducer,
  userAddressDelete: userAddressDeleteReducer,
  userCoin: userCoinReducer,
  userCart: userCartReducer,
  setCartEmail: setCartEmailReducer,
  userCartRemoveItem: userCartRemoveItemeducer,
  removeCartDiscount: removeCartDiscountReducer,
  setCartPaymentMethod: setCartPaymentMethodReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderDeliver: orderDeliverReducer,
  orderCancel: orderCancelReducer,
  orderConfirm: orderConfirmReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  guestUser: guestUserReducer,
  orderCommentList: orderCommentListReducer,
  orderPayStatus: orderPayReducer,
  orderPayRazor: payOrderReducer,
  categoryProduct: categoryProductReducer,
  authorProduct: authorProductReducer,
  adminOrderList: adminOrderListReducer,
  discountCodeCreate: discountCodeCreateReducer,
  discountCodeList: discountCodeListReducer,
  discountCodeGet: discountCodeGetReducer,
  reedemDiscount: reedemDiscountReducer,
  listUserDiscounts: listUserDiscountsReducer,
  emailPopUp: emailPopUpReducer,
  subscriberedEmailList: subscriberedEmailListReducer,
  abandonCartDetail: abandonCartDetailReducer,
  flowList: flowListReducers,
  flowDetail: flowDetailReducers,
  flowCreate: flowCreateReducers,
  flowDelete: flowDetailReducers,
  flowTriggerCreate: flowTriggerCreateReducer,
  flowTriggerDelete: flowTriggerDeleteReducer,
  shipmentCreate: shipmentCreateReducer,
  getShipment: getShipmenteducer,
  assignAWB: assignAWBReducer,
  languageProduct: languageProductReducer,
  wishlistAdd: wishlistAddReducer,
  wishlistGet: wishlistGetReducer,
  intrestAdd: intrestAddReducer,
  intrestGet: intrestGetReducer,
  listUserIntrest: listUserIntrestReducer,
  alreadyReadAdd: alreadyReadAddReducer,
  alreadyReadGet: alreadyReadGetReducer,
  getIntrestsByProduct: getIntrestsByProductReducer,
  getAlreadyReadsByProduct: getAlreadyReadsByProductReducer,
  wishlistRemove: wishlistRemoveReducer,
  intrestRemove: intrestRemoveReducer,
  alreadyReadRemove: alreadyReadRemoveReducer,
  getUserReading: getUserReadingReducer,
  listEbook: listEbookReducer,
  getReadingCount: getReadingCountReducer,
  getReadingStatus: getReadingStatusReducer,
  addToForm: addToFormReducer,
  addToSubmission: addToSubmissionReducer,
  listContactForm: listContactFormReducer,
  listEbookSubmission: listEbookSubmissionReducer,
  userOtpVerify: userOtpVerifyReducer,
  userVerify: userVerifyReducer,
  productRedirect: productRedirectReducer,
  createBookFair: createBookFairReducer,
  listBookFair: listBookFairReducer,
  getBookFairIntrest: getBookFairIntrestReducer,
  createBookFairEntry: createBookFairEntryReducer,
  listBookFairEntry: listBookFairEntryReducer,
  listBookFairEntryStats: listBookFairEntryStatsReducer,
  getBookFair: getBookFairReducer,
  productScan: productScanReducer,
  productUpsert: productUpsertReducer,
  createSubscription: createSubscriptionReducer,
  updateSubscription: updateSubscriptionReducer,
  listSubscription: listSubscriptionReducer,
  createEpisode: createEpisodeReducer,
  getEpisode: getEpisodeReducer,
  createStory: createStoryReducer,
  listStory: listStoryReducer,
  getStory: getStoryReducer,
  listMyStory: listMyStoryReducer,
  storybyCat: storybyCatReducer,
  readEpisode: readEpisodeReducer,
  commentEpisode: commentEpisodeReducer,
  listCommentEpisode: listCommentEpisodeReducer,
  deleteStory: deleteStoryReducer,
  deleteEpisode: deleteEpisodeReducer,
  myReadingStory: myReadingStoryReducer,
  updateUserDesc: updateUserDescReducer,
  getFollower: getFollowerReducer,
  getFollowing: getFollowingReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  userProfile: userProfileReducer,
  callAi: callAiReducer,
  getProductAttributes: getProductAttributesReducer,
  createBlog: createBlogReducer,
  listBlog: listBlogReducer,
  getBlog: getBlogReducer,
  productListAll: productListAllReducer,
  userAddressLP: userAddressLPReducer,
  userRegisterLP: userRegisterLPReducer,
  otpVerify: otpVerifyReducer,
  storybyKey: storybyKeyReducer,
  readFreeEpisode: readFreeEpisodeReducer,
  editBlog: editBlogReducer,
  deleteBlog: deleteBlogReducer,
  onBoard: onBoardReducer,
  storyListByGoal: storyListByGoalReducer,
  storyReadList: storyReadListReducer,
  listCollection: listCollectionReducer,
  getCollection: getCollectionReducer,
})
const cookies = new Cookies()

const emailPopUpFromStorage = localStorage.getItem('emailPopUpShown')
  ? JSON.parse(localStorage.getItem('emailPopUpShown'))
  : false
const revcentviewFromStorage = localStorage.getItem('recentView')
  ? JSON.parse(localStorage.getItem('recentView'))
  : []

const totalPriceFromStorage = localStorage.getItem('totalPrice')
  ? JSON.parse(localStorage.getItem('totalPrice'))
  : 0
const discountPriceFromStorage = localStorage.getItem('discountPrice')
  ? JSON.parse(localStorage.getItem('discountPrice'))
  : 0
const cartIemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []
const cartTypeFromStorage = localStorage.getItem('cartType')
  ? JSON.parse(localStorage.getItem('cartType'))
  : CART_READ

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : ''
const shippingMethodFromStorage = localStorage.getItem('shippingMethod')
  ? localStorage.getItem('shippingMethod')
  : 'standard'

const userInfoFromStorage = cookies.get('_incf') ? cookies.get('_incf') : null

const initialState = {
  cart: {
    cartItems: cartIemsFromStorage,
    cartType: cartTypeFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: localStorage.getItem('paymentMethod')
      ? JSON.parse(localStorage.getItem('paymentMethod'))
      : 'online',
    shippingMethod: shippingMethodFromStorage,
    recentView: revcentviewFromStorage,
    totalPrice: totalPriceFromStorage,
    discountPrice: discountPriceFromStorage,
  },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  emailPopUp: {
    emailPopUpShown: emailPopUpFromStorage,
  },
}
const middleWare = [thunk]
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
)

export default store
