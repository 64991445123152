import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listUsers, deleteUser } from '../../actions/userActions'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import BlogsMeta from '../../components/meta/blogsMeta'
import { getBlog, listBlog } from '../../actions/blogActions'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { Paper } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
const ViewBlogScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const getBlogRes = useSelector((state) => state.getBlog)
  const { loading, error, success, blog } = getBlogRes
  const [date, setDate] = useState('2018-12-28')
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getBlog(match.params.id))
    if (success) {
      setDate(blog.createdAt.substring(0, 10))
    }
  }, [dispatch])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <BlogsMeta blog={blog}></BlogsMeta>
          <div role='presentation'>
            <Breadcrumbs aria-label='breadcrumb' gutterBottom mt={1}>
              <Link underline='hover' color='inherit' to={'/blog'}>
                Blog
              </Link>
              <Typography color='text.primary'>Post</Typography>
            </Breadcrumbs>
          </div>
          {userInfo && userInfo.isAdmin && (
            <Link to={`/blog/edit/${blog._id}`}>
              <Button>Edit</Button>
            </Link>
          )}
          <Grid item xs={12} md={6}>
            <Card sx={{ display: 'flex' }}>
              <CardContent sx={{ flex: 1 }}>
                <div>
                  <h1 className='blog-title'>{blog && blog.title}</h1>
                  <Typography variant='subtitle'>
                    by <a href='#'>Pooja Rawath</a>. posted on{' '}
                    {blog.createdAt && blog.createdAt.substring(0, 10)}
                    <br />
                  </Typography>
                  <LazyLoadImage
                    src={blog && blog.image}
                    width={'65%'}
                    alt={blog.title + 'by second hand bookstore 99bookscart'}
                    className='text-center'
                  />
                </div>
                <Typography variant='subtitle1' paragraph>
                  <p
                    className='blog-text'
                    dangerouslySetInnerHTML={{
                      __html:
                        success &&
                        blog.description.replace(
                          /(<? *script)/gi,
                          'illegalscript'
                        ),
                    }}
                  ></p>
                </Typography>
                <div>
                  Tag:{' '}
                  {success &&
                    blog.topic.map((topic) => (
                      <span className='topic' key={topic}>
                        {topic}
                      </span>
                    ))}
                </div>
              </CardContent>
              {/* <CardMedia
                  component='img'
                  sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
                  image={blog.image}
                  alt={blog.name}
                /> */}
            </Card>
          </Grid>
          <Paper>
            <div style={{ padding: '10px' }}>
              <Typography component='h5' variant='h5'>
                About Author
              </Typography>
              <Typography variant='subtitle1' paragraph>
                Pooja Rawath is a passionate blogger and a book lover. Graduated
                in Literature and public journalism, she is currently pursuing
                her masters in English Literature. She has a keen interest in
                writing and reading novels. She loves to write about the latest
                books and about the book industry.
              </Typography>
              <Typography variant='subtitle2' paragraph>
                <Link to={'/contact-us'}>Contact Pooja Rawath</Link>
              </Typography>
            </div>
          </Paper>
          <br />
        </>
      )}
    </Container>
  )
}

export default ViewBlogScreen
