import crypto from 'crypto'
export function getCategory(id) {
  const categoryArray = [
    'Life Story',
    'Short Story',
    'Random',
    'Action',
    'Adventure',
    'Biography',
    'Erotica',
    'Fantasy',
    'General Fiction',
    'Historical Fiction',
    'Horror',
    'Health',
    'Mystery',
    'Non-Fiction',
    'Poetry',
    'Science Fiction',
    'Spiritual',
    'Self Help',
    'Travel',
    'Classic',
    'Literature',
  ]
  return categoryArray[id]
}

export function storyStatus(id) {
  const categoryArray = ['Draft', 'On Going', 'Completed']
  return categoryArray[id]
}

export function timeConvert(n) {
  var num = n
  if (n < 60) {
    return n + 'm'
  }
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  return rhours + 'h ' + rminutes + 'm'
}
export function myFunction(val) {
  return val
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export function timeConvertSummary(n) {
  var num = n / 200
  if (num < 60) {
    return Math.ceil(num) + ' minutes Read'
  }
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  return rhours + 'hours ' + rminutes + 'minutes'
}
export function convertToSlug(Text) {
  if (Text) {
    return Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }
}
export function convertToSlug2(Text) {
  return Text ? Text.toLowerCase().replace(/ /g, '-') : Text
}
export function makeTitle(slug) {
  var words = slug.split('-')

  for (var i = 0; i < words.length; i++) {
    var word = words[i]
    words[i] = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return words.join(' ')
}
export function isWebview() {
  const userAgent = window.navigator.userAgent.toLowerCase()

  return userAgent.includes('wv') ? true : false
}

export function decryptText(text) {
  var algorithm = 'aes256'
  var key = 'password'
  var decipher = crypto.createDecipher(algorithm, key)
  var decrypted = decipher.update(text, 'hex', 'utf8') + decipher.final('utf8')
  return decrypted
}

export function convertTimeAgo(date) {
  const currentDateUTC = new Date() // Current UTC date
  const ISTOffset = 5.5 * 3600 * 1000 // IST offset in milliseconds (5 hours 30 minutes)
  const currentDateIST = new Date(currentDateUTC.getTime() + ISTOffset) // Convert current UTC date to IST

  const pastDateUTC = new Date(date) // Convert given UTC date string to date object
  const pastDateIST = new Date(pastDateUTC.getTime() + ISTOffset) // Convert given date to IST

  const diffInSeconds = Math.floor((currentDateIST - pastDateIST) / 1000)

  let interval = Math.floor(diffInSeconds / 31536000)
  if (interval >= 1) {
    return interval + ' year' + (interval > 1 ? 's' : '') + ' ago'
  }
  interval = Math.floor(diffInSeconds / 2592000)
  if (interval >= 1) {
    return interval + ' month' + (interval > 1 ? 's' : '') + ' ago'
  }
  interval = Math.floor(diffInSeconds / 86400)
  if (interval >= 1) {
    return interval + ' day' + (interval > 1 ? 's' : '') + ' ago'
  }
  interval = Math.floor(diffInSeconds / 3600)
  if (interval >= 1) {
    return interval + ' hour' + (interval > 1 ? 's' : '') + ' ago'
  }
  interval = Math.floor(diffInSeconds / 60)
  if (interval >= 1) {
    return interval + ' minute' + (interval > 1 ? 's' : '') + ' ago'
  }
  return (
    Math.floor(diffInSeconds) +
    ' second' +
    (diffInSeconds > 1 ? 's' : '') +
    ' ago'
  )
}
