import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listAbandonCart } from '../actions/cartActions'
import { listUserIntrests } from '../actions/intrestActions'
const IntrestListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const listUserIntrest = useSelector((state) => state.listUserIntrest)
  const { loading, error, intrests, succees } = listUserIntrest

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUserIntrests())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, succees])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          <Row>
            <Col>
              <h4>Abandon Cart</h4>
            </Col>
            <Col>
              <Link to='/' className='btn btn-light float-right'>
                Go back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>USER</th>
                    <th>Book</th>
                  </tr>
                </thead>
                <tbody>
                  {intrests.map((intrest) => (
                    <tr key={intrest._id}>
                      <td>{intrest.user.name}</td>
                      <td>
                        {intrest.product.name}
                        <br />
                        {getISTTime(intrest.createdAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Row>
      )}
    </Container>
  )
}
const getISTTime = (date) => {
  let d = new Date(date)
  let newData = d.getTime() + 5.5 * 60 * 60
  let ist = new Date(newData)
  return (
    ist.getDate() +
    '/' +
    ist.getMonth() +
    '/' +
    ist.getFullYear() +
    ' ' +
    ist.getHours() +
    ':' +
    ist.getMinutes()
  )
}
export default IntrestListScreen
