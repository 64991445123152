import React, { useState, useEffect } from 'react'
import { Form, Button, Col, Row, Container, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader'
import Cookies from 'universal-cookie'
import { createSubscription } from '../../../actions/subscriptionActions'
import { FaBook, FaTimesCircle, FaBookOpen, FaArrowRight } from 'react-icons/fa'
import { FaLock, FaLockOpen, FaGem, FaPowerOff } from 'react-icons/fa'
import { logout } from '../../../actions/userActions'

const cookies = new Cookies()

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}
const BillingScreen = ({ history }) => {
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const dispatch = useDispatch()

  const [isBox, setIsBox] = useState(false)
  const [codCost, setCodCost] = useState('')
  const [payButtonClick, setPayButtonClick] = useState(false)
  const [payS, SetPayS] = useState(true)
  const [processingOrder, setProcessingOrder] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [isCodeApplied, setIsCodeApplied] = useState(false)
  const [isCodeA, setIsCodeA] = useState(false)
  const [displayCartError, setDisplayCartError] = useState(false)
  const [couponCodeError, setCouponCodeError] = useState(false)
  const [isCodeB, setIsCodeB] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [codeShow, setCodeShow] = useState(false)
  const [show, setShow] = useState(false)
  const [phone, setPhone] = useState(false)
  const [disable, setDisable] = useState(false)
  const [email, setEmail] = useState(false)
  const [name, setName] = useState(false)
  const [sid, setSid] = useState('0')
  const [emailError, setEmailError] = useState(null)
  const [flag, setFlag] = useState(true)
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cSubscription = useSelector((state) => state.createSubscription)
  const {
    loading,
    success: successSubscription,
    error: subcriptionError,
    subscription,
  } = cSubscription

  const uSubscription = useSelector((state) => state.updateSubscription)
  const {
    success: successSubscriptionUpdate,
    subscription: updatedSubs,
    loading: subUpdateLoading,
  } = uSubscription

  const paymentROrder = useSelector((state) => state.orderPayRazor)
  const {
    orderPayDetails,
    success: paySuccess,
    error: payError,
    loading: payLoading,
  } = paymentROrder

  const [selectedOption, setSelectedOption] = useState('')

  const handleSelection = (option) => {
    setSelectedOption(option)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }
  var options = {
    key: 'rzp_live_lBv4PeFBJfyAkX',
    // key: 'rzp_test_Uc74kK1ulMDQoS',
    currency: 'INR',
    name: 'NexNuggets',
    description: 'Learn More Read Less',
    image: 'https://www.NexNuggets.com/images/logo.png',

    handler: function (response) {
      setSid(response.razorpay_subscription_id)
      history.push(
        `/app/subscribed/${subscription.sid}/${response.razorpay_payment_id}/${response.razorpay_subscription_id}`
      )
      // dispatch(
      //   updateSubscriptions({
      //     razorpayId: response.razorpay_payment_id,
      //     subscriptionId: response.razorpay_subscription_id,
      //     status: 1,
      //   })
      // )

      timeout(3000)
    },
    prefill: {
      email: userInfo ? userInfo.email : '',
      contact: userInfo ? userInfo.phone : '',
    },
    theme: {
      color: '#022335',
    },
    modal: {
      ondismiss: function () {
        setFlag(true)
        setDisable(false)
      },
    },
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  const submitHandler2 = (e) => {
    e.preventDefault()

    if (!disable) {
      setDisable(true)
      dispatch(createSubscription(1))
    }
  }
  const submitHandler3 = (e) => {
    e.preventDefault()

    if (!disable) {
      setDisable(true)
      dispatch(createSubscription(2))
    }
  }
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (userInfo && userInfo.isSubscribed) {
        history.push('/app')
      }
    }
    window.scrollTo(0, 0)
    loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (successSubscription && flag && sid == '0') {
      setProcessingOrder(false)
      SetPayS(false)
      setFlag(false)
      options['subscription_id'] = subscription.id
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  }, [history, successSubscription])

  return (
    <>
      <Container>
        {subUpdateLoading && !successSubscriptionUpdate && <Loader></Loader>}
        <Container>
          <Row>
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              {/* <img
                src='../../images/logo.png'
                className='siteLogo2'
                alt='99bookscart logo'
              /> */}
              <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                Learn something new everyday
              </span>
              <br />
              <small className='text-muted'>Learn More, Read Less</small>

              <br />
              {/* <Row>
                <strong>Regular ₹4,999/Year</strong>
                <br />
                <small className='sub-text' style={{ marginBottom: '12px' }}>
                  90% Off
                </small>
                <br />
                <br />
              </Row> */}
              <Row>
                <div className='subscription-container'>
                  <div className='subscription-item'>
                    <FaLock className='icon locked' />
                    <div className='subscription-text'>
                      <p className='main-text'>
                        Start FREE trail by paying{' '}
                        <span style={{ fontSize: '23px', fontWeight: 'bold' }}>
                          ₹5.00
                        </span>
                      </p>
                      <p className='sub-text'>Refunded immediatly</p>
                    </div>
                  </div>
                  <div className='subscription-item'>
                    <FaLockOpen className='icon unlocked' />
                    <div className='subscription-text'>
                      <p className='main-text'>Trial Starts, Cancel Anytime</p>
                      <p className='sub-text'>
                        Enjoy 1,000+ Book Summaries for free
                      </p>
                    </div>
                  </div>
                  <div className='subscription-item'>
                    <FaGem className='icon gem' />
                    <div className='subscription-text'>
                      <p className='main-text'>
                        Autopay <span>₹99.00/month</span>
                      </p>
                      <p className='sub-text'>
                        After 7 days, autopay every month
                      </p>
                    </div>
                  </div>
                </div>
              </Row>
              {/* <Row>
                <Col xs={6}>
                  <div style={{ marginLeft: '20%' }}>
                    <Button
                      className='cart-header'
                      onClick={() => handleSelection('monthly')}
                      variant={
                        selectedOption === 'monthly'
                          ? 'primary'
                          : 'outline-primary'
                      }
                      size='md'
                      block
                    >
                      <span style={{ fontSize: '18px' }}>₹99/Month</span>
                    </Button>
                    <br />{' '}
                    <small style={{ marginTop: '10px' }}>Billed Monthly</small>
                  </div>
                </Col>
                <Col xs={6}>
                  <div style={{ marginRight: '20%' }}>
                    <Button
                      className='cart-header'
                      onClick={() => handleSelection('yearly')}
                      variant={
                        selectedOption === 'yearly'
                          ? 'primary'
                          : 'outline-primary'
                      }
                      size='md'
                      block
                    >
                      <span style={{ fontSize: '18px' }}>₹999/Year</span>
                    </Button>
                    <br />
                    <small style={{ marginTop: '10px' }}>Billed Annually</small>
                  </div>
                </Col>
              </Row> */}
              {/* <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements> */}
              <Row
                style={{
                  maxWidth: '650px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginBottom: '15px',
                }}
                className='text-muted'
              >
                <Col>
                  Shortform
                  <br />
                  <strike> ₹1,499.00/month</strike>
                </Col>
                <Col>
                  Blinkist
                  <br />
                  <strike> ₹999.00/month</strike>
                </Col>
              </Row>
              <Row
                style={{
                  maxWidth: '650px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              >
                <Col style={{ float: 'right' }}>
                  <div
                    style={{
                      marginBottom: '12px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Start your subscription
                  </div>
                  <Button
                    type='submit'
                    disable={disable ? disable : false}
                    onClick={submitHandler2}
                    className='subButton'
                    style={{
                      backgroundColor: '#229954',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    ₹99.00/month <FaArrowRight style={{ color: '#fff' }} />
                  </Button>
                </Col>
                <Col style={{ float: 'left' }}>
                  <div
                    style={{
                      marginBottom: '12px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Start your free trail
                  </div>
                  <Button
                    type='submit'
                    disable={disable ? disable : false}
                    onClick={submitHandler3}
                    className='subButton'
                    style={{
                      backgroundColor: '#229954',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    ₹999.00/Year <FaArrowRight style={{ color: '#fff' }} />
                  </Button>
                </Col>
              </Row>

              <br />
              <small>
                <br />
                Cancel your Subscription at any time before it ends, and you
                won’t be charged.
              </small>
            </div>
          </Row>
          <Row className='subpage-gaurente'>
            <Col>
              <FaBook
                style={{
                  marginRight: '8px',
                  fontSize: '20px',
                  color: '#022335',
                }}
              />
              New Books Every Week
            </Col>
            <Col>
              <FaLockOpen
                style={{
                  marginRight: '8px',
                  fontSize: '20px',
                  color: '#022335',
                }}
              />
              New Guides Every Month
            </Col>
            <Col>
              <FaBookOpen
                style={{
                  marginRight: '8px',
                  fontSize: '20px',
                  color: '#022335',
                }}
              />
              Full Access to Every Book
            </Col>
          </Row>
          <Row className='text-center'>
            <h3 className='billing-head'>Understand key ideas in 15 minutes</h3>
          </Row>
          <Row
            style={{
              backgroundColor: '#E0E0E0',
              padding: '12px',
              textAlign: 'center',
            }}
          >
            <Col xs={12} sm={4}>
              <img
                src='../../images/icon1.png'
                alt='Know more in minutes'
                style={{ width: '90px' }}
              />
              <h5 className='billing-points'>Know more in minutes</h5>
              Get the key insights from today's best books, podcasts, and
              articles.
            </Col>
            <Col xs={12} sm={4}>
              <img
                src='../../images/icon3.png'
                alt='Feed your curiosity'
                style={{ width: '90px' }}
              />
              <h5 className='billing-points'>Feed your curiosity</h5>
              Personal recommendations to dive into 1,500+ titles and hundreds
              of topics.
            </Col>
            <Col xs={12} sm={4}>
              <img
                src='../../images/icon2.png'
                alt='An expert in your ear'
                style={{ width: '90px' }}
              />
              <h5 className='billing-points'>An expert in your ear</h5>
              Learn from experts through step-by-step guides & exclusive
              personal insights.
            </Col>
          </Row>
          <Row>
            <div className='subpage-contact'>
              <h3>Have any questions before upgrading</h3>
              <div style={{ fontSize: '13px' }}>
                If you have any questions about your NexNuggets subscription,
                get in touch with us. We'd be glad to help you.
              </div>
              <Link to={'/contact-us'}>
                <Link to={'/contact-us'}>
                  <Button className='subbutton'>Contact Us</Button>
                </Link>
              </Link>
            </div>
          </Row>
          <Row>
            <Col className='logoutSection'>
              <Button
                variant='primary'
                className='btn'
                style={{ float: 'center', marginTop: '5%' }}
                onClick={logoutHandler}
              >
                <FaPowerOff style={{ color: '#fff' }} />
              </Button>
              <br />
              <small>Logout</small>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default BillingScreen
