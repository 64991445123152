import React from 'react'
import { Helmet } from 'react-helmet'
const ProductMeta = ({ product }) => {
  // const slug = convertToSlug(product.name)
  var slug = ''
  var stock = 'INSTOCK'
  if (product.name) {
    slug = convertToSlug(product.name)
    stock = product.countInStock > 0 ? 'INSTOCK' : 'OUTOFSTOCK'
  }
  const url = 'https://www.99bookscart.com/book/' + product.isbn + '/' + slug
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Book',
    '@id': url,
    url: url,
    name: product.name,
    headline: product.name,
    description: product.description,
    image: product.image,
    datePublished: product.edition,
    bookEdition: 'PaperBack',
    bookFormat: 'https://schema.org/Paperback',
    author: {
      '@type': 'Person',
      name: product.author,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      name: product.name,
      ratingValue: product.grating,
      reviewCount: product.gnumReviews,
      bestRating: 5,
      worstRating: 1,
    },
    inLanguage: 'en',
    workExample: {
      '@type': 'Book',
      '@id': url,
      inLanguage: 'en',
      name: product.name,
      isbn: product.isbn,
      bookEdition: product.edition,
      datePublished: product.edition,
      bookFormat: 'https://schema.org/Paperback',
      author: {
        '@type': 'Person',
        name: product.author,
      },
      url: url,
      potentialAction: {
        '@type': 'ReadAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: url,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/AndroidPlatform',
            'http://schema.org/IOSPlatform',
          ],
        },
        expectsAcceptanceOf: [
          {
            '@type': 'Offer',
            category: 'nologinrequired',
            availabilityStarts: '2020-01-01T11:0:00-04:00',
            availabilityEnds: '2050-06-30T23:59:00-04:00',
            priceCurrency: 'INR',
            availability: 'https://schema.org' + stock,
            price: product.salePrice,
            name: product.name,
            eligibleRegion: [
              {
                '@type': 'Country',
                name: 'IN',
              },
            ],
          },
        ],
      },
    },
  }
  return (
    <Helmet>
      <title>{`Buy ${product.name} book by ${product.author} `}</title>
      <meta charset='UTF-8' />

      <meta
        property='og:title'
        content={`Buy ${product.name} book by ${product.author}`}
      />

      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='book:isbn' />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={product.image} />
      <meta property='og:description' content={product.description} />
      <meta property='og:site_name' content='99bookscart' />
      <meta property='product:brand' content='99bookscart' />
      <meta property='product:availability' content='in stock' />
      <meta property='product:condition' content='new' />
      <meta property='product:price:amount' content={product.salePrice} />
      <meta property='product:price:currency' content='INR' />
      <meta
        name='title'
        content={`Buy ${product.name} book by ${product.author}`}
      ></meta>
      <meta
        name='description'
        content={`${product.name} book is a ${product.description}`}
      ></meta>
      <meta name='keyword' content={product.name}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      <link rel='canonical' href={url}></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(articleStructuredData)}
      </script>
    </Helmet>
  )
}
ProductMeta.defaultProps = {
  title: '99bookscart',
  description: 'online bookstore',
  keyword: 'buy used books',
}
function convertToSlug(string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default ProductMeta
