import {
  DISCOUNT_CODE_REMOVE_FAIL,
  DISCOUNT_CODE_REMOVE_REQUEST,
  DISCOUNT_CODE_REMOVE_SUCCESS,
} from '../constants/cartConstants'
import {
  DISCOUNT_CODE_CREATE_FAIL,
  DISCOUNT_CODE_CREATE_REQUEST,
  DISCOUNT_CODE_CREATE_RESET,
  DISCOUNT_CODE_CREATE_SUCCESS,
  DISCOUNT_CODE_FAIL,
  DISCOUNT_CODE_LIST_FAIL,
  DISCOUNT_CODE_LIST_REQUEST,
  DISCOUNT_CODE_LIST_SUCCESS,
  DISCOUNT_CODE_REEDEM_FAIL,
  DISCOUNT_CODE_REEDEM_REQUEST,
  DISCOUNT_CODE_REEDEM_SUCCESS,
  DISCOUNT_CODE_REQUEST,
  DISCOUNT_CODE_RESET,
  DISCOUNT_CODE_SUCCESS,
  USER_DISCOUNT_CODE_LIST_FAIL,
  USER_DISCOUNT_CODE_LIST_REQUEST,
  USER_DISCOUNT_CODE_LIST_SUCCESS,
} from '../constants/discountCodeConstants'

export const discountCodeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNT_CODE_CREATE_REQUEST:
      return {
        loading: true,
      }
    case DISCOUNT_CODE_CREATE_SUCCESS:
      return {
        loading: false,
        discountCode: action.payload,
        success: true,
      }
    case DISCOUNT_CODE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case DISCOUNT_CODE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const discountCodeListReducer = (
  state = { discountCodes: [] },
  action
) => {
  switch (action.type) {
    case DISCOUNT_CODE_LIST_REQUEST:
      return {
        loading: true,
      }
    case DISCOUNT_CODE_LIST_SUCCESS:
      return {
        loading: false,
        discountCodes: action.payload,
        success: true,
      }
    case DISCOUNT_CODE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const discountCodeGetReducer = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNT_CODE_REQUEST:
      return {
        loading: true,
      }
    case DISCOUNT_CODE_SUCCESS:
      return {
        loading: false,
        discountCode: action.payload,
        success: true,
      }
    case DISCOUNT_CODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case DISCOUNT_CODE_RESET:
      return {}
    default:
      return state
  }
}

export const reedemDiscountReducer = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNT_CODE_REEDEM_REQUEST:
      return {
        loading: true,
      }
    case DISCOUNT_CODE_REEDEM_SUCCESS:
      return {
        loading: false,
        discountCode: action.payload,
        success: true,
      }
    case DISCOUNT_CODE_REEDEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const listUserDiscountsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DISCOUNT_CODE_LIST_REQUEST:
      return {
        loading: true,
      }
    case USER_DISCOUNT_CODE_LIST_SUCCESS:
      return {
        loading: false,
        discounts: action.payload,
        success: true,
      }
    case USER_DISCOUNT_CODE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
