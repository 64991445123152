import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Pagination,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listEbookSubmission } from '../actions/submissionActions'
import SearchBox from '../components/SearchBox'
import { Route } from 'react-router-dom'

const EbookSubmissionsScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const submissionList = useSelector((state) => state.listEbookSubmission)
  const { loading, error, submissions, success } = submissionList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo.isAdmin) {
      history.push('/login')
    } else if (loading) {
      dispatch(listEbookSubmission())
    }
  }, [dispatch, history, userInfo, submissions])

  return (
    <Container>
      <Row className='align-items-center'>
        <Col>
          <h1>Ebook Submissions</h1>
        </Col>
      </Row>

      {error && <Message>{error}</Message>}
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Book Details</th>
                <th>Image</th>
                <th>Details</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {submissions.map((submission) => (
                <tr key={submission._id}>
                  <td>
                    {submission.name}
                    <br />
                    {submission.email}
                    <br />
                    {submission.contact}
                  </td>
                  <td>
                    Title:{submission.title}
                    <br />
                    Descritpion:{submission.description}
                  </td>
                  <td>
                    <img src={submission.coverPage} width={160} />
                    <a href={submission.ebookFile}>Epub File</a>
                  </td>
                  <td>
                    Genre:{submission.genre}
                    <br />
                    Author:{submission.author}
                    <br />
                    Publisher:{submission.publisher}
                  </td>
                  <td>
                    Pages:{submission.pages}
                    <br />
                    Published Year:{submission.publishingYear}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default EbookSubmissionsScreen
