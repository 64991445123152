import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listMyOrders } from '../actions/orderActions'
import Paginate from '../components/Paginate'
import { MdAddAlert } from 'react-icons/md'
import { BsBoxSeam, BsCoin } from 'react-icons/bs'
import { FaTruck, FaCreditCard } from 'react-icons/fa'
const UserOrderListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const cancelOrders = match.params.cancel

  const orderListMy = useSelector((state) => state.orderListMy)
  const { loading, error, orders } = orderListMy

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(listMyOrders())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, cancelOrders, match])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container>
          <Row>
            <Col>
              <h1>Orders</h1>
            </Col>
            <Col>
              <Link to='/profile' className='btn btn-light float-right'>
                Go back
              </Link>
            </Col>
          </Row>

          {orders.length == 0 && (
            <Message>No orders has been placed yet!</Message>
          )}
          {orders.map((order) => (
            <Row key={order._id} className='order-row'>
              <Row>
                <Col xs={6}>
                  <Link to={`/order/${order._id}`}>
                    {' '}
                    {order.orderName}
                  </Link>{' '}
                  <br />
                  <small>&#8377;{order.totalPrice}</small>
                </Col>
                <Col xs={6}>
                  <div className='float-right'>
                    {order.status}
                    <br />
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button className='btn-sm float-right' variant='dark'>
                        Details
                      </Button>
                    </LinkContainer>
                  </div>
                </Col>
              </Row>
            </Row>
          ))}
        </Container>
      )}
    </Container>
  )
}

export default UserOrderListScreen
