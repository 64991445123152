import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, listStoryByCat } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { register } from '../../actions/userActions'
import SocialLogin from '../../components/SocialLogin'

import { getCategory } from '../../helpers/helpers'
import Loader from '../../components/Loader'
import Story from '../../components/Story'
import StoryStats from '../../components/StoryStats'
import { FaBookReader } from 'react-icons/fa'
import SocialShare from '../../components/SocialShare'
import PaginateStory from '../../components/PaginateStory'
import SubLogin from '../../components/SubLogin'
import SubNav from '../../components/SubNav'

const StoriesListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const category = match.params.category || ''
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const storybyCat = useSelector((state) => state.storybyCat)
  const { stories, loading, error, suceess, pages, page } = storybyCat
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const showMore = () => {
    setShow(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listStoryByCat(category, pageNumber))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, pageNumber, category, userInfo, registerSuccess, emailError])
  return (
    <>
      <Container>
        <SubNav history={history}></SubNav>
        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <>
            <h1>{category} Books</h1>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <Story history={history} story={story} />
                      )}
                    />
                  </Col>
                ))}
              <Button
                onClick={showMore}
                style={{
                  width: '40%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                Show More
              </Button>
            </Row>
            <Modal show={show} onHide={handleClose} backdrop='static'>
              <Modal.Body>
                <Form onSubmit={submitHandler2}>
                  <div>
                    <Button className='close-button' onClick={handleClose}>
                      X
                    </Button>
                  </div>
                  <div style={{ padding: '20px' }}>
                    <Form.Group>
                      <div
                        style={{ marginBottom: '20px', textAlign: 'center' }}
                      >
                        <img
                          src='../../images/logo.png'
                          className='siteLogo'
                          alt='99bookscart logo'
                        />
                        <br />
                        <h5>
                          Join the Coommunity of More than 1.2 Lakh Reader
                        </h5>
                        <br />
                        <div className='social-logins'>
                          <SocialLogin />
                        </div>
                      </div>
                      <Form.Text className='red-text'>
                        {errorMessage && <span>{errorMessage}</span>}
                      </Form.Text>
                      <Form.Control
                        type='text'
                        placeholder='Name'
                        required
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>

                      <Form.Text className='red-text'>
                        {emailError && <span>{emailError}</span>}
                      </Form.Text>

                      <Form.Control
                        type='email'
                        placeholder='Email'
                        required
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                      <Form.Text className='red-text'>
                        {passwordError && <span>{passwordError}</span>}
                      </Form.Text>
                      <Form.Control
                        type='password'
                        placeholder='Passsword'
                        required
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Button
                          type='submit'
                          variant='primary'
                          className='float-right'
                        >
                          Sign Up
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </>
  )
}
{
}

export default StoriesListScreen
