import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storyListByGoal } from '../../actions/storyActions'
import { Link } from 'react-router-dom'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import { listCollection } from '../../actions/collectionActions'
import Loader from '../Loader'
const Collection = () => {
  const dispatch = useDispatch()

  const { collections, loading, error, success } = useSelector(
    (state) => state.listCollection
  )

  useEffect(() => {
    dispatch(listCollection())
  }, [dispatch])
  return (
    <Container>
      <div
        style={{
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          marginTop: '18px',
          marginBottom: '18px',
        }}
      >
        For lifelong learners, busy professionals, or someone just curious about
        the world
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '34px',
          fontWeight: 'bold',
        }}
      >
        Read in minutes not in hours
      </div>
      
      {loading && <Loader />}
      {success && (
        <Row>
          {collections.map((st) => (
            <Col
              xs={6}
              sm={3}
              style={{
                textAlign: 'center',
                fontSize: '16px',
                padding: '12px',
                backgroundColor: '#e5f9fc',
                paddingBottom: '20px',
              }}
            >
              <Link to={`/collection/${st.slug}`}>
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  {st.title}
                </div>
                <div>
                  {' '}
                  <img
                    src={st.image}
                    alt={st.title}
                    className='img-fluid'
                    width={'130px'}
                  />{' '}
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  )
}

export default Collection
