import React from 'react'
import { ProgressBar } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
const BoxProgressBar = ({ cart }) => {
  const history = useHistory()
  const onBarClick = () => {
    history.push(`/cart/`)
  }
  const pragressVaraint = 'success'
  const newValue = cart.length > 9 ? 9 : cart.length
  const ProgressValue = newValue * 11.11111

  return (
    ProgressValue > 0 && (
      <>
        <small className='mobile-center text-muted'>
          {cart.length > 0 && '9 Books for ₹999/-'}
        </small>
        <ProgressBar
          variant={pragressVaraint}
          now={ProgressValue}
          onClick={onBarClick}
        />
        <small className='mobile-center'>
          {cart.length > 0 && 'Bookscart Box (' + newValue + '/9 Books)'}
        </small>
      </>
    )
  )
}

export default BoxProgressBar
