import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { createBlog } from '../../actions/blogActions'
import Loader from '../../components/Loader'
import SmallLoader from '../../components/SmallLoader'
import { myFunction } from '../../helpers/helpers'

const CreateBlogScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const storyId = match.params.sid || null
  const episode = match.params.id || null
  const [metaTitle, setmetaTitle] = useState('')
  const [meateDesc, setmeateDesc] = useState('')
  const [title, setTitle] = useState('')
  const [image, setImage] = useState('')
  const [topic, setTopic] = useState([])
  const [topicLineItems, setTopicLineItems] = useState([])
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const createBlogRes = useSelector((state) => state.createBlog)
  const { blog, loading, error, suceess } = createBlogRes
  const editorRef = useRef(null)
  const formLabel = {
    color: '#000',
    fontSize: '16px',
    fontWeight: 'bold',
  }
  const removeCoverImage = () => {
    setUploaded(false)
  }
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploaded(true)
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }
  const addTags = () => {
    topicLineItems.push(topic)
    setTopic('')
  }
  const removeTags = (item, e) => {
    e.preventDefault()
    const index = topicLineItems.indexOf(item)
    if (index > -1) {
      topicLineItems.splice(index, 1)
    }
    setTopicLineItems(topicLineItems)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createBlog({
        title: title,
        image: image,
        topic: topicLineItems,
        description: description,
        metaTitle: metaTitle,
        metaDesc: meateDesc,
      })
    )
  }
  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      if (suceess) {
        history.push(`/blog/view/${blog._id}/${myFunction(blog.title)}`)
      }
    } else {
      history.push('/login')
    }
  }, [history, suceess, blog])
  return (
    <Container>
      {false ? (
        <Loader></Loader>
      ) : (
        <>
          <Row>
            <Col>
              <h5>Create Blog</h5>
            </Col>
          </Row>

          <Form onSubmit={submitHandler}>
            <Row>
              <Col sm={6}>
                <Form.Group className='mb-3' controlId='metatitle'>
                  <Form.Label style={formLabel}>Meta Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={metaTitle}
                    onChange={(e) => setmetaTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='metadesc'>
                  <Form.Label style={formLabel}>Meta Desc</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={meateDesc}
                    onChange={(e) => setmeateDesc(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='Storytitle'>
                  <Form.Label style={formLabel}>Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Untitled Story'
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='tags'>
                  <Form.Label style={formLabel}>Tags</Form.Label>
                  <Row>
                    <Col xs={9}>
                      <Form.Control
                        type='text'
                        placeholder='Tags'
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                      />
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant='primary'
                        className='mb-10'
                        style={{ float: 'left' }}
                        onClick={addTags}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>

                  {topicLineItems.length > 0 && (
                    <div>
                      {topicLineItems.map((item) => (
                        <small className='topic' key={item}>
                          {item}{' '}
                          <Link to='' onClick={(e) => removeTags(item, e)}>
                            X
                          </Link>
                        </small>
                      ))}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId='coverimage' className='mb-3'>
                  <img src={image} width={'130px'} />
                  <br />
                  {!uploaded ? (
                    <div className=''>
                      <Form.Label style={formLabel}>Cover Image</Form.Label>
                      <Form.Control
                        type='hidden'
                        placeholder='Add Cover Image'
                        value={image}
                        required
                        onChange={(e) => setImage(e.target.value)}
                      ></Form.Control>
                      <Form.File
                        id='image-file'
                        custom
                        onChange={uploadHandler}
                      ></Form.File>
                      {uploading && <SmallLoader></SmallLoader>}
                      <br />
                    </div>
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-sm'
                      onClick={() => removeCoverImage()}
                    >
                      Delete
                    </Button>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className='mb-3' controlId='description'>
                  <Form.Label style={formLabel}>Description</Form.Label>
                  <Editor
                    apiKey='g547goe8xzsy9huuryqujg4tz7deth3i6cqhgr3173uv4ifc'
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={description}
                    onEditorChange={(newValue, editor) =>
                      setDescription(newValue)
                    }
                    init={{
                      height: 600,

                      plugins:
                        'autolink directionality visualblocks visualchars  link media   table charmap pagebreak nonbreaking anchor   lists wordcount   help',
                      toolbar:
                        'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </Form.Group>

                <div style={{ float: 'right' }}>
                  <Button variant='primary' type='submit'>
                    Create Story
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  )
}

export default CreateBlogScreen
