import {
  COLLECTION_GET_FAIL,
  COLLECTION_GET_REQUEST,
  COLLECTION_GET_SUCCESS,
  COLLECTION_LIST_FAIL,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
} from '../constants/collectionConstants'
import {} from '../constants/userConstants'

export const listCollectionReducer = (state = { collections: [] }, action) => {
  switch (action.type) {
    case COLLECTION_LIST_REQUEST:
      return {
        loading: true,
      }
    case COLLECTION_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        collections: action.payload.collections,
      }
    case COLLECTION_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getCollectionReducer = (state = { collection: {} }, action) => {
  switch (action.type) {
    case COLLECTION_GET_REQUEST:
      return {
        loading: true,
      }
    case COLLECTION_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        collection: action.payload,
      }
    case COLLECTION_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
