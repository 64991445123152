import {
  ABANDON_CART_DETAILS_FAIL,
  ABANDON_CART_DETAILS_REQUEST,
  ABANDON_CART_DETAILS_SUCCESS,
  ABANDON_CART_FAIL,
  ABANDON_CART_LIST_FAIL,
  ABANDON_CART_LIST_REQUEST,
  ABANDON_CART_LIST_SUCCESS,
  ABANDON_CART_REQUEST,
  ABANDON_CART_RESET,
  ABANDON_CART_SUCCESS,
  CART_ADD_ITEM,
  CART_LIST_FAIL,
  CART_LIST_REQUEST,
  CART_LIST_SUCCESS,
  CART_READ,
  CART_READER,
  CART_READEST,
  CART_REMOVE_ITEM,
  CART_RESET,
  CART_SAVE,
  CART_SAVE_DISCOUNT,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_SHIPPING_METHOD,
  CART_SAVE_TOTAL,
  DISCOUNT_CODE_REMOVE_FAIL,
  DISCOUNT_CODE_REMOVE_REQUEST,
  DISCOUNT_CODE_REMOVE_SUCCESS,
  EMAIL_POP_UP_FAIL,
  EMAIL_POP_UP_REQUEST,
  EMAIL_POP_UP_SUCCESS,
  EMAIL_SUBMIT_FAIL,
  EMAIL_SUBMIT_LIST_FAIL,
  EMAIL_SUBMIT_LIST_REQUEST,
  EMAIL_SUBMIT_LIST_SUCCESS,
  EMAIL_SUBMIT_REQUEST,
  EMAIL_SUBMIT_SUCCESS,
  RECENT_VIEW_ADD_ITEM,
  USER_CART_EMAIL_SAVE_FAIL,
  USER_CART_EMAIL_SAVE_REQUEST,
  USER_CART_EMAIL_SAVE_SUCCESS,
  USER_CART_FAIL,
  USER_CART_PAYMENT_FAIL,
  USER_CART_PAYMENT_REQUEST,
  USER_CART_PAYMENT_SUCCESS,
  USER_CART_REMOVE_FAIL,
  USER_CART_REMOVE_REQUEST,
  USER_CART_REMOVE_SUCCESS,
  USER_CART_REQUEST,
  USER_CART_RESET,
  USER_CART_SUCCESS,
  WISHLIST_ADD_ITEM,
} from '../constants/cartConstants'

export const cartReducer = (
  state = {
    cartItems: [],
    cartType: CART_READ,
    shippingAddress: {},
    cartSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      const existItem = state.cartItems.find((x) => x.product === item.product)
      if (item.variantSelected) {
        const cartType =
          state.cartItems.length + 1 > 9
            ? state.cartItems.length + 1 > 14
              ? CART_READEST
              : CART_READER
            : CART_READ
        return {
          ...state,
          cartItems: [...state.cartItems, item],
          cartType: cartType,
          cartSuccess: true,
        }
      } else if (existItem) {
        const cartType =
          state.cartItems.length > 9
            ? state.cartItems.length > 14
              ? CART_READEST
              : CART_READER
            : CART_READ
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
          cartType: cartType,
        }
      } else {
        const cartType =
          state.cartItems.length + 1 > 9
            ? state.cartItems.length + 1 > 14
              ? CART_READEST
              : CART_READER
            : CART_READ
        return {
          ...state,
          cartItems: [...state.cartItems, item],
          cartType: cartType,
        }
      }
    case CART_REMOVE_ITEM:
      const cartNewType =
        state.cartItems.length > 9
          ? state.cartItems.length > 14
            ? CART_READEST
            : CART_READER
          : CART_READ
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product != action.payload),
        cartType: cartNewType,
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        cartSuccess: true,
        ...state,
        shippingAddress: action.payload,
      }
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case CART_SAVE_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethod: action.payload,
      }
    case CART_SAVE_TOTAL:
      return {
        ...state,
        totalPrice: action.payload,
      }
    case CART_SAVE_DISCOUNT:
      return {
        ...state,
        discountPrice: action.payload,
      }
    case CART_RESET:
      if (state.paymentMethod == 'cod') {
        state.paymentMethod = 'online'
      }
      return {
        ...state,
        cartItems: [],
      }
    case RECENT_VIEW_ADD_ITEM:
      const recentItem = action.payload
      const recentExistItem = state.recentView.find(
        (x) => x.product === recentItem.product
      )
      if (recentExistItem) {
        return {
          ...state,
          recentView: state.recentView.map((x) =>
            x.product === recentItem.product ? recentItem : x
          ),
        }
      } else {
        if (state.recentView.length > 11) {
          state.recentView = state.recentView.slice(-10)
          localStorage.setItem('recentView', JSON.stringify(state.recentView))
        }
        return {
          ...state,
          recentView: [...state.recentView, recentItem],
        }
      }

    case CART_SAVE:
      return {
        ...state,
        itemPrice: action.payload,
      }
    default:
      return state
  }
}

export const abandonCartReducer = (state = { abandonCart: {} }, action) => {
  switch (action.type) {
    case ABANDON_CART_REQUEST:
      return {
        loading: true,
      }
    case ABANDON_CART_SUCCESS:
      return {
        loading: false,
        abandonCart: action.payload,
      }
    case ABANDON_CART_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ABANDON_CART_RESET:
      return {}
    default:
      return state
  }
}
export const userCartReducer = (
  state = { cart: { cartItems: [] }, success: false },
  action
) => {
  switch (action.type) {
    case USER_CART_REQUEST:
      return {
        loading: true,
      }
    case USER_CART_SUCCESS:
      return {
        loading: false,
        cart: action.payload,
        success: true,
      }
    case USER_CART_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case USER_CART_RESET:
      return {}
    default:
      return state
  }
}
export const userCartRemoveItemeducer = (
  state = { cart: { cartItems: [] } },
  action
) => {
  switch (action.type) {
    case USER_CART_REMOVE_REQUEST:
      return {
        loading: true,
      }
    case USER_CART_REMOVE_SUCCESS:
      return {
        loading: false,
        cart: action.payload,
        success: true,
      }
    case USER_CART_REMOVE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const abandonCartListReducer = (
  state = { abandonCartList: [] },
  action
) => {
  switch (action.type) {
    case ABANDON_CART_LIST_REQUEST:
      return {
        loading: true,
      }
    case ABANDON_CART_LIST_SUCCESS:
      return {
        loading: false,
        abandonCartList: action.payload,
      }
    case ABANDON_CART_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const cartListReducer = (
  state = { cartList: [], success: false },
  action
) => {
  switch (action.type) {
    case CART_LIST_REQUEST:
      return {
        loading: true,
      }
    case CART_LIST_SUCCESS:
      return {
        loading: false,
        cartList: action.payload,
        success: true,
      }
    case CART_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const abandonCartDetailReducer = (
  state = { loading: true, abandonCart: {} },
  action
) => {
  switch (action.type) {
    case ABANDON_CART_DETAILS_REQUEST:
      return {
        loading: true,
      }
    case ABANDON_CART_DETAILS_SUCCESS:
      return {
        loading: false,
        abandonCart: action.payload,
      }
    case ABANDON_CART_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const emailPopUpReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_POP_UP_REQUEST:
      return {
        loading: true,
      }
    case EMAIL_POP_UP_SUCCESS:
      return {
        loading: false,
        emailPopUp: action.payload,
      }
    case EMAIL_POP_UP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const emailSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_SUBMIT_REQUEST:
      return {
        loading: true,
      }
    case EMAIL_SUBMIT_SUCCESS:
      return {
        loading: false,
        emailStatus: action.payload,
        success: true,
      }
    case EMAIL_SUBMIT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const subscriberedEmailListReducer = (
  state = { subscribers: [] },
  action
) => {
  switch (action.type) {
    case EMAIL_SUBMIT_LIST_REQUEST:
      return {
        loading: true,
      }
    case EMAIL_SUBMIT_LIST_SUCCESS:
      return {
        loading: false,
        subscribers: action.payload.subscribers,
        success: true,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case EMAIL_SUBMIT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const removeCartDiscountReducer = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNT_CODE_REMOVE_REQUEST:
      return {
        loading: true,
      }
    case DISCOUNT_CODE_REMOVE_SUCCESS:
      return {
        loading: false,
        discountCode: action.payload,
        success: true,
      }
    case DISCOUNT_CODE_REMOVE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
export const setCartPaymentMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CART_PAYMENT_REQUEST:
      return {
        loading: true,
      }
    case USER_CART_PAYMENT_SUCCESS:
      return {
        loading: false,
        discountCode: action.payload,
        success: true,
      }
    case USER_CART_PAYMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const setCartEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CART_EMAIL_SAVE_REQUEST:
      return {
        loading: true,
      }
    case USER_CART_EMAIL_SAVE_SUCCESS:
      return {
        loading: false,
        discountCode: action.payload,
        success: true,
      }
    case USER_CART_EMAIL_SAVE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
