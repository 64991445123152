import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listOrders } from '../actions/orderActions'
import Paginate from '../components/Paginate'
import OrderSearchBox from '../components/OrderSearchBox'
import { Route } from 'react-router-dom'

const OrderListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const cancelOrders = match.params.cancel || false
  const keyword = match.params.keyword || null

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders, page, pages } = orderList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if ((userInfo && userInfo.isAdmin) || cancelOrders) {
      dispatch(listOrders(pageNumber, cancelOrders, keyword))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, cancelOrders, match])

  return (
    <Container>
      <h1>Orders</h1>
      <Route render={({ history }) => <OrderSearchBox history={history} />} />
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Order</th>
                <th>Total</th>
                <th>Details</th>
                <th>Tracking</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>
                    <LinkContainer to={`/admin/order/${order._id}`}>
                      <small>{order.orderName && order.orderName}</small>
                    </LinkContainer>
                    <br />
                    <small>{order.shippingAddress.name}</small>
                    <br />
                    <small> {order.createdAt}</small>
                  </td>
                  <td> &#8377;{order.totalPrice}</td>
                  <td>
                    {order.isPaid ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                    {order.isConfirmed}
                    {order.isConfirmed && (
                      <>
                        {' '}
                        <i
                          className='fab fa-diaspora'
                          style={{ color: 'blue' }}
                        ></i>
                      </>
                    )}
                    {order.deliveredAt ? (
                      <i
                        className='fas fa-truck'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-bell' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>
                    {order.tracking && (
                      <>
                        <small>{order.tracking.utmSource}</small>
                        <br />
                        <small>{order.tracking.utmMedium}</small>
                        <br />
                        <small>{order.tracking.utmCampaign}</small>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={pages}
            page={page}
            isAdmin={true}
            keyword={keyword}
            orders={true}
          ></Paginate>
        </>
      )}
    </Container>
  )
}
// const getISTTime = (date) => {
//   let d = new Date(date)
//   let newData = d.getTime() + 5.5 * 60
//   let ist = new Date(newData)
//   return (
//     ist.getDate() +
//     '/' +
//     ist.getMonth() +
//     '/' +
//     ist.getFullYear() +
//     ' ' +
//     ist.getHours() +
//     ':' +
//     ist.getMinutes()
//   )
// }
export default OrderListScreen
