import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Form, Button, Col, Row, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'

import CheckoutSteps from '../components/CheckoutSteps'
import OrderSummary from '../components/OrderSummary'
import { FaTruckMoving } from 'react-icons/fa'
import {
  listProductsDetails,
  createProductReview,
} from '../actions/productActions'
import Message from '../components/Message'
import Rating from '../components/Rating'
const ReviewScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product, success, readingCount } = productDetails
  var reviewsLenght = 0
  var reviewsList = []
  if (success) {
    product.description =
      product.description && product.description.replace(/<[^>]*>?/gm, '')
    reviewsLenght = product.reviews.length

    if (product.reviews.length > 0) {
      for (var i = 0; i < 100; i++) {
        if (product.reviews[i]) {
          reviewsList.push(product.reviews[i])
        }
      }
    }
    // product.reviews = reviewsList
  }
  useEffect(() => {
    if (product && product.isbn != match.params.id) {
      window.scrollTo(0, 0)
      dispatch(listProductsDetails(match.params.id, match.params.slug))
    }
  }, [history])

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h5>Reviews</h5>
          {reviewsList.length == 0 && <Message>No Reviews</Message>}

          <ListGroup.Item key='reviewsss'>
            <Container>
              <Row>
                {reviewsList.map((review, index) => (
                  <Col key={review._id} xs={12} md={4}>
                    <span className='review-tile'>{review.name}</span> rated{' '}
                    {review.rating} stars <br />
                    <Rating value={review.rating}></Rating>
                    <Row key={review._id}>
                      <Col xs={12} md={6}>
                        {review.image && (
                          <img
                            src={review.image.replace(/\\/g, '/')}
                            className='reviewImage'
                            key={review._id}
                          />
                        )}
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className='right'
                        style={{ padding: '6px' }}
                      >
                        <p key={review._id}>{review.comment}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Container>
          </ListGroup.Item>
        </Col>
      </Row>
    </Container>
  )
}

export default ReviewScreen
