import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  ListGroupItem,
  Form,
  FormGroup,
  Alert,
  Modal,
  Table,
} from 'react-bootstrap'
import Rating from '../components/Rating'
import axios from 'axios'
import {
  listProductsDetails,
  createProductReview,
  getProductAttributes,
} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_PAYMENTTYPE_FREE,
  PRODUCT_PAYMENTTYPE_SUBSCRIPTION,
} from '../constants/productConstants'
import Meta from '../components/Meta'
import {
  addToCart,
  recentlyViewed,
  saveEmailToCart,
} from '../actions/cartActions.js'
import { Container } from 'react-bootstrap'
import { Route } from 'react-router-dom'
import Product from '../components/Product'
import { listProductsByAuthor } from '../actions/categoryActions'
import BoxProgressBar from '../components/BoxProgressBar'
import {
  FaFlagCheckered,
  FaTruck,
  FaCompressArrowsAlt,
  FaCheck,
  FaGooglePlay,
  FaHeart,
  FaBarcode,
  FaRegNewspaper,
  FaBuilding,
  FaPenFancy,
  FaCalendarAlt,
  FaBookOpen,
  FaLanguage,
  FaCoins,
  FaCartPlus,
  FaRegClock,
} from 'react-icons/fa'

import {
  addToIntrested,
  addToWIshlist,
  getWishlist,
  addToAlreadRead,
  getIntrestsByProduct,
  getAlreadyReadsByProduct,
} from '../actions/wishlistActions'
import ReactHtmlParser from 'react-html-parser'
import ProductMeta from '../components/ProductMeta'
import { BoxDescription } from '../components/BoxDescription'
import Cookies from 'universal-cookie'
import SocialShare from '../components/SocialShare'
import BookStats from '../components/BookStats'
import { timeConvert, timeConvertSummary } from '../helpers/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Timer from '../components/Timer'

var dayss = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const cookies = new Cookies()
const userAgent = window.navigator.userAgent.toLowerCase()

const ProductScreen = ({ history, match }) => {
  const url = `https://www.99bookscart.com/book/${match.params.id}/${match.params.slug}`
  const dispatch = useDispatch()
  const [qty, setQty] = useState(1)
  const [addToCartMessage, setAddToCartMessage] = useState(false)
  const [vprice, setVprice] = useState(0)
  const [vId, SetVid] = useState(0)

  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [slug, setSlug] = useState('')
  const [image, setImage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const [uploading, setUploading] = useState(false)
  const [isReadMore, setIsReadMore] = useState(true)
  const [intrestCheck, setIntrestCheck] = useState(false)
  const [bookcartBoxCount, SetBookcartBoxCount] = useState(0)
  const [alreadyReadCheck, setAlreadyReadCheck] = useState(false)
  const [inBox, SetInBox] = useState(false)
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState(false)
  const [phoneMessage, setPhoneMessage] = useState('')
  const [arShow, setArShow] = useState(false)
  const [load, setLoad] = useState(true)

  const setCartEmail = useSelector((state) => state.setCartEmail)
  const { success: successEmail } = setCartEmail

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const { success: successReview, error: errorReview } = productReviewCreate

  const cart = useSelector((state) => state.cart)
  const { cartItems, cartType, recentView: recentProducts, cartSuccess } = cart

  const productDetails = useSelector((state) => state.productDetails)
  const {
    loading,
    error,
    product,
    success,
    bookAttribute: bookAttributes,
    readingCount,
  } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const wishlist = useSelector((state) => state.wishlistAdd)
  const { success: wishlistSucces } = wishlist

  const wishlists = useSelector((state) => state.wishlistGet)
  const { wishlists: userWishList, success: userWishlistSucces } = wishlists

  const intrest = useSelector((state) => state.intrestAdd)
  const { success: intrestSucces } = intrest

  const alreadyRead = useSelector((state) => state.alreadyReadAdd)
  const { success: alreadyReadSucces } = alreadyRead

  const intrests = useSelector((state) => state.getIntrestsByProduct)
  const { success: intrestsSucces, intrestsCount } = intrests

  const alreadyReads = useSelector((state) => state.getAlreadyReadsByProduct)
  const { success: alreadyReadsSucces, alreadyreadsCount } = alreadyReads

  const getProductAttributesRes = useSelector(
    (state) => state.getProductAttributes
  )
  var wordCount = 0
  const {
    success: arSucces,
    error: arError,
    product: arProduct,
    bookAttribute,
    loading: arLoading,
  } = getProductAttributesRes

  let inWishlist = false
  // if (userWishlistSucces) {
  //   userWishList.map((item) => {
  //     if (item.product == product._id) {
  //       inWishlist = true
  //     }
  //   })
  // }
  var reviewsLenght = 0
  var reviewsList = []
  var date = new Date()

  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  useEffect(() => {
    if (product && product.isbn != match.params.id) {
      setDisable(false)
      window.scrollTo(0, 0)
      dispatch(listProductsDetails(match.params.id, match.params.slug))
    } else {
      setLoad(false)
    }

    if (success && product) {
      setSlug(convertToSlug(product.name))
      dispatch(recentlyViewed(product._id, 1))
      dispatch(getIntrestsByProduct(product._id))
      dispatch(getAlreadyReadsByProduct(product._id))
      // dispatch(listProductsByAuthor(product.author, 1, true))
    }
    if (cartItems) {
      SetInBox(false)
      if (cartItems.length > 0) {
        var count = 0
        cartItems.forEach((item, index) => {
          if (item.isBookscartBox) {
            if (
              (item.isbn == product.isbn || item.isbn == match.params.id) &&
              !item.variantSelected
            ) {
              SetInBox(true)
            }
            count++
          }
        })
        SetBookcartBoxCount(count)
      }
    }
    if (successReview) {
      setRating(0)
      setComment('')
      setImage('')
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
    if (userInfo) {
      dispatch(getWishlist())
    }
    if (intrestSucces) {
      setIntrestCheck(true)
    }
    if (alreadyReadSucces) {
      setAlreadyReadCheck(true)
    }
    if (addToCartMessage & product) {
      if (product.isbn == 'BookscartBox1') {
        if (userInfo) {
          history.push('/checkout/select-address')
        } else {
          history.push('/checkout/basic-information')
        }
      }
    }
    if (successEmail) {
      setShow(false)
    }
  }, [
    dispatch,
    match,
    successReview,
    success,
    product,
    intrestSucces,
    alreadyReadSucces,
    cartItems,
    bookcartBoxCount,
    cartSuccess,
    addToCartMessage,
    successEmail,
    arSucces,
    wordCount,
  ])
  if (success && reviewsList.length == 0) {
    product.description =
      product.description && product.description.replace(/<[^>]*>?/gm, '')
    reviewsLenght = product.reviews.length

    if (product.reviews.length > 0) {
      const list = product.reviews.sort(() => Math.random() - 0.5)
      for (var i = 0; i < 9; i++) {
        if (list[i]) {
          reviewsList.push(list[i])
        }
      }
    }
  }
  if (bookAttributes) {
    if (bookAttributes.plotSummary) {
      wordCount = bookAttributes.plotSummary.split(' ').length
    }
    if (bookAttributes.insights) {
      wordCount = wordCount + bookAttributes.plotSummary.split(' ').length
    }
  }

  const submitHandler2 = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(email)) {
      dispatch(saveEmailToCart(email))
    } else {
      setPhoneMessage('Enter Valid Phone Number')
    }
  }
  const handleClose = () => {
    setShow(false)
  }
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data)
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  const BuyNowHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      variantId = vId
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))
    history.push('/cart')
  }
  const addToCartHandler = () => {
    let variantId = 0
    if (vprice.length > 0) {
      var e = document.getElementById('boxTypeSelect')
      variantId = vId
    } else if (product.isBox) {
      variantId = product.boxType[0]._id
    }
    dispatch(addToCart(product._id, 1, variantId))

    if (product.isbn == 'BookscartBox1') {
      if (userInfo) {
        history.push('/checkout/select-address')
      } else {
        history.push('/checkout/basic-information')
      }
    } else {
      setAddToCartMessage(true)
      if (!userInfo && !cookies.get('_cfx_phone_pop')) {
        setShow(true)
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(product._id, {
        rating,
        comment,
        image,
      })
    )
  }

  const updateVariantPrice = (ee) => {
    var e = document.getElementById('boxTypeSelect')
    // product.salePrice = e.options[e.selectedIndex].getAttribute('data-name')
    // setVprice(e.options[e.selectedIndex].value)
    product.salePrice = ee.target.value
    SetVid(ee.target.id)
    setVprice(ee.target.value)
  }
  const addToUserWishlist = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToWIshlist(product))
    } else {
      history.push('/register')
    }
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const readNow = () => {
    if (userInfo) {
      history.push(`/read/${product.isbn}/${slug}`)
    } else {
      history.push('/register')
    }
  }

  const subscribeNow = () => {
    if (userInfo) {
      history.push(`/profile/subscription`)
    } else {
      history.push('/register')
    }
  }

  const addToIntrestUser = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToIntrested(product._id))
    } else {
      history.push('/register')
    }
  }
  const addToAlreadReadUser = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(addToAlreadRead(product._id))
    } else {
      history.push('/register')
    }
  }
  const getPlotSummary = (e) => {
    e.preventDefault()
    if (userInfo) {
      dispatch(getProductAttributes(product._id))
      handlearShow()
    } else {
      history.push('/register')
    }
  }
  const handlearShow = () => setArShow(true)
  const handlearClose = () => setArShow(false)
  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <ProductMeta product={product}></ProductMeta>

          <>
            <Container className='story-head-section'>
              <Row>
                {userInfo && userInfo.isAdmin && (
                  <Link to={`/admin/product/${product._id}/edit`}>
                    <Button type='button' className='btn btn-block'>
                      Edit
                    </Button>
                  </Link>
                )}
              </Row>
              <Row>
                {/* {product.isBox && <Timer></Timer>} */}
                <Col xs={4}>
                  <Image
                    src={product.image}
                    layout='responsive'
                    height='230'
                    width='150'
                    className='storypagecover'
                    alt={product.name + ' book'}
                  />
                </Col>
                <Col xs={8}>
                  <div style={{ paddingLeft: '8px' }}>
                    <h1 className='title-main'>
                      {product.name && product.name.toLowerCase()}
                    </h1>
                    {product.author ? (
                      <div className='book-author'>
                        by{' '}
                        <span
                          style={{ textTransform: 'capitalize !important' }}
                        >
                          {product.author.toLowerCase()}
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {product.numReviews > 0 ? (
                      <>
                        <Rating value={product.rating} />
                        {/* {product.reviews.length > 0 && (
                        <small>{product.reviews.length} Reviews</small>
                      )} */}
                      </>
                    ) : (
                      <div className='text-center-rating'>
                        {product.grating > 0 && (
                          <>
                            <Rating
                              value={product.grating}
                              style={{ textAlign: 'right' }}
                            />
                            {/* {product.gnumReviews > 0 && (
                            <small>{product.gnumReviews} Reviews</small>
                          )} */}
                          </>
                        )}
                      </div>
                    )}

                    <div className='text-center-product'>
                      {product.paymentType == PRODUCT_PAYMENTTYPE_FREE ? (
                        <div className='product-page-price themecolor'>
                          FREE
                        </div>
                      ) : product.paymentType ==
                        PRODUCT_PAYMENTTYPE_SUBSCRIPTION ? (
                        <div>Get 1000's of books for just ₹149/month</div>
                      ) : (
                        <div style={{ textAlign: 'left' }}>
                          <small className='red-text'>
                            -
                            {Math.round(
                              100 - (product.salePrice / product.price) * 100
                            )}
                            %{' '}
                          </small>
                          <span className='product-page-price themecolor'>
                            &#8377;{product.salePrice}
                          </span>{' '}
                          &nbsp;
                          <strike>&#8377;{product.price}</strike>
                          <br />
                        </div>
                      )}
                    </div>
                    <div>
                      {!product.isEbook &&
                        !product.isBox &&
                        (product.isBookscartBox ? (
                          <>
                            <small className='green-text'>
                              Available for (9 Books for &#8377;999)
                            </small>
                          </>
                        ) : (
                          <>
                            <small
                              className='grey-text'
                              style={{ fontSize: '12px' }}
                            >
                              Not available for (9 Books for &#8377;999)
                            </small>
                          </>
                        ))}
                    </div>
                    <div
                      className='desktop-view'
                      style={{ width: '50%', marginBottom: '5px' }}
                    >
                      <BookStats
                        product={product}
                        coins={product.salePrice / 50}
                        binding={product.binding}
                      ></BookStats>
                    </div>

                    <div>
                      {!product.isEbook && (
                        <ListGroup.Item key='cartbutton'>
                          <>
                            <div>
                              {addToCartMessage && (
                                <>
                                  <div className='very-small-line-height line-height'>
                                    <span className='very-small'>
                                      Added to Cart!
                                    </span>
                                  </div>
                                  <br />
                                </>
                              )}
                            </div>
                            {/* {!inBox ? ( */}
                            <Row>
                              <Col xs={12} md={12} className=''>
                                <Button
                                  className='btn-primary addtocart'
                                  onClick={addToCartHandler}
                                  type='button'
                                  disabled={
                                    (product.countInStock == 0 || disable) &&
                                    !product.variantSelected
                                  }
                                >
                                  {product.countInStock == 0 ? (
                                    'No Stock'
                                  ) : (
                                    <>
                                      <FaCartPlus></FaCartPlus> Add to Cart
                                    </>
                                  )}
                                </Button>
                                &nbsp; &nbsp;
                                <Link to='/cart'>
                                  <Button className='btn-primary' type='button'>
                                    Cart
                                  </Button>
                                </Link>
                                {/* {product.isAttribute && (
                                <>
                                  &nbsp; &nbsp;
                                  <Button
                                    className='btn-primary'
                                    type='button'
                                    onClick={getPlotSummary}
                                  >
                                    Summary
                                  </Button>
                                </>
                              )} */}
                              </Col>
                            </Row>
                            {/* ) : (
                          <p className='mobile-center'>
                            Added in Box. <Link to='/cart'>View Cart</Link>
                          </p>
                        )} */}
                          </>
                        </ListGroup.Item>
                      )}
                    </div>
                    <div className='add-to-wishlist'>
                      <Link
                        variant='light'
                        to='/'
                        disabled={inWishlist}
                        onClick={(e) => addToUserWishlist(e)}
                      >
                        <small>
                          <FaHeart></FaHeart>{' '}
                          {wishlistSucces || inWishlist
                            ? 'In Library'
                            : 'Add to Library'}
                        </small>
                      </Link>
                    </div>
                    <div>
                      {product.type == 'New' && (
                        <div className=' new-books'>New Book</div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='bookscartbox-section'>
                <div className='text-center'>
                  <Link
                    onClick={(e) => addToIntrestUser(e)}
                    to=''
                    className='user-intrest'
                  >
                    <FaCompressArrowsAlt> </FaCompressArrowsAlt> Intrested
                    <span className='text-muted'>
                      {intrestsSucces && '(' + intrestsCount.count + ')'}
                    </span>
                  </Link>{' '}
                  <Link
                    to=''
                    onClick={(e) => addToAlreadReadUser(e)}
                    className='user-intrest'
                  >
                    <FaFlagCheckered></FaFlagCheckered> Already Read
                    <span className='text-muted'>
                      {alreadyReadsSucces &&
                        '(' + alreadyreadsCount.count + ')'}
                    </span>
                  </Link>
                </div>

                {product.isBox && (
                  <>
                    <ListGroup.Item key='textbox'>
                      <strong>
                        🚀 How to know more than your friends & family? The
                        knowledge you gain from reading gives you lots to share
                        & talk about with others.📚
                      </strong>
                      <br />
                      <div className='freeshipping'>
                        {' '}
                        Buy any 2 Boxes and Get FREE Shipping
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item key='selector'>
                      <Row>
                        <Form.Label className='text-center'>
                          Select Your Favorite Genre
                        </Form.Label>
                        <Container>
                          <Row className='margin-left'>
                            {product.boxType.map((x) => (
                              <Col xs={6} md={4} key={x._id}>
                                {/* <Form.Check
                                  key={x._id}
                                  name='variant'
                                  type='radio'
                                  id={x._id}
                                  label={x.name}
                                  data-id={x._id}
                                  data-name={x.price}
                                  value={x.price}
                                  style={ulStyle}
                                  onChange={(e) => updateVariantPrice(e)}
                                /> */}

                                <Button
                                  className='ldp-variant'
                                  key={x._id}
                                  id={x._id}
                                  label={x.name}
                                  data-id={x._id}
                                  name={x.name}
                                  value={x.price}
                                  onClick={(e) => updateVariantPrice(e)}
                                >
                                  {x.name}
                                </Button>
                              </Col>
                            ))}
                          </Row>
                          {/* <Row>
                              <div style={freeShippingText}>
                                Buy any 2 Box and Get FREE Shipping!
                              </div>
                            </Row> */}
                        </Container>
                      </Row>
                    </ListGroup.Item>
                  </>
                )}
              </Row>
            </Container>
            <Row style={{ padding: '8px' }} className='summary-reading'>
              <Col>
                <Container style={{ padding: '9px' }}>
                  <div className='mobile-view'>
                    <BookStats
                      product={product}
                      coins={product.salePrice / 50}
                      binding={product.binding}
                    ></BookStats>
                  </div>

                  {product.description &&
                    (product.isBox ? (
                      <ListGroup.Item key='description-bookscartBox'>
                        <BoxDescription
                          description={product.description}
                        ></BoxDescription>
                      </ListGroup.Item>
                    ) : (
                      <>
                        <ListGroup.Item key='description'>
                          {product.isAttribute ? (
                            <>
                              <div className='product-description'>
                                <div>
                                  {wordCount !== 0 && (
                                    <>
                                      <br />
                                      <div>
                                        <FaRegClock></FaRegClock>{' '}
                                        {timeConvertSummary(wordCount)}
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    {bookAttributes.insights && (
                                      <>
                                        <h2 className='summary-titles'>
                                          What you will learn from reading{' '}
                                          {product.name} by {product.author}
                                        </h2>
                                        <p
                                          style={{
                                            whiteSpace: 'pre-wrap',
                                            marginTop: '-40px',
                                          }}
                                        >
                                          {bookAttributes.insights}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  {/* {bookAttributes.emotions && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Emotions:
                                      </strong>{' '}
                                      <small
                                        style={{
                                          textTransform: 'capitalize',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {bookAttributes.emotions.join()}
                                      </small>
                                      <br />
                                    </>
                                  )}
                                  {bookAttributes.mainNarater && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Main Narrator:
                                      </strong>{' '}
                                      <small
                                        style={{
                                          textTransform: 'capitalize',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {bookAttributes.mainNarater +
                                          '(' +
                                          bookAttributes.mainCharacterGender +
                                          ')'}{' '}
                                      </small>
                                      <br />
                                    </>
                                  )}
                                  {bookAttributes.ageGroupRange && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Suitable Age:
                                      </strong>{' '}
                                      <small
                                        style={{
                                          textTransform: 'capitalize',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {bookAttributes.ageGroupRange}
                                      </small>
                                      <br />
                                    </>
                                  )}

                                  {bookAttributes.places && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Places:
                                      </strong>{' '}
                                      <small
                                        style={{
                                          textTransform: 'capitalize',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {bookAttributes.places.join()}
                                      </small>
                                      <br />
                                    </>
                                  )}
                                  {bookAttributes.keywords && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Keywords:
                                      </strong>{' '}
                                      <small
                                        style={{
                                          textTransform: 'capitalize',
                                          wordWrap: 'break-word',
                                          width: '100%',
                                        }}
                                      >
                                        {bookAttributes.keywords.join()}
                                      </small>
                                    </>
                                  )} */}
                                  <br />
                                </div>

                                <div>
                                  {bookAttributes.plotSummary && (
                                    <>
                                      <div className='summary-titles'>
                                        99bookscart Review
                                      </div>
                                      <p
                                        style={{
                                          whiteSpace: 'pre-wrap',
                                          marginTop: '-40px',
                                        }}
                                      >
                                        {bookAttributes.plotSummary}
                                      </p>
                                    </>
                                  )}
                                </div>
                                {/* <div>
                                  {bookAttributes.mainCharacters &&
                                    bookAttributes.mainCharacters.length >
                                      0 && (
                                      <>
                                        <div>
                                          <div className='summary-titles'>
                                            Key Characters
                                          </div>

                                          {bookAttributes.mainCharacters.map(
                                            (ch) => (
                                              <div
                                                key={ch.name}
                                                style={{
                                                  marginBottom: '10px',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  {ch.name}
                                                </div>{' '}
                                                {ch.profession && ch.profession}{' '}
                                                {ch.sex && ch.sex}{' '}
                                                {ch.age && '(' + ch.age + ')'}
                                                <br />
                                                {ch.traits && (
                                                  <>
                                                    <span
                                                      style={{
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      Traits:
                                                    </span>{' '}
                                                    {ch.traits.join()}
                                                    <br />
                                                  </>
                                                )}
                                                {ch.emotions && (
                                                  <>
                                                    <span
                                                      style={{
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      Emotions:{' '}
                                                    </span>
                                                    {ch.emotions.join()}
                                                    <br />
                                                  </>
                                                )}
                                                {ch.description}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </>
                                    )}
                                </div> */}

                                {/* <div>
                            {bookAttributes && bookAttributes.quotes && (
                              <>
                                <div className='summary-titles'>Quotes:</div>

                                {arProduct.bookAttributes.quotes.map((ch) => (
                                  <div key={ch} style={{ marginBottom: '8px' }}>
                                    {ch}
                                    <br />
                                    {ch.description}
                                  </div>
                                ))}
                              </>
                            )}
                          </div> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ fontWeight: 'bold' }}>
                                Detailed Summary is not available!
                              </div>
                              {isReadMore
                                ? ReactHtmlParser(
                                    product.description.slice(0, 450)
                                  )
                                : ReactHtmlParser(product.description)}
                              <div
                                onClick={toggleReadMore}
                                className='read-or-hide'
                              >
                                {isReadMore ? '...read more' : ' show less'}
                              </div>
                            </>
                          )}
                        </ListGroup.Item>
                      </>
                    ))}

                  <div className='story-tags'>
                    <span className='storietaglabel'>Related Books: </span>
                    {product.categories.map((category) => (
                      <Link
                        to={`/category/${category.slug}`}
                        key={`${category.slug}`}
                        className='topic'
                      >
                        <span>{category.name} books</span>
                      </Link>
                    ))}
                  </div>
                  {!product.isBox && (
                    <ListGroup.Item key='details'>
                      <div className='product-details'>
                        <div>
                          <strong>
                            <FaBarcode></FaBarcode> ISBN-13:{' '}
                          </strong>
                          {product.isbn}
                        </div>
                        {product.pages > 1 && (
                          <div>
                            <strong>
                              <FaRegNewspaper></FaRegNewspaper> Pages:{' '}
                            </strong>
                            {product.pages}{' '}
                            <strong>
                              <FaRegClock></FaRegClock>{' '}
                              {timeConvert((product.pages * 500) / 100)}
                            </strong>
                          </div>
                        )}

                        {product.publisher && (
                          <div>
                            <strong>
                              <FaBuilding> </FaBuilding> Publisher:{' '}
                            </strong>
                            {product.publisher}
                          </div>
                        )}
                        {product.author && (
                          <div>
                            <strong>
                              <FaPenFancy></FaPenFancy> Author:{' '}
                            </strong>
                            {product.author}
                          </div>
                        )}
                        {product.edition && (
                          <div>
                            <strong>
                              <FaCalendarAlt></FaCalendarAlt> Edition:{' '}
                            </strong>
                            {product.edition}
                          </div>
                        )}
                        {product.binding && (
                          <div>
                            <strong>
                              {' '}
                              <FaBookOpen></FaBookOpen> Binding:{' '}
                            </strong>
                            {product.binding}
                          </div>
                        )}
                        <div>
                          <strong>
                            <FaLanguage></FaLanguage> Langauge:{' '}
                          </strong>
                          {product.langauge ? product.langauge : 'English'}
                        </div>
                      </div>
                    </ListGroup.Item>
                  )}
                  <SocialShare url={url} title={product.name}></SocialShare>
                </Container>
              </Col>
            </Row>
            <Container>
              <Row>
                <Col md={12}>
                  {product.reviews.length > 0 && (
                    <>
                      <h5>Reviews</h5>
                      <ListGroup.Item key='reviewsss'>
                        <Container>
                          <Row>
                            {reviewsList.map((review, index) => (
                              <Col key={review._id} xs={12} md={4}>
                                <span className='review-tile'>
                                  {review.name}
                                </span>{' '}
                                rated {review.rating} stars
                                <br />
                                <Rating value={review.rating}></Rating>
                                <Row key={review._id}>
                                  <Col xs={12} md={4}>
                                    {review.image && (
                                      <img
                                        src={review.image.replace(/\\/g, '/')}
                                        key={review._id}
                                        className='reviewImage'
                                      />
                                    )}
                                  </Col>
                                  <Col xs={12} md={8} className='right'>
                                    <p key={review._id}>{review.comment}</p>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </Row>
                          <Row className='homepage-mobile-bar'>
                            <Link
                              to={`/review/${match.params.id}/${match.params.slug}`}
                            >
                              <h1 className='text-center homepage-mobile-bar-col'>
                                Read All Reviews
                              </h1>
                            </Link>
                          </Row>
                        </Container>
                      </ListGroup.Item>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {!product.isBox && (
                    <>
                      <br />
                      <h5>GoodReads Reviews</h5>
                      <div id='goodreads-widget'>
                        <iframe
                          frameBorder='0'
                          height='400'
                          id='the_iframe'
                          src='https://www.goodreads.com/api/reviews_widget_iframe?did=DEVELOPER_ID&amp;format=html&amp;isbn=9780552566032&amp;links=660&amp;min_rating=&amp;review_back=fff&amp;stars=000&amp;text=000'
                          width='100%'
                        ></iframe>
                      </div>
                    </>
                  )}
                </Col>
                <Col md={6}>
                  <ListGroup.Item key='customer-review'>
                    <h6>Write a Review</h6>
                    {errorReview && (
                      <Message variant='danger'>{errorReview}</Message>
                    )}
                    {userInfo ? (
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='rating'>
                          <Form.Label>Rating</Form.Label>
                          <Form.Control
                            as='select'
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                          >
                            <option value=''>Select...</option>
                            <option value='1'>1 - Poor</option>
                            <option value='2'>2 - Fair</option>
                            <option value='3'>3 - Good</option>
                            <option value='4'>4 - Very Good</option>
                            <option value='5'>5 - Excellent</option>
                          </Form.Control>
                          <Form.Group controlId='image'>
                            <Form.Label>Image</Form.Label>
                            <Form.File
                              id='image-file'
                              label='Choose File'
                              custom
                              onChange={uploadHandler}
                            ></Form.File>
                            {uploading && <Loader></Loader>}
                          </Form.Group>
                          <FormGroup controlId='comment'>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as='textarea'
                              value={comment}
                              row='3'
                              onChange={(e) => setComment(e.target.value)}
                            ></Form.Control>
                          </FormGroup>
                          <Button type='submit' variant='primary'>
                            Comment
                          </Button>
                        </Form.Group>
                      </Form>
                    ) : (
                      <Message>
                        Please <Link to='/login'>Sign In</Link>
                      </Message>
                    )}
                  </ListGroup.Item>
                  <br />
                </Col>
              </Row>
              {/* <Row>
              <Col xs={12}>
                {authorLoading ? (
                  <Loader></Loader>
                ) : authorError ? (
                  <Message>
                    <h2>error</h2>
                  </Message>
                ) : (
                  <>
                    <Row className='homepage-mobile-bar'>
                      <Col xs={7}>
                        <div className='homepage-mobile-bar-col'>
                          Books from Author {product.author && product.author}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <Link
                          to={`/author/${product.author}`}
                          className='float-right mt-2 homepage-mobile-bar-col'
                        >
                          See All
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      {authorProducts.map((product) => (
                        <Col
                          key={product._id}
                          sm={4}
                          md={4}
                          lg={2}
                          xs={4}
                          className='product-grid-size'
                        >
                          <Route
                            render={({ history }) => (
                              <Product history={history} product={product} />
                            )}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </Col>
            </Row> */}
              <Container>
                <Row>
                  <Col>
                    <Row className='homepage-mobile-bar'>
                      <Col xs={7}>
                        <div className='homepage-mobile-bar-col'>
                          Recently Viewed
                        </div>
                        {userAgent.includes('wv') ? 'wb' : 'b'}
                      </Col>
                    </Row>
                    <Row>
                      {recentProducts.length > 1 &&
                        recentProducts.map((product) => (
                          <Col
                            key={product._id}
                            sm={4}
                            md={4}
                            lg={2}
                            xs={4}
                            className='product-grid-size'
                          >
                            <Route
                              render={({ history }) => (
                                <Product history={history} product={product} />
                              )}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Container>
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <Form onSubmit={submitHandler2} className='cart-form'>
                  <div style={{ padding: '20px' }}>
                    <Form.Group>
                      <h5>Save your Cart</h5>
                      <small>Reading makes you Happy!</small>
                      <div className='red-text'>
                        {phoneMessage && phoneMessage}
                      </div>
                      <Form.Control
                        type='Number'
                        placeholder='Enter Phone Number'
                        required
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Button
                          variant='secondary'
                          onClick={handleClose}
                          style={{ float: 'left' }}
                        >
                          <small>Don't save</small>
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type='submit'
                          variant='primary'
                          className='float-right'
                        >
                          Save Cart
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            <Modal
              show={arShow}
              onHide={handlearClose}
              dialogClassName='ar-modal'
            >
              {!arSucces ? (
                <Loader></Loader>
              ) : (
                <Modal.Body className='summary-reading'>
                  <div style={{ padding: '10px' }}>
                    <div style={{ width: '100%' }}>
                      <Button
                        onClick={handlearClose}
                        style={{ float: 'right' }}
                      >
                        X
                      </Button>{' '}
                    </div>
                    <div>
                      {arProduct.name} by{' '}
                      {arProduct.author && arProduct.author.name}{' '}
                      {bookAttribute.goodreadRating && (
                        <>
                          <br />
                          <Rating value={bookAttribute.goodreadRating} />{' '}
                          {bookAttribute.goodreadReviewsCount &&
                            '(' + bookAttribute.goodreadReviewsCount + ')'}
                        </>
                      )}
                    </div>
                    <div>
                      <br />
                      {bookAttribute.emotions && (
                        <>
                          <strong
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Emotions:
                          </strong>{' '}
                          <small
                            style={{
                              textTransform: 'capitalize',
                              wordWrap: 'break-word',
                            }}
                          >
                            {bookAttribute.emotions.join()}
                          </small>
                          <br />
                        </>
                      )}
                      {bookAttribute.mainNarater && (
                        <>
                          <strong
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Main Narrator:
                          </strong>{' '}
                          <small
                            style={{
                              textTransform: 'capitalize',
                              wordWrap: 'break-word',
                            }}
                          >
                            {bookAttribute.mainNarater +
                              '(' +
                              bookAttribute.mainCharacterGender +
                              ')'}{' '}
                          </small>
                          <br />
                        </>
                      )}
                      {bookAttribute.ageGroupRange && (
                        <>
                          <strong
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Suitable Age:
                          </strong>{' '}
                          <small
                            style={{
                              textTransform: 'capitalize',
                              wordWrap: 'break-word',
                            }}
                          >
                            {bookAttribute.ageGroupRange}
                          </small>
                          <br />
                        </>
                      )}

                      {bookAttribute.places && (
                        <>
                          <strong
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Places:
                          </strong>{' '}
                          <small
                            style={{
                              textTransform: 'capitalize',
                              wordWrap: 'break-word',
                            }}
                          >
                            {bookAttribute.places.join()}
                          </small>
                          <br />
                        </>
                      )}
                      {bookAttribute.keywords && (
                        <>
                          <strong
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Keywords:
                          </strong>{' '}
                          <small
                            style={{
                              textTransform: 'capitalize',
                              wordWrap: 'break-word',
                              width: '100%',
                            }}
                          >
                            {bookAttribute.keywords.join()}
                          </small>
                        </>
                      )}
                      <br />
                    </div>
                    <div>
                      {bookAttribute.mainCharacters &&
                        bookAttribute.mainCharacters.length > 0 && (
                          <>
                            <div>
                              <div className='summary-titles'>
                                Key Characters
                              </div>

                              {bookAttribute.mainCharacters.map((ch) => (
                                <div
                                  key={ch.name}
                                  style={{ marginBottom: '10px' }}
                                >
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {ch.name}
                                  </div>{' '}
                                  {ch.profession && ch.profession}{' '}
                                  {ch.sex && ch.sex}{' '}
                                  {ch.age && '(' + ch.age + ')'}
                                  <br />
                                  {ch.traits && (
                                    <>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Traits:
                                      </span>{' '}
                                      {ch.traits.join()}
                                      <br />
                                    </>
                                  )}
                                  {ch.emotions && (
                                    <>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Emotions:{' '}
                                      </span>
                                      {ch.emotions.join()}
                                      <br />
                                    </>
                                  )}
                                  {ch.description}
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                    </div>
                    <div>
                      {bookAttribute.plotSummary && (
                        <>
                          <div className='summary-titles'>
                            99bookscart Review
                          </div>
                          <p
                            style={{
                              whiteSpace: 'pre-wrap',
                              marginTop: '-40px',
                            }}
                          >
                            {bookAttribute.plotSummary}
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      {bookAttribute.insights && (
                        <>
                          <div className='summary-titles'>
                            What you will learn from reading this book?
                          </div>
                          <p
                            style={{
                              whiteSpace: 'pre-wrap',
                              marginTop: '-40px',
                            }}
                          >
                            {bookAttribute.insights}
                          </p>
                        </>
                      )}
                    </div>

                    <div>
                      {bookAttribute.dates && (
                        <>
                          Dates:
                          {bookAttribute.dates.join()}
                        </>
                      )}

                      {bookAttribute.quotes && (
                        <>
                          <div className='summary-titles'>Quotes:</div>

                          {arProduct.bookAttribute.quotes.map((ch) => (
                            <div key={ch} style={{ marginBottom: '8px' }}>
                              {ch}
                              <br />
                              {ch.description}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div>
                      {arProduct.author && (
                        <>
                          <tr>
                            <td colSpan={2}>
                              <div className='summary-titles'>About Author</div>
                              <p>{arProduct.author.about}</p>
                            </td>
                          </tr>
                        </>
                      )}
                    </div>
                    <div style={{ width: '100%' }}>
                      <Button
                        onClick={handlearClose}
                        style={{ float: 'right' }}
                      >
                        X
                      </Button>{' '}
                    </div>
                  </div>
                </Modal.Body>
              )}
            </Modal>
          </>
        </Container>
      )}
    </>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
function loop(index) {
  if (index < 0) {
    return
  }
}
export default ProductScreen
