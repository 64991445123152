import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserDesc } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import { getUserCoins } from '../actions/coinActions'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  FaBookOpen,
  FaBoxOpen,
  FaMoneyBillAlt,
  FaThList,
  FaTools,
} from 'react-icons/fa'
import Product from '../components/Product'
import {
  getIntrested,
  getAlreadyRead,
  removeIntrest,
  removeAlreadyRead,
} from '../actions/wishlistActions'
import { Link } from 'react-router-dom'
import { FaBookReader, FaStar, FaList, FaClock, FaPlus } from 'react-icons/fa'

const ProfileScreen = ({ location, history, getState }) => {
  const dispatch = useDispatch()
  const [gender, setGender] = useState('')
  const [description, setDescription] = useState('')
  const [show, setShow] = useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userIntrests = useSelector((state) => state.intrestGet)
  const {
    success: intrestsSucces,
    loading: intrestsLoading,
    intrests,
  } = userIntrests

  const userAlreadyReads = useSelector((state) => state.alreadyReadGet)
  const {
    success: alreadyReadsSucces,
    loading: alreadyReadsLoading,
    alreadyReads,
  } = userAlreadyReads

  const userIntrestRemove = useSelector((state) => state.intrestRemove)
  const { success: intrestsRemoveSucces } = userIntrestRemove

  const userAlreadyReadRemove = useSelector((state) => state.alreadyReadRemove)
  const { success: alreadyReadRemoveSucces } = userAlreadyReadRemove

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading,
    error,
    user: loggedInUser,
    success: userSuccess,
  } = userDetails

  const updateUserDescRed = useSelector((state) => state.updateUserDesc)
  const {
    loading: userUpdateLoading,
    user: userUpdateUser,
    success: userUpdateSuccess,
  } = updateUserDescRed
  const [key, setKey] = useState('home')

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(getUserDetails(userInfo._id))
      dispatch(getIntrested())
      dispatch(getAlreadyRead())
    }
    if (userUpdateSuccess) {
      setShow(false)
      dispatch(getUserDetails(userInfo._id))
    }
  }, [
    dispatch,
    history,
    userInfo,
    intrestsRemoveSucces,
    alreadyReadRemoveSucces,
    userSuccess,
    userUpdateSuccess,
  ])
  const removeFromIntrest = (id, e) => {
    e.preventDefault()
    dispatch(removeIntrest(id))
  }

  const removeFromAlreadyRead = (id, e) => {
    e.preventDefault()
    dispatch(removeAlreadyRead(id))
  }
  const addDescriptionHandler = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const handleClose = () => setShow(false)

  const submitProfileDescription = (e) => {
    e.preventDefault()
    dispatch(updateUserDesc({ description, gender }))
  }
  return (
    <>
      <>
        {!userSuccess ? (
          <Loader></Loader>
        ) : (
          <>
            <Row>
              <Col>
                <div className='text-center profile-backgrd'>
                  <amp-img
                    src={loggedInUser.profileImage}
                    alt={`${loggedInUser.name} 99bookscart profile image`}
                    height='250'
                    width='250'
                    style={{ borderRadius: '130px' }}
                  />
                  <br />
                  <div>{loggedInUser && loggedInUser.name}</div>

                  <div
                    style={{ width: '350px', color: '#fff' }}
                    className='center-block'
                  >
                    {/* <Row className='storystatsrow'>
                      <Col>
                        <Link
                          to={'/profile/mystories'}
                          style={{ color: '#fff' }}
                        >
                          <div className='storiestatslabel'>
                            <FaBookReader /> Stories
                          </div>
                          <div className='storiestatsvalue'>
                            {loggedInUser.stories}
                          </div>
                        </Link>
                      </Col>
                      <Col>
                        <Link to={'/profile/stories'} style={{ color: '#fff' }}>
                          <div className='storiestatslabel'>
                            <FaStar /> Vote
                          </div>
                          <div className='storiestatsvalue'>
                            {loggedInUser.voteCount}
                          </div>
                        </Link>
                      </Col>
                      <Col>
                        <Link
                          to={'/profile/followers'}
                          style={{ color: '#fff ' }}
                        >
                          <div className='storiestatslabel'>
                            <FaList /> Followers
                          </div>
                          <div className='storiestatsvalue'>
                            {loggedInUser.followings}
                          </div>
                        </Link>
                      </Col>
                    </Row> */}
                  </div>
                </div>
              </Col>
            </Row>

            <Container>
              {/* <Row>
                <Col xs={12} className='center-block'>
                  <br />
                  <Link
                    to={'/create/episode'}
                    className='newbuttons text-center wd200'
                  >
                    <FaPlus></FaPlus> Write Your Story
                  </Link>
                </Col>
              </Row> */}
              <Row>
                <Col md={12}>
                  <Card className='text-center'>
                    <Card.Body className='profile-card-footer'>
                      <Card.Text></Card.Text>
                      <Card.Link
                        href='/profile/orders'
                        className=' profile-button'
                      >
                        <FaBoxOpen></FaBoxOpen> Orders
                      </Card.Link>
                      {/* <Card.Link href='/#/profile/ebooks' className=' profile-button'>
                  <FaBookOpen> </FaBookOpen> Ebooks
                </Card.Link> */}
                      <Card.Link
                        href='/profile/coins'
                        className=' profile-button'
                      >
                        <FaMoneyBillAlt></FaMoneyBillAlt> Coins
                      </Card.Link>
                      <Card.Link
                        href='/profile/wishlist'
                        className=' profile-button'
                      >
                        <FaThList></FaThList> My Library
                      </Card.Link>
                      <Card.Link
                        href='/profile/setting'
                        className=' profile-button'
                      >
                        <FaTools></FaTools> Settings
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={3}>
                  {loggedInUser.aboutMe ? (
                    <>
                      <div>About Me</div>
                      <p>{loggedInUser.aboutMe}</p>
                    </>
                  ) : (
                    <Link
                      to={'/'}
                      className='newbuttons'
                      onClick={(e) => addDescriptionHandler(e)}
                    >
                      Add Description
                    </Link>
                  )}
                </Col>
                <Col xs={12} md={9}>
                  <div style={{ display: 'block', padding: 30 }}>
                    <span className='text-center'>
                      Manage your Virtual Library Here
                    </span>
                    <Tabs defaultActiveKey='first' className='profile-tabs'>
                      <Tab eventKey='first' title='Intrested'>
                        {intrestsLoading ? (
                          <Loader></Loader>
                        ) : (
                          <Row>
                            {intrestsSucces &&
                              intrests.length > 0 &&
                              intrests.map((intrest) => (
                                <Col
                                  key={intrest.product._id}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  xs={4}
                                >
                                  <>
                                    <Route
                                      render={({ history }) => (
                                        <Product
                                          history={history}
                                          product={intrest.product}
                                          addtocart={false}
                                        />
                                      )}
                                    />
                                    <Link
                                      className='text-center'
                                      onClick={(e) =>
                                        removeFromIntrest(
                                          intrest.product._id,
                                          e
                                        )
                                      }
                                      to=''
                                    >
                                      <i className='fas fa-trash'></i>
                                    </Link>
                                  </>
                                </Col>
                              ))}
                          </Row>
                        )}
                      </Tab>
                      <Tab eventKey='second' title='Already Read'>
                        {alreadyReadsLoading ? (
                          <Loader></Loader>
                        ) : (
                          <Row>
                            {alreadyReadsSucces &&
                              alreadyReads.map((alreadyRead) => (
                                <Col
                                  key={alreadyRead.product._id}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  xs={4}
                                >
                                  <>
                                    <Route
                                      render={({ history }) => (
                                        <Product
                                          history={history}
                                          product={alreadyRead.product}
                                          addtocart={false}
                                        />
                                      )}
                                    />
                                    {alreadyReadRemoveSucces ? (
                                      'Removed'
                                    ) : (
                                      <Link
                                        className='text-center'
                                        onClick={(e) =>
                                          removeFromAlreadyRead(
                                            alreadyRead.product._id,
                                            e
                                          )
                                        }
                                        to=''
                                      >
                                        <i className='fas fa-trash'></i>
                                      </Link>
                                    )}
                                  </>
                                </Col>
                              ))}
                          </Row>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Container>
            <Modal show={show}>
              <Form name='descupdate'>
                <Modal.Header closeButton>
                  <Modal.Title>Add about Yourself</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className='mb-3' controlId='profiledescription'>
                    <Form.Check
                      type='radio'
                      name='gender'
                      label={`Male`}
                      value='Male'
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <Form.Check
                      type='radio'
                      label={`Female`}
                      name='gender'
                      value='Female'
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <Form.Control
                      as='textarea'
                      rows={3}
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant='primary'
                    onClick={(e) => submitProfileDescription(e)}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </>
    </>
  )
}

export default ProfileScreen
