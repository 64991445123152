import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../helpers/helpers'
import Loader from '../../components/Loader'
import { FaBookReader } from 'react-icons/fa'
import Message from '../../components/Message'
import PaginateStory from '../../components/PaginateStory'
import { register } from '../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../components/Categories'
import Rating from '../../components/Rating'
import SocialLogin from '../../components/SocialLogin'

import { listStoryByCat } from '../../actions/storyActions'
import Story from '../../components/Story'

const StoriesScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const storybyCat = useSelector((state) => state.storybyCat)
  const {
    stories,
    loading: storyLoading,
    error: storyError,
    suceess: storySuccess,
    pages,
    page,
  } = storybyCat

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const startSub = () => {
    if (userLoggedIn) {
      history.push('/app/profile/billing')
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(listStoryByCat('Money', pageNumber))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  return (
    <>
      <Container>
        <Row className='story-hero-text'>
          <Col xs={12} sm={6}>
            <div>
              <Link to={'/'}>
                <img
                  src='../../images/logo.png'
                  className='siteLogo'
                  alt='99bookscart logo'
                />
              </Link>
              <h1 className='hero-text'>
                Key Ideas from 1000+ Books in your Pocket
              </h1>
              <p>
                More knowledge in less time
                <br />
                {/* Read 10 key Learnings from 1,000+ Non Fiction Books */}
                <br />7 days Free Trail!
              </p>

              <Button onClick={startSub}>Start your free trail</Button>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div>
              <img
                className='hero-image-subs'
                src='https://s3-books-images.s3.ap-south-1.amazonaws.com/4a587e6a-7491-42c7-8b85-9027b0d90bd5.png'
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <div className='lp-rating-sect'>
              <Rating value={4.9}></Rating>
              <span>Satish. C</span>
              <br />
              <p>Great Service thanx a billion@99bookscart</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className='lp-rating-sect'>
              <Rating value={5}></Rating>
              <span>Ryan</span>
              <br />
              <p>
                I was a bit skeptical at first, but after trying out this book
                summary subscription service, I am now a loyal customer.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className='lp-rating-sect'>
              <Rating value={5}></Rating>
              <span>Neha</span>
              <br />
              <p>I absolutely love this book summary subscription service!</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='hero-text2'>
              Get 10 Key Ideas from a Book in 15 Minutes
              <br />
              <Button onClick={startSub}>Start Reading</Button>
            </div>
          </Col>
        </Row>

        <Row className='story-bg-points'>
          <div className='text-center mb-8'>
            <h3>A small investment, an incredible growth opportunity</h3>
            <br />
          </div>
          <Col xs={12} sm={4}>
            <h3>READ</h3>
            <p>
              Gain knowledge and insights,Improve communication skills,Develop
              empathy,Expand creativity and innovation
            </p>
          </Col>
          <Col xs={12} sm={4}>
            <h3>LEAD</h3>
            <p>Decision-making, Goal setting, Time management, Adaptability</p>
          </Col>
          <Col xs={12} sm={4}>
            <h3>SUCCEED</h3>
            <p>
              Fulfilling and Satisfying Life,Financial Stability, Recognition,
              and Opportunities for Growth and Development.
            </p>
          </Col>
        </Row>
        <Row>
          <Categories></Categories>
        </Row>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <div className='lp-text-app'>
              <h1>Why Subscribe?</h1>
              <br />
              <p>
                Open the door to new worlds of wisdom, beauty, and inspiration
                with our book Ideas subscription service. Discover the
                transformative power of books in just 15-20 minutes or less,
                with our hand-picked selection of 10 key ideas from over 1000+
                non-fiction books.
              </p>

              <p>
                Immerse yourself in the voices of the great thinkers, poets, and
                visionaries of our time. Experience the rush of excitement and
                inspiration that comes from discovering a new idea, a new
                perspective, a new way of seeing the world.
              </p>

              <p>
                99bookscart is a gateway to a life of meaning, purpose, and
                fulfillment. It's a bridge between the wisdom of the past and
                the vision of the future. It's a testament to the power of words
                to transform, uplift, and inspire.
              </p>

              <p>
                So join us on this journey of discovery. Let us be your guide,
                your friend, your ally. Let us show you the way to a brighter,
                more beautiful tomorrow.
              </p>

              <p>Subscribe today and let the magic begin.</p>
            </div>
            <div>
              <Button onClick={startSub}>Start your free trail</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <div style={{ textAlign: 'center' }}>
            <br />
            <h1>1000+ Key Ideas Available to read</h1>
            <br />
          </div>
          {storyLoading ? (
            <Loader></Loader>
          ) : (
            stories &&
            stories.map((story) => (
              <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                <Route
                  render={({ history }) => (
                    <Story history={history} story={story} />
                  )}
                />
              </Col>
            ))
          )}
          <div style={{ textAlign: 'center' }}>
            <Button onClick={startSub}>Start your free trail</Button>
          </div>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Body>
          <Form onSubmit={submitHandler2}>
            <div>
              <Button className='close-button' onClick={handleClose}>
                X
              </Button>
            </div>
            <div style={{ padding: '20px' }}>
              <Form.Group>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <img
                    src='../../images/logo.png'
                    className='siteLogo'
                    alt='99bookscart logo'
                  />
                  <br />
                  <div>Read Your Favorite Non Fiction Books in 15 Min!</div>
                  <br />
                  <div className='social-logins'>
                    <SocialLogin />
                  </div>
                </div>
                <Form.Text className='red-text'>
                  {errorMessage && <span>{errorMessage}</span>}
                </Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  required
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>

                <Form.Text className='red-text'>
                  {emailError && <span>{emailError}</span>}
                </Form.Text>

                <Form.Control
                  type='email'
                  placeholder='Email'
                  required
                  value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
                <Form.Text className='red-text'>
                  {passwordError && <span>{passwordError}</span>}
                </Form.Text>
                <Form.Control
                  type='password'
                  placeholder='Passsword'
                  required
                  value={password || ''}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
{
}

export default StoriesScreen
