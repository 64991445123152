import { React, useState } from 'react'
import { Route, useLocation, Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

import { FaUserAlt } from 'react-icons/fa'
import { FaBookReader, FaPowerOff } from 'react-icons/fa'

import { FaSearch } from 'react-icons/fa'
import 'react-pro-sidebar/dist/css/styles.css'
import { useHistory } from 'react-router-dom'

const Header = ({}) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const [menuCollapse, setMenuCollapse] = useState(false)

  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  }
  const location = useLocation()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)

  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }

  return (
    <header>
      {userInfo &&
        !userInfo.isAdmin &&
        location.pathname.indexOf('plot') == -1 && (
          <Container>
            <Navbar expand='lg' collapseOnSelect className='customer-nav'>
              <div style={{ width: '100%' }}>
                <div style={{ float: 'left' }}>
                  <Link to='/app'>
                    <img
                      src='../../images/logo.png'
                      className='siteLogo-app'
                      alt='litplots logo'
                    />
                  </Link>
                </div>

                <div style={{ float: 'right' }}>
                  <Link to='/app/profile/readings'>
                    <FaBookReader className='profile-icons' />
                  </Link>
                  <Link to='/app/profile/subscriptions'>
                    <FaUserAlt className='profile-icons'></FaUserAlt>
                  </Link>
                </div>
              </div>
            </Navbar>
          </Container>
        )}
      {userInfo &&
        userInfo.isAdmin &&
        location.pathname.indexOf('plot') == -1 && (
          <Container>
            <Navbar expand='lg' collapseOnSelect className='customer-nav'>
              <Container>
                <LinkContainer to='/'>
                  <Navbar.Brand href='/'>
                    <img
                      src='../../images/logo.png'
                      className='siteLogo'
                      alt='litplots logo'
                    />
                  </Navbar.Brand>
                </LinkContainer>
                <>
                  <Navbar.Toggle aria-controls='basic-navbar-nav' />
                  <Navbar.Collapse
                    id='basic-navbar-nav'
                    className='justify-content-end'
                  >
                    <Nav className='ml-auto'>
                      <LinkContainer to='/admin/dashboard'>
                        <Nav.Link>Dashboard</Nav.Link>
                      </LinkContainer>
                      <NavDropdown title='Plots' id='Plots'>
                        <LinkContainer to='/create/episode'>
                          <NavDropdown.Item>Create New Plots</NavDropdown.Item>
                        </LinkContainer>
                        {/* <LinkContainer to='/stories'>
                          <NavDropdown.Item>List Stories</NavDropdown.Item>
                        </LinkContainer>{' '}
                        <LinkContainer to='/profile/mystories'>
                          <NavDropdown.Item>My Stories</NavDropdown.Item>
                        </LinkContainer> */}
                        <LinkContainer to='/app/admin/story/all'>
                          <NavDropdown.Item>All Plots</NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>

                      <NavDropdown title='users' id='users'>
                        <LinkContainer to='/admin/userlist'>
                          <NavDropdown.Item>List Users</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/admin/subscriberList'>
                          <NavDropdown.Item>Subscribers</NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>

                      {/* <LinkContainer to='/admin/productlist'>
                        <Nav.Link>Products</Nav.Link>
                      </LinkContainer> */}
                      <NavDropdown title='marketing' id='marketing'>
                        <LinkContainer to='/admin/flowlist'>
                          <NavDropdown.Item>Email Sequences</NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>
                      <NavDropdown title='submissions' id='submissions'>
                        <LinkContainer to='/admin/formsubmissions'>
                          <NavDropdown.Item>Contact Form</NavDropdown.Item>
                        </LinkContainer>
                        {/* <LinkContainer to='/admin/ebooksubmissions'>
                          <NavDropdown.Item>Ebook</NavDropdown.Item>
                        </LinkContainer> */}
                      </NavDropdown>
                      <NavDropdown title='Setting' id='setting'>
                        <LinkContainer to='/profile'>
                          <NavDropdown.Item>Profile</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/admin/discountCode'>
                          <NavDropdown.Item>Discount Code</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Item onClick={logoutHandler}>
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </>
              </Container>
            </Navbar>
          </Container>
        )}
    </header>
  )
}

export default Header
