import React from 'react'
import Helmet from 'react-helmet'
const BlogsMeta = ({ blog }) => {
  const title = blog.title
  const image = blog.image
  const author = 'Pooja Rawath'
  const createdAt = blog.createdAt ? blog.createdAt.slice(0, 10) : ''
  const updatedAt = blog.updatedAt ? blog.updatedAt.slice(0, 10) : ''
  const description = blog.description
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: title,
    image: blog.image,
    datePublished: createdAt,
    dateModified: updatedAt,
    articleBody: description,
    articleSection: 'Books',
    author: [
      {
        '@type': 'Person',
        name: author,
        url: 'https://www.99bookscart.com/contact-us',
      },
    ],
    publisher: {
      '@type': 'Organization',
      name: '99bookscart',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.99bookscart.com/images/logo.png',
      },
    },
  }
  return (
    <Helmet>
      <title>{blog.title}</title>
      <meta charset='UTF-8' />

      <meta property='og:title' content={blog.title} />

      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.99bookscart.com' />
      <meta
        property='og:image'
        content='https://99bookscart.com/images/logo.png'
      />
      <meta property='og:description' content={blog.metaDescription} />
      <meta property='og:site_name' content='99bookscart' />
      <meta name='description' content={blog.metaDescription}></meta>
      <meta
        name='keyword'
        content='second hand books, books online, books to read, online book selling platform, online book marketplace, buy books online, sell books online, new books, second-hand books, rare books,  book stores, Books, New & second hand books, bookstore near me, bookshop near me,Shop for 2023 Books'
      ></meta>
      <meta name='title' content={blog.title}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      <link rel='canonical' href='https://www.99bookscart.com'></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(articleStructuredData)}
      </script>
    </Helmet>
  )
}

export default BlogsMeta
