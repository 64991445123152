import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Notification = () => {
  const dispatch = useDispatch()

  const salesDa = [
    {
      name: 'Dr Satish Kumar',
      image: './images/billis.jpg',
      date: '3m',
      goal: 'Wants to Create Wealth',
    },
    {
      name: 'Shivram Modi',
      image: './images/billis.jpg',
      date: '34m',

      goal: 'Wants to have a successful career',
    },
    {
      name: 'Radhika Sharma',
      image: './images/billis.jpg',
      date: '4h',
      goal: 'Wants to Increase productivity',
    },
    {
      name: 'Hemant Kohli',
      image: './images/billis.jpg',
      date: '5h',
      goal: 'Wants to Improve sex life',
    },
    {
      name: 'Simran',
      image: './images/billis.jpg',
      date: '8h',
      goal: 'Wants to Boost intelligence',
    },
    // Add more sales data items here...
  ]
  const [currentNotification, setCurrentNotification] = useState(null)
  const [salesData, setSaleData] = useState(salesDa)

  useEffect(() => {
    if (salesData.length === 0) {
      setSaleData(salesData)
    }
  }, [dispatch])
  useEffect(() => {
    let counter = 0
    const interval = setInterval(() => {
      if (counter >= salesData.length) {
        clearInterval(interval)
      } else {
        setCurrentNotification(salesData[counter])
        counter++
      }
    }, 10000) // Add a delay of 4 seconds between each notification display

    return () => clearInterval(interval)
  }, [salesData])

  useEffect(() => {
    if (currentNotification) {
      const timeout = setTimeout(() => {
        setCurrentNotification(null)
      }, 3000) // Display each notification for 3 seconds

      return () => clearTimeout(timeout)
    }
  }, [currentNotification])

  return (
    <div className='sales-notification-container'>
      {currentNotification && (
        <div className='sales-notification'>
          <img
            src={currentNotification.image}
            alt='Book'
            style={{ width: '80px' }}
          />
          <div className='notification-details'>
            <p style={{ color: '#022335', fontWeight: 'bold' }}>
              {currentNotification.name} Started Reading
              <br />
              <small style={{ fontFamily: 'Verdana', fontSize: '12px' }}>
                {currentNotification.goal}
              </small>
              <br />
              <small style={{ color: '#000' }}>
                {currentNotification.date + ' ago'}
              </small>
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
const calculateTimeAgo = (mongoTimestamp) => {
  const currentTime = new Date()
  const timestampDate = new Date(mongoTimestamp)

  const timeDifference = currentTime - timestampDate
  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (seconds < 60) {
    return 'just now'
  } else if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
  } else if (hours < 24) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
  } else {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`
  }
}
export default Notification
