import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup } from 'react-bootstrap'
import Product from '../components/Product'
import axios from 'axios'
import { listProducts } from '../actions/productActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import CPaginate from '../components/CPaginate'
import { Container } from 'react-bootstrap'
import ProductCarosel from '../components/ProductCarosel'
import Meta from '../components/Meta'
import BoxProgressBar from '../components/BoxProgressBar'
import BoxCarosel from '../components/BoxCarosel'
import { geteBooksList } from '../actions/ebookActions'
import Ebook from '../components/Ebook'
const EbooksScreen = ({ match }) => {
  const pageNumber = match.params.pageNumber || 1

  const ebookList = useSelector((state) => state.listEbook)
  const { loading, error, ebooks, pages, page } = ebookList

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(geteBooksList())
  }, [dispatch, match, pageNumber])

  return (
    <>
      <Meta></Meta>
      <h1>ebooks</h1>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>
          <h2>error</h2>
        </Message>
      ) : (
        <>
          <Container>
            <Row>
              {ebooks &&
                ebooks.map((product) => (
                  <Col
                    key={product._id}
                    sm={4}
                    md={4}
                    lg={2}
                    xs={4}
                    className='product-grid-size'
                  >
                    <Route
                      render={({ history }) => (
                        <Ebook history={history} product={product} />
                      )}
                    />
                  </Col>
                ))}
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

export default EbooksScreen
