import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import {
  FaBookReader,
  FaStar,
  FaMoneyBillAlt,
  FaExclamationCircle,
} from 'react-icons/fa'
const BookStats = ({ product, coins, binding }) => {
  return (
    <Container>
      <Row className='storystatsrow'>
        <Col>
          <div className='storiestatslabel'>
            <FaBookReader /> Rating
          </div>
          <div className='storiestatsvalue'>
            {product.rating === 0 ? product.grating : product.rating}
          </div>
        </Col>
        <Col>
          <div className='storiestatslabel'>
            <FaStar /> Review
          </div>
          <div className='storiestatsvalue'>
            {product.reviews.length > 0
              ? product.reviews.length
              : product.gnumReviews
              ? product.gnumReviews.toLocaleString()
              : 0}
          </div>
        </Col>
        <Col>
          <div className='storiestatslabel'>
            <FaMoneyBillAlt /> Coins
          </div>
          <div className='storiestatsvalue'>{coins}</div>
        </Col>
        <Col>
          <div className='storiestatslabel'>
            <FaExclamationCircle /> Binding
          </div>
          <div className='storiestatsvalue'>{binding}</div>
        </Col>
      </Row>
    </Container>
  )
}

export default BookStats
