import axios from 'axios'
import { AI_FAIL, AI_REQUEST, AI_SUCCEES } from '../constants/aiConstants'
import {
  BLOG_CREATET_SUCCESS,
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_DELETE_FAIL,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_EDIT_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_SUCCESS,
  BLOG_GET_REQUEST,
  BLOG_GET_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
} from '../constants/blogConstants'

export const createBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/blog`, blog, config)
    dispatch({
      type: BLOG_CREATET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BLOG_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listBlog = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST })

    const { data } = await axios.get(`/api/blog?pageNumber=${pageNumber}`)
    dispatch({
      type: BLOG_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BLOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getBlog = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_GET_REQUEST })

    const { data } = await axios.get(`/api/blog/${id}`)
    dispatch({
      type: BLOG_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BLOG_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const editBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_EDIT_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/blog/${blog._id}`, blog, config)
    dispatch({
      type: BLOG_EDIT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BLOG_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteBlog = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/blog/${id}`, config)
    dispatch({
      type: BLOG_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BLOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
