import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Howl } from 'howler'
import { FaPauseCircle, FaPlay } from 'react-icons/fa'

const AudioPlayer = ({ src, name, history }) => {
  const [player, setPlayer] = useState(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  useEffect(() => {
    const sound = new Howl({
      src: [src],
      html5: true,
      onload: () => {
        setDuration(sound.duration()) // Set duration once loaded
      },
      onplay: () => {
        if (userLoggedIn) {
          setPlaying(true) // Update playing state to true when play starts
        } else {
          history.push(`/login`)
        }
      },
      onpause: () => {
        setPlaying(false) // Update playing state to false when paused
      },
      onend: () => {
        setPlaying(false)
        setCurrentTime(0) // Reset current time at the end of playback
      },
    })

    setPlayer(sound)

    return () => {
      sound.unload() // Clean up when the component unmounts
    }
  }, [src])

  useEffect(() => {
    if (playing) {
      const updateProgress = () => {
        if (player && player.playing()) {
          setCurrentTime(player.seek())
          requestAnimationFrame(updateProgress)
        }
      }

      requestAnimationFrame(updateProgress) // Start updating progress when playing
    }
  }, [playing, player])

  const handlePlayPause = () => {
    if (player) {
      if (player.playing()) {
        player.pause()
      } else {
        player.play()
      }
    }
  }

  const formatTime = (secs) => {
    const minutes = Math.floor(secs / 60) || 0
    const seconds = Math.floor(secs % 60) || 0
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  return (
    <div className='fixed-bottom bg-light p-2 backgg'>
      <div
        className='container'
        style={{ borderTop: '1px solid #D8D7D6', color: '#D8D7D6' }}
      >
        <div className='row align-items-center'>
          <div className='col'>
            <h5 style={{ color: '#D8D7D6' }}>{name}</h5>
            <div style={{ fontSize: '12px' }}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
          </div>
          <div className='col-auto'>
            <button onClick={handlePlayPause} className='audioplaybutton'>
              {playing ? (
                <FaPauseCircle style={{ color: '#fff' }} />
              ) : (
                <FaPlay style={{ color: '#fff' }} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudioPlayer
