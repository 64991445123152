import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Card, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
 import OrderSummary from '../components/OrderSummary'
import { Link } from 'react-router-dom'
import { FaTruck } from 'react-icons/fa'
import {
  userAddressList,
  deleteUserAddress,
  createGuestUserAddress,
} from '../actions/userActions'
import Cookies from 'universal-cookie'
import { SHIPPING_STATE } from '../constants/orderConstants'

import Loader from '../components/Loader'
const cookies = new Cookies()
const BasicInformationScreen = ({ history }) => {
  const firstRender = useRef(true)

  const dispatch = useDispatch()

  const guestUser = useSelector((state) => state.guestUser)
  const { user, success: guestSuccess, error: guestError } = guestUser

  const [email, setEmail] = useState(cookies.get('_cfx_email'))
  const [name, setName] = useState('')

  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phone, setPhone] = useState(cookies.get('_cfx_phone'))
  const [state, setState] = useState('')

  const [disable, setDisabled] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)
  const [addNew, setAddNew] = useState(false)
  var date = new Date()
  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  // const [country, setCountry] = useState(shippingAddress.country)
  let country = 'India'
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
    if (guestSuccess) {
      if (!user.data) {
        history.push('/checkout/shipping')
      }
    }
    if (guestError) {
      if (guestError.type == 'phone') {
        setPhoneError(guestError.data)
      }
      if (guestError.type == 'email') {
        setEmailError(guestError.data)
      }
    }
  }, [dispatch, guestSuccess, guestError])

  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const submitHandler = (e) => {
    e.preventDefault()
    if (isEmail(email)) {
      setEmailError('')
      var phonenoExp = /^\d{10}$/
      if (phonenoExp.test(phone)) {
        setPhoneError('')
        if (postalCode.length == 6) {
          setZipError('')

          dispatch(
            createGuestUserAddress({
              name,
              address,
              address2,
              city,
              postalCode,
              state,
              country,
              phone,
              email,
              cartId: cookies.get('_cid'),
            })
          )
        } else {
          setZipError('Enter Valid ZipCode')
        }
      } else {
        setPhoneError('Enter Valid Phone Number')
      }
    } else {
      setEmailError('Enter Valid email')
    }
  }
  return (
    <Container>
      <FormContainer>
        {/* <OrderSummary></OrderSummary> */}
        {/* <CheckoutSteps step1 /> */}
        <h4 className='cart-header'>Shipping Address</h4>
        <br />
        <>
          {/* <Container className='delivery-date'>
            <FaTruck></FaTruck> Delivery by {deliveryDate.getDate()}{' '}
            {months[deliveryDate.getMonth()]}
          </Container> */}
          <Row>
            <p>
              Open a book, open your mind: Reading expands your world inside!
            </p>
          </Row>
          <Form onSubmit={submitHandler} className='cart-form'>
            <Form.Group controlId='guestemail'>
              <small>Already have an Account?</small>{' '}
              <Link to='/login'>Login</Link>
              <br />
              <Form.Text className='red-text'>
                {emailError && <span>{emailError}</span>}
              </Form.Text>
              {/* <Form.Label style={{ fontSize: '12px', marginBottom: '0em' }}>
              Email
            </Form.Label> */}
              <Form.Control
                type='text'
                placeholder='Email'
                required
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='name'>
              <Form.Control
                type='text'
                placeholder='Full Name'
                required
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='phone'>
              <Form.Text className='red-text'>
                {phoneError && <span>{phoneError}</span>}
              </Form.Text>
              <Form.Control
                type='tel'
                placeholder='Phone Number'
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='address'>
              <Form.Control
                type='text'
                placeholder='Address'
                required
                value={address || ''}
                onChange={(e) => setAddress(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='address2'>
              <Form.Control
                type='text'
                placeholder='Apartment, Suite, etc (Optional)'
                value={address2 || ''}
                onChange={(e) => setAddress2(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='city'>
              <Form.Control
                type='text'
                placeholder='City'
                required
                value={city || ''}
                onChange={(e) => setCity(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
              <Form.Text className='text-muted'>
                {zipError && <span>{zipError}</span>}
              </Form.Text>
              <Form.Control
                type='text'
                placeholder='Zip Code'
                required
                value={postalCode || ''}
                onChange={(e) => setPostalCode(e.target.value)}
                className='checkoutForm'
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='postalCode'></Form.Group>
            <Form.Group controlId='state'>
              <Form.Control
                as='select'
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                className='checkoutForm'
              >
                <option key='Select your State' value={''}>
                  State
                </option>
                {SHIPPING_STATE.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <br />
            <Button
              type='submit'
              variant='primary'
              disabled={disable}
              className='float-right'
            >
              Continue
            </Button>
          </Form>
          <br />
        </>
      </FormContainer>
    </Container>
  )
}

export default BasicInformationScreen
