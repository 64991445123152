import React from 'react'
import { Helmet } from 'react-helmet'
const BookMeta = ({ story }) => {
  // const slug = convertToSlug(product.name)
  if (story) {
    var slug = ''
    if (story.title) {
      slug = convertToSlug(story.title)
    }
    const url =
      'https://www.NexNuggets.com/book-summary/' + slug + '/' + story._id

    const articleStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: story.title,
      image: story.coverImage,
      datePublished: story.createdAt,
      dateModified: story.updatedAt,
      articleBody: story.description,
      articleSection: 'Books',
      author: [
        {
          '@type': 'Person',
          name: story.user.name,
          url: 'https://www.NexNuggets.com/contact-us',
        },
      ],
      publisher: {
        '@type': 'Organization',
        name: 'NexNuggets',
        logo: {
          '@type': 'ImageObject',
          url: 'https://www.NexNuggets.com/images/logo.png',
        },
      },
    }
    return (
      <Helmet>
        <meta charset='UTF-8' />
        <title>{`${story.title} Book Summary, Rating,  Review, Synopsis | NexNuggets.com`}</title>

        <meta
          property='og:title'
          content={`Read ${story.title} book summary for FREE on NexNuggets.com. Book Summary, Stories, Poetry and more`}
        />

        {/* <head prefix='book: https://ogp.me/ns/book#'></head>
        <meta property='og:type' content='book:isbn' />
        <meta property='og:url' content={url} />
        <meta property='og:image' content={product.image} />
        <meta property='og:description' content={product.description} />
        <meta property='og:site_name' content='99bookscart' /> */}
        <meta property='product:brand' content='litplts' />
        <meta property='product:availability' content='in stock' />
        <meta property='product:condition' content='new' />
        <meta property='product:price:amount' content={249} />
        <meta property='product:price:currency' content='INR' />
        <meta
          name='twitter:title'
          content={`${story.title} Book Summary, Rating,  Review | NexNuggets.com`}
        />
        <meta
          name='twitter:description'
          content={`Read ${story.title} book summary for FREE on NexNuggets.com. Book Summary, Stories, Poetry and more`}
        />
        <meta
          name='twitter:image'
          content='https://www.NexNuggets.com/images/logo.png'
        />
        <meta name='twitter:card' content='summary_large_image'></meta>
        <meta
          name='title'
          content={`${story.title} Book Summary, Rating,  Review, Synopsis | NexNuggets.com`}
        ></meta>
        <meta
          name='description'
          content={`Read ${story.title} book summary for FREE on NexNuggets.com. Book Summary, Stories, Poetry and more`}
        ></meta>
        <meta
          name='keyword'
          content={`Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales`}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link rel='canonical' href={url}></link>
        <meta name='robots' content='index, follow' />
        <script className='structured-data-list' type='application/ld+json'>
          {JSON.stringify(articleStructuredData)}
        </script>
      </Helmet>
    )
  } else {
    return <Helmet></Helmet>
  }
}

function convertToSlug(string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default BookMeta
