import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { register } from '../../actions/userActions'
import { getCategory } from '../../helpers/helpers'
import Loader from '../../components/Loader'
import Story from '../../components/Story'
import StoryStats from '../../components/StoryStats'
import { FaBookReader } from 'react-icons/fa'
import SocialShare from '../../components/SocialShare'
import SubNav from '../../components/SubNav'
import SocialLogin from '../../components/SocialLogin'

const StoryViewScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const url = `https://www.99bookscart.com/stories/${match.params.id}`

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isReadMore, setIsReadMore] = useState(true)
  const getStoryRed = useSelector((state) => state.getStory)
  const {
    story,
    StoryStats: stats,
    similarStories,
    loading,
    error,
    success,
  } = getStoryRed

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const subscribeButton = () => {
    if (userLoggedIn) {
      if (userLoggedIn.isSubscribed) {
        history.push(`/app/idea/${match.params.id}`)
      } else {
        history.push(`/app/profile/billing`)
      }
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getStory(match.params.id))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, match, userInfo, registerSuccess, emailError])
  return (
    <Container>
      <SubNav history={history}></SubNav>
      {loading || !success ? (
        <Loader></Loader>
      ) : (
        <>
          <Container>
            <Row className='story-head-section'>
              <Col xs={4}>
                <div>
                  <Image
                    src={story.coverImage}
                    layout='responsive'
                    height='230'
                    width='150'
                    className='storypagecover'
                  />
                </div>
              </Col>
              <Col xs={8}>
                <div style={{ padding: '10px' }}>
                  <h1>{story.title}</h1>
                  <div className='writer-name'>
                    {/* <Link to={`/user/${story.user._id}`}> */}
                    <span>{story.user && story.user.name}</span>
                    {/* </Link> */}
                  </div>

                  <div className='desktop-view' style={{ width: '50%' }}>
                    <StoryStats story={story}></StoryStats>
                  </div>
                  <div>
                    <Button className='btn-primary' onClick={subscribeButton}>
                      <FaBookReader />
                      &nbsp; Subscribe to Read
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: '8px' }}>
            <Col>
              <>
                <div className='mobile-view'>
                  <StoryStats story={story}></StoryStats>
                </div>
                <h3 className='storietaglabel'>What's in the book? </h3>
                <h2>Ideas</h2>
                <div className='storychaptersection'>
                  {story.episodes.map((episode, index) => (
                    // <Link to={`/episode/${episode._id}`}>
                    <Row key={episode._id} className='chaptersection'>
                      <Col xs={9} className='chapter-title'>
                        {'Idea#' + (index + 1) + ' ' + episode.title}
                      </Col>
                      <Col xs={3} style={{ float: 'right' }}>
                        {episode.duration}
                      </Col>
                    </Row>
                    // </Link>
                  ))}
                </div>
                <div className='storydescription'>
                  {isReadMore
                    ? ReactHtmlParser(
                        story.description && story.description.slice(0, 1500)
                      )
                    : ReactHtmlParser(story.description)}
                  <div onClick={toggleReadMore} className='read-or-hide'>
                    {isReadMore ? '...read more' : ' show less'}
                  </div>
                </div>
                <div>
                  {typeof story.category === 'string' ? (
                    <>{getCategory(story.category)}</>
                  ) : (
                    <>
                      <h2>Related Topics</h2>
                      {story.category.map((cat) => (
                        <Link to={`/book-ideas/category/${cat.name}`}>
                          <span key={`${cat._id}`} className='topic'>
                            <span>{cat.name}</span>
                          </span>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
                {story.tags.length > 0 && (
                  <div className='story-tags'>
                    <span className='storietaglabel'>Tags: </span>
                    {story.tags.map((tag) => (
                      <Link
                        to={`/stories`}
                        key={`${tag._id}`}
                        className='topic'
                      >
                        <span>{tag.name}</span>
                      </Link>
                    ))}
                  </div>
                )}

                <SocialShare url={url} title={story.title}></SocialShare>
              </>
            </Col>
          </Row>
          {/* <Container>
            <h1>Books</h1>
            <Row>
              {similarStories.map((story) => (
                <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                  <Route
                    render={({ history }) => (
                      <Story history={history} story={story} />
                    )}
                  />
                </Col>
              ))}
            </Row>
          </Container> */}
        </>
      )}
      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Body>
          <Form onSubmit={submitHandler2}>
            <div>
              <Button className='close-button' onClick={handleClose}>
                X
              </Button>
            </div>
            <div style={{ padding: '20px' }}>
              <Form.Group>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <img
                    src='../../images/logo.png'
                    className='siteLogo'
                    alt='99bookscart logo'
                  />
                  <br />
                  <h5>Join the Coommunity of More than 1.2 Lakh Reader</h5>
                  <br />
                  <div className='social-logins'>
                    <SocialLogin />
                  </div>
                </div>
                <Form.Text className='red-text'>
                  {errorMessage && <span>{errorMessage}</span>}
                </Form.Text>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  required
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>

                <Form.Text className='red-text'>
                  {emailError && <span>{emailError}</span>}
                </Form.Text>

                <Form.Control
                  type='email'
                  placeholder='Email'
                  required
                  value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
                <Form.Text className='red-text'>
                  {passwordError && <span>{passwordError}</span>}
                </Form.Text>
                <Form.Control
                  type='password'
                  placeholder='Passsword'
                  required
                  value={password || ''}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Button
                    type='submit'
                    variant='primary'
                    className='float-right'
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default StoryViewScreen
