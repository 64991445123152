import React, { useState, useEffect } from 'react'
import { Button, ProgressBar, Form, Row, Container } from 'react-bootstrap'
import LoginForm from './LoginForm'
import NewLogin from './NewLogin'
import { Link } from 'react-router-dom'
import LoadingStep from './LoadingStep'

const StepFunnel = ({ history }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [onboardArray, setOnboardArray] = useState([])
  const [userInputs, setUserInputs] = useState({
    ageRange: '',
    gender: '',
    interests: [],
    readingFrequency: '',
    focusType: '',
    decisiveness: '',
  })
  const [progress, setProgress] = useState(0)
  const [randomNumber, setRandomNumber] = useState(null)
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const id = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(id)
          return 100
        }
        return Math.min(oldProgress + 100 / 6, 100)
      })
    }, 1000) // Update every second

    setIntervalId(id)

    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    if (progress === 100) {
      // Generate a random number between 200 and 400
      setRandomNumber(Math.floor(Math.random() * 201) + 200)
    }
  }, [progress])
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1)
    localStorage.setItem('stepCompletion', currentStep)
    localStorage.setItem('completionDate', new Date().toISOString())
    setOnboardArray([...onboardArray, userInputs])
    sendDataToBackend(userInputs)
  }

  const handleBackStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }
  const sendDataToBackend = async (data) => {
    const apiUrl = '/api/story/funnel' // Replace with your actual API URL
    const fid = localStorage.getItem('fid')

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fid,
        ageRange: data.ageRange,
        gender: data.gender,
        interests: data.interests,
        readingFrequency: data.readingFrequency,
        focusType: data.focusType,
        decisiveness: data.decisiveness,
      }),
    })

    const responseData = await response.json()
    if (!fid && responseData._id) {
      localStorage.setItem('fid', responseData._id)
    }
  }
  const handleInput = (field, value) => {
    let newUserInputs = { ...userInputs }
    if (field === 'interest') {
      const newInterests = newUserInputs.interests.includes(value)
        ? newUserInputs.interests.filter((interest) => interest !== value)
        : [...newUserInputs.interests, value]
      newUserInputs.interests = newInterests
    } else {
      newUserInputs[field] = value
    }
    setUserInputs(newUserInputs)

    if (
      field === 'ageRange' ||
      field === 'gender' ||
      field === 'readingFrequency'
    ) {
      handleNextStep()
    }
  }

  const progress11 = Math.round((currentStep / 13) * 100) // Total steps are now 5

  return (
    <Container className='mt-1' style={{ padding: '28px' }}>
      <div className='text-center'>
        <Link to='/'>
          <img
            src='../../images/logo.png'
            style={{ width: '140px' }}
            alt='nexnuggets logo'
          />
        </Link>
        <br />
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Become the most interesting person in the room
        </div>
      </div>
      <ProgressBar
        now={progress11}
        label={`${progress11}%`}
        style={{ height: '22px' }}
      />

      <div className='mt-1'>
        <h2
          className='text-center'
          style={{ fontWeight: 'bold', fontSize: '20px' }}
        >{`${
          [
            'What is your age?',
            'Select Your Gender',
            'Hooray',
            'In which area you want to grow?',
            'How much do read a week?',
            'Your Personal Growth Plan is ready',
            'Focus on the Big Picture or Details?',
            'Do you always know what you want exactly?',
            'What is your primary goal for reading?',
            'What describes you best?',
            'What would help you to achieve your goals? 🎯',
            // 'People with clear goals produce more than other people',
            'Crafting your customized experience',
            'Become Smartest person in room',
          ][currentStep - 1]
        }`}</h2>
        {currentStep === 1 &&
          ['18-24', '25-35', '36-45', '46+'].map((ageRange) => (
            <Row key={ageRange} className='mt-3'>
              <Button
                variant='outline-primary'
                onClick={() => handleInput('ageRange', ageRange)}
              >
                {ageRange}
              </Button>
            </Row>
          ))}
        {currentStep === 2 &&
          ['👩 Female', '👨 Male', '🧑 Other'].map((gender) => (
            <Row key={gender} className='mt-3'>
              <Button
                variant='outline-primary'
                onClick={() => handleInput('gender', gender)}
              >
                {gender}
              </Button>
            </Row>
          ))}
        {currentStep === 3 && (
          <div className='text-center'>
            <img
              src='./images/hurry.png'
              alt='Descriptive Alt Text'
              className='img-fluid'
              style={{ width: '110px' }}
            />
            <p style={{ marginTop: '8px' }}>
              Congratulations on taking the first step towards self-care! 🌟
              Join over 3,700 professionals who enhance their daily lives
              through our platform every single day.📚✨
            </p>
            <Button variant='primary' onClick={handleNextStep}>
              Continue
            </Button>
          </div>
        )}
        {currentStep === 4 && (
          <div style={{ textAlign: 'center' }}>
            <small style={{ color: '#E74C3C' }}>
              Select atleast 2 options ({userInputs.interests.length}/2)
            </small>
            <br />
            {[
              'Have a successful career',
              'Build a close-knit family',
              'Increase productivity',
              'Achieve life balance',
              'Lead a healthy lifestyle',
              'Become confident',
              'Improve sex life',
              'Develop healthy relationships',
              'Boost intelligence',
              'Reach happiness',
            ].map((interest) => (
              <Button
                key={interest}
                variant={
                  userInputs.interests.includes(interest)
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('interest', interest)}
                className='mt-1'
                style={{ marginRight: '6px' }}
              >
                {interest}
              </Button>
            ))}
            <Row className='mt-4'>
              {userInputs.interests.length >= 2 && (
                <Button variant='primary' onClick={handleNextStep}>
                  Next
                </Button>
              )}
            </Row>
          </div>
        )}
        {currentStep === 5 &&
          ['10min/day', '30min/day', '1hour/day', 'Once in a while'].map(
            (readingFrequency) => (
              <Row key={readingFrequency} className='mt-3'>
                <Button
                  variant='outline-primary'
                  onClick={() =>
                    handleInput('readingFrequency', readingFrequency)
                  }
                >
                  {readingFrequency}
                </Button>
              </Row>
            )
          )}
        {currentStep === 6 && (
          <div className='text-center'>
            <img
              src='./images/Now.png'
              alt='Growth success'
              className='img-fluid'
              style={{ width: '200px' }}
            />
            <p>📚Nothing feels better than self improvement✨</p>
            <Button variant='primary' onClick={handleNextStep}>
              Continue
            </Button>
          </div>
        )}
        {currentStep === 7 && (
          <div className='text-center'>
            <Button
              variant={
                userInputs.focusType === 'Big Picture'
                  ? 'primary'
                  : 'outline-primary'
              }
              onClick={() => handleInput('focusType', 'Big Picture')}
              style={{ paddingLeft: '12px', paddingRight: '12px' }}
            >
              Big Picture
            </Button>

            <Button
              variant={
                userInputs.focusType === 'Details Oriented'
                  ? 'primary'
                  : 'outline-primary'
              }
              onClick={() => handleInput('focusType', 'Details Oriented')}
              style={{
                marginLeft: '12px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              Details Oriented
            </Button>
            {userInputs.focusType && (
              <>
                <p className='step-descrip' style={{ marginTop: '8px' }}>
                  {userInputs.focusType === 'Big Picture'
                    ? 'Seeing the big picture helps you to understand broad concepts and their implications.'
                    : 'Being detail-oriented helps you to catch small errors and make precise adjustments.'}
                </p>
                <Button variant='primary' onClick={handleNextStep}>
                  Continue
                </Button>
              </>
            )}
          </div>
        )}

        {currentStep === 8 && (
          <div className='text-center'>
            <Row className='justify-content-center'>
              <Button
                variant={
                  userInputs.decisiveness === 'Yes'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('decisiveness', 'Yes')}
              >
                Yes
              </Button>
              <Button
                variant={
                  userInputs.decisiveness === 'Maybe'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('decisiveness', 'Maybe')}
                style={{ marginTop: '8px' }}
              >
                Maybe
              </Button>
              <Button
                variant={
                  userInputs.decisiveness === 'No'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('decisiveness', 'No')}
                style={{ marginTop: '8px' }}
              >
                No
              </Button>
            </Row>
            {userInputs.decisiveness && (
              <>
                <p className='step-descrip' style={{ marginTop: '8px' }}>
                  {userInputs.decisiveness === 'Yes'
                    ? 'Great! Decisiveness can lead to more effective leadership and faster problem solving.'
                    : userInputs.decisiveness === 'Maybe'
                    ? 'Sometimes being unsure can lead to better decision-making through more thorough consideration.'
                    : 'It’s okay to be unsure. It’s important to gather enough information before making decisions.'}
                </p>
                <Button variant='primary' onClick={handleNextStep}>
                  Continue
                </Button>
              </>
            )}
          </div>
        )}
        {currentStep === 9 && (
          <div className='text-center'>
            <h2>What is your primary goal for reading? 📚</h2>
            <Row className='justify-content-center'>
              <Button
                variant={
                  userInputs.primaryGoal === 'Knowledge'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('primaryGoal', 'Knowledge')}
              >
                Gain Knowledge 🎓
              </Button>
              <Button
                variant={
                  userInputs.primaryGoal === 'Entertainment'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('primaryGoal', 'Entertainment')}
                style={{ marginTop: '8px' }}
              >
                Entertainment 🎭
              </Button>
              <Button
                variant={
                  userInputs.primaryGoal === 'Skills'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('primaryGoal', 'Skills')}
                style={{ marginTop: '8px' }}
              >
                Develop Skills 🔧
              </Button>
            </Row>
            {userInputs.primaryGoal && (
              <>
                <p style={{ marginTop: '8px' }}>
                  {userInputs.primaryGoal === 'Knowledge'
                    ? 'You’ve chosen to enrich your mind with new knowledge. Great choice! 🏆'
                    : userInputs.primaryGoal === 'Entertainment'
                    ? 'Ready to be entertained? Grab a book and escape into another world! 🌎'
                    : 'Skill development is key to personal growth. Dive into books that sharpen your skills! ⚙️'}
                </p>
                <Button variant='primary' onClick={handleNextStep}>
                  Continue
                </Button>
              </>
            )}
          </div>
        )}

        {currentStep === 10 && (
          <div className='text-center'>
            <Row className='justify-content-center'>
              <Button
                variant={
                  userInputs.personality === 'Leader'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('personality', 'Leader')}
              >
                A leader 🚀
              </Button>
              <Button
                variant={
                  userInputs.personality === 'Follower'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('personality', 'Follower')}
                style={{ marginTop: '8px' }}
              >
                A follower 👣
              </Button>
              <Button
                variant={
                  userInputs.personality === 'Both'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('personality', 'Both')}
                style={{ marginTop: '8px' }}
              >
                A bit of both 🎯
              </Button>
            </Row>
            {userInputs.personality && (
              <>
                <p style={{ marginTop: '8px' }}>
                  {userInputs.personality === 'Leader'
                    ? 'Leading the way! 🚀 Your proactive approach is inspiring.'
                    : userInputs.personality === 'Follower'
                    ? 'Following the lead! 👣 You know there is strength in collaboration.'
                    : 'Balancing act! 🎯 Flexibility is your strength, adapting as needed.'}
                </p>
                <Button variant='primary' onClick={handleNextStep}>
                  Continue
                </Button>
              </>
            )}
          </div>
        )}
        {currentStep === 11 && (
          <div className='text-center'>
            <Row className='justify-content-center'>
              <Button
                variant={
                  userInputs.achieveGoals === 'Focus'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('achieveGoals', 'Focus')}
                style={{ marginTop: '8px' }}
              >
                Focus 🔍
              </Button>
              <Button
                variant={
                  userInputs.achieveGoals === 'Motivation'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('achieveGoals', 'Motivation')}
                style={{ marginLeft: '8px', marginTop: '8px' }}
              >
                Motivation 🚀
              </Button>
              <Button
                variant={
                  userInputs.achieveGoals === 'Effort'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('achieveGoals', 'Effort')}
                style={{ marginLeft: '8px', marginTop: '8px' }}
              >
                Effort 💪
              </Button>
              <Button
                variant={
                  userInputs.achieveGoals === 'Others'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => handleInput('achieveGoals', 'Others')}
                style={{ marginLeft: '8px', marginTop: '8px' }}
              >
                Others 🤔
              </Button>
            </Row>
            {userInputs.achieveGoals && (
              <>
                <p style={{ marginTop: '8px' }}>
                  {userInputs.achieveGoals === 'Focus'
                    ? 'Focus 🔍 - Essential for directing our energies towards achieving goals.'
                    : userInputs.achieveGoals === 'Motivation'
                    ? 'Motivation 🚀 - Fuels our drive to push forward, even when challenges arise.'
                    : userInputs.achieveGoals === 'Effort'
                    ? 'Effort 💪 - The key to turning aspirations into real accomplishments.'
                    : 'Others 🤔 - Let’s explore these unique drivers in your journey.'}
                </p>
                <Button variant='primary' onClick={handleNextStep}>
                  Continue
                </Button>
              </>
            )}
          </div>
        )}
        {/* {currentStep === 12 && (
          <div className='text-center'>
            <img
              src='./images/c33.PNG'
              alt='Descriptive Alt Text'
              className='img-fluid'
              style={{ width: '280px' }}
            />
            <br />
            <br />
            <Button variant='primary' onClick={handleNextStep}>
              Continue
            </Button>
          </div>
        )} */}
        {currentStep === 12 && (
          <div className='text-center'>
            <LoadingStep handleNextStep={handleNextStep}></LoadingStep>
          </div>
        )}
        {currentStep === 13 && (
          <NewLogin history={history} userInputs={userInputs}></NewLogin>
        )}
        {/* {currentStep > 1 && (
          <Row>
            <Button
              variant='outline-primary'
              className='mt-5'
              onClick={handleBackStep}
            >
              &larr; Back
            </Button>
          </Row>
        )} */}
      </div>
    </Container>
  )
}

export default StepFunnel
