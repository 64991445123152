import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Modal,
  Container,
} from 'react-bootstrap'
import { getCartDetailsFromStorage } from '../actions/cartActions.js'
import { Link } from 'react-router-dom'

const OrderSummary = ({ cart }) => {
  const dispatch = useDispatch()
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  return (
    <Container>
      {cart && (
        <Row className='order-summary text-center'>
          <Col xs={6}>
            <Link to='/cart'>View Cart </Link>
            <br /> {cart.cartItems && cart.cartItems.length} Items
          </Col>
          <Col xs={6}>
            Cart Total
            <br />
            <span className='productPrice'> &#8377;{cart.cartPrice}/-</span>
          </Col>
          {/* <Col xs={6}> Shipping Method
                </Col>
                <Col xs={6} className='capitalize-text'>{cart.shippingMethod}
                </Col>
                <Col xs={6}> COD Charges
                </Col>
                <Col xs={6} className='capitalize-text'>{cart.paymentMethod}
                </Col> */}
        </Row>
      )}
    </Container>
  )
}

export default OrderSummary
