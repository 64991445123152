import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import AppStory from '../../../components/AppStory'
import {
  getCategory,
  convertToSlug2,
  convertToSlug,
} from '../../../helpers/helpers'
import Loader from '../../../components/Loader'

import StoryStats from '../../../components/StoryStats'
import { FaBookReader, FaDownload, FaLock, FaPaperclip } from 'react-icons/fa'
import SocialShare from '../../../components/SocialShare'
import AudioPlayer from '../../../components/litplots/player/AudioPlayer'
import Rating from '../../../components/Rating'
import { Helmet } from 'react-helmet'
const AppPlotViewScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const [isReadMore, setIsReadMore] = useState(true)
  const getStoryRed = useSelector((state) => state.getStory)

  const {
    story,
    StoryStats: stats,
    similarStories,
    loading,
    error,
    success,
  } = getStoryRed

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const storyCoverPage = {
    width: '100px',
    float: 'right',
    borderRadius: '5px',
  }
  const storyCoverPageDesktop = {
    width: '250px',
  }
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    }
    dispatch(getStory(match.params.id))
  }, [dispatch, match, history])
  return (
    <>
      {loading || !success ? (
        <Loader></Loader>
      ) : (
        <Container>
          <Helmet>
            <title>
              {story.title} by {story.user && story.user.name} | NexNuggets
            </title>
            <meta charset='UTF-8' />
            <meta
              name='title'
              content={`${story.title} by ${
                story.user && story.user.name
              } | NexNuggets`}
            ></meta>
            <meta
              name='description'
              content={`${story.description.slice(0, 150)}`}
            ></meta>
            <meta name='keyword' content={`${story.title}}`}></meta>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1.0'
            ></meta>
            <meta name='robots' content='index, follow' />
          </Helmet>
          <Container>
            <Row className='story-head-section'>
              <Col xs={5}>
                <div>
                  <Image
                    src={story.coverImage}
                    layout='responsive'
                    height='230'
                    width='150'
                    className='storypagecover'
                    alt={story.title}
                  />
                </div>
              </Col>
              <Col xs={7}>
                {story.audio && (
                  <AudioPlayer src={story.audio} name={story.title} />
                )}
                <div style={{ padding: '10px' }}>
                  <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    {story.title}
                  </div>
                  {!story.isPaid && (
                    <span className='free-sticker'>Free Book</span>
                  )}
                  <div className='writer-name'>
                    {/* <Link to={`/user/${story.user._id}`}> */}
                    <span>{story.user && story.user.name}</span>
                    {/* </Link> */}
                  </div>
                  <Rating value={story.rating}></Rating>{' '}
                  <small className='reviews-count'>
                    {story.reviewCount.toLocaleString()} Reviews
                  </small>
                  <div className='desktop-view' style={{ width: '50%' }}>
                    <StoryStats story={story}></StoryStats>
                  </div>
                  {story.isPaid ? (
                    <div>
                      <Link to={`/app/en/plot/${story.episodes[0]._id}`}>
                        <Button
                          className='btn-primary'
                          style={{ marginTop: '15px' }}
                        >
                          <FaPaperclip />
                          &nbsp; Read
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <Link
                        to={`/app/en/plot/free/${story.episodes[0]._id}/${story._id}`}
                      >
                        <Button
                          className='btn-primary'
                          style={{ marginTop: '15px' }}
                        >
                          <FaPaperclip />
                          &nbsp; Read
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
                {story.aLink && userLoggedIn && userLoggedIn.isSubscribed && (
                  <div style={{ paddingLeft: '10px' }}>
                    <a href={story.aLink} target='_blank' rel='noreferrer'>
                      <FaDownload />
                      &nbsp; Buy on Amazon
                    </a>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: '15px' }}>
            <Col>
              <>
                <div className='mobile-view'>
                  <StoryStats story={story}></StoryStats>
                </div>

                <br />
                <h3 className='storietaglabel'>What's in the book? </h3>

                <div className='storydescription'>
                  {isReadMore
                    ? ReactHtmlParser(
                        story.description && story.description.slice(0, 800)
                      )
                    : ReactHtmlParser(story.description)}
                  <div onClick={toggleReadMore} className='read-or-hide'>
                    {isReadMore ? '...read more' : ' show less'}
                  </div>
                </div>
                {story.tags.length > 0 && (
                  <div className='story-tags'>
                    <span className='storietaglabel'>Tags: </span>
                    {story.tags.map((tag) => (
                      <Link
                        to={`/stories`}
                        key={`${tag._id}`}
                        className='topic'
                      >
                        <span>{tag.name}</span>
                      </Link>
                    ))}
                  </div>
                )}
                {userLoggedIn && userLoggedIn.isSubscribed && (
                  <>
                    <h2>Ideas</h2>
                    <div className='storychaptersection'>
                      {story.episodes.map((episode, index) => (
                        <div key={episode._id}>
                          {!story.isPaid && !userLoggedIn.isSubscribed ? (
                            <Link
                              to={`/app/en/plot/free/${episode._id}/${story._id}`}
                            >
                              <Row key={episode._id} className='chaptersection'>
                                <Col xs={12} className='chapter-title'>
                                  {'#' + (index + 1) + ' ' + episode.title}
                                  <br />
                                </Col>
                              </Row>
                            </Link>
                          ) : (
                            <Link to={`/app/en/plot/${episode._id}`}>
                              <Row key={episode._id} className='chaptersection'>
                                <Col xs={12} className='chapter-title'>
                                  {'#' + (index + 1) + ' ' + episode.title}
                                  <br />
                                </Col>
                              </Row>
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <SocialShare story={story}></SocialShare>
                <div>
                  {typeof story.category === 'string' ? (
                    <>{getCategory(story.category)}</>
                  ) : (
                    <>
                      <h2 className=''>Related Topics</h2>
                      {story.category.map(
                        (cat) =>
                          cat &&
                          cat.name && (
                            <Link
                              to={`/app/en/category/${convertToSlug2(
                                cat.name
                              )}`}
                            >
                              <span key={`${cat._id}`} className='topic'>
                                <span>{cat.name}</span>
                              </span>
                            </Link>
                          )
                      )}
                    </>
                  )}
                </div>
              </>
            </Col>
          </Row>
          {userLoggedIn && userLoggedIn.isAdmin && (
            <Container>
              <Row>
                <Link
                  to={`/write/story/${
                    story.episodes.length > 0
                      ? story.episodes[0]._id
                      : 'nullEpisode'
                  }/${story._id}`}
                  className='newbuttons'
                >
                  Edit
                </Link>
              </Row>
            </Container>
          )}
          <Container>
            <h1>Related Nuggets</h1>
            <Row>
              {similarStories.map((story) => (
                <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                  <Route
                    render={({ history }) => (
                      <AppStory history={history} story={story} />
                    )}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      )}
    </>
  )
}
{
}

export default AppPlotViewScreen
