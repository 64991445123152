import React, { useEffect } from 'react'
import { Button, Row, Form, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { commentEpisode, listCommentEpisode } from '../actions/episodeActions'
import Loader from './Loader'
import { TextField } from '@mui/material'

const Comments = ({ episode }) => {
  const dispatch = useDispatch()
  const commentEpisodeRed = useSelector((state) => state.commentEpisode)
  const { suceess } = commentEpisodeRed

  const listCommentEpisodeRed = useSelector((state) => state.listCommentEpisode)
  const {
    comments,
    loading: commentLoading,
    suceess: commentSuccess,
  } = listCommentEpisodeRed

  const [comment, setComment] = React.useState('')
  const commentSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(
      commentEpisode({ comment, episode: episode._id, story: episode.story })
    )
    setComment('')
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!commentSuccess) {
      dispatch(listCommentEpisode(episode._id))
    }
  }, [commentSuccess, episode, dispatch])
  return (
    <Container style={{ marginTop: '25px' }}>
      <Row>
        <Form onSubmit={commentSubmitHandler}>
          <Form.Group className='mb-3' controlId='comment'>
            <TextField
              id='outlined-basic'
              label='thoughts on this chapter'
              variant='outlined'
              required
              type='text'
              autoComplete='off'
              value={comment || ''}
              onChange={(e) => setComment(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Form.Group>
          <Button className='commentbutton' type='submit'>
            Comment
          </Button>
        </Form>
        {suceess && <div className='red-text'>Comment Added</div>}
      </Row>
      <Row style={{ padding: '10px' }}>
        {commentLoading ? (
          <Loader></Loader>
        ) : (
          comments.map((comment) => (
            <div className='storycomments' key={comment._id}>
              <strong>{comment.user.name}</strong> says {comment.comment}
            </div>
          ))
        )}
      </Row>
    </Container>
  )
}

export default Comments
