import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Row, Col, Card, ListGroupItem, Container } from 'react-bootstrap'
const SearchBox = ({ history }) => {
  const [keywords, setKeywords] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keywords.trim()) {
      history.push(`/app/en/search/${keywords}`)
    } else {
      history.push('/')
    }
  }
  return (
    <Container>
      <Row>
        <Container>
          <Form onSubmit={submitHandler} inline>
            <Row className='text-center'style={{marginTop:'40px'}}>
              <h2>Search Ideas from Book</h2>
              <Col xs={12}>
                <div>
                  <div className='search-bar-lp'>
                    <Form.Control
                      type='text'
                      name='q'
                      onChange={(e) => setKeywords(e.target.value)}
                      placeholder='Book Title, Key Ideas, Author, etc.'
                      className='mr-sm-2 ml-sm-5 searchbar'
                    ></Form.Control>
                  </div>
                  <div>
                    <Button type='submit' className='search-button-mobile'>
                      <i className='fas fa-search'></i> Search
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </Row>
    </Container>
  )
}

export default SearchBox
