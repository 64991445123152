export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCESS = 'USER_LOGIN_SUCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCESS = 'USER_REGISTER_SUCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCESS = 'USER_DETAILS_SUCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCESS = 'USER_UPDATE_PROFILE_SUCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCESS = 'USER_LIST_SUCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCESS = 'USER_DELETE_SUCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCESS = 'USER_UPDATE_SUCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_ADDRESS_REQUEST = 'USER_ADDRESS_REQUEST'
export const USER_ADDRESS_SUCESS = 'USER_ADDRESS_SUCESS'
export const USER_ADDRESS_FAIL = 'USER_ADDRESS_FAIL'

export const USER_ADDRESS_DELETE_REQUEST = 'USER_ADDRESS_DELETE_REQUEST'
export const USER_ADDRESS_DELETE_SUCESS = 'USER_ADDRESS_DELETE_SUCESS'
export const USER_ADDRESS_DELETE_FAIL = 'USER_ADDRESS_DELETE_FAIL'

export const USER_OTP_VERIFY_REQUEST = 'USER_OTP_VERIFY_REQUEST'
export const USER_OTP_VERIFY_SUCESS = 'USER_OTP_VERIFY_SUCESS'
export const USER_OTP_VERIFY_FAIL = 'USER_OTP_VERIFY_FAIL'
export const USER_OTP_VERIFY_RESET = 'USER_OTP_VERIFY_RESET'

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST'
export const USER_VERIFY_SUCESS = 'USER_VERIFY_SUCESS'
export const USER_VERIFY_FAIL = 'USER_OVERIFY_FAIL'
export const USER_VERIFY_RESET = 'USER_VERIFY_RESET'

export const USER_GUEST_CREATE_REQUEST = 'USER_GUEST_CREATE_REQUEST'
export const USER_GUEST_CREATE_SUCESS = 'USER_GUEST_CREATE_SUCESS'
export const USER_GUEST_CREATE_FAIL = 'USER_GUEST_CREATE_FAIL'

export const USER_DESC_REQUEST = 'USER_DESC_REQUEST'
export const USER_DESC_SUCESS = 'USER_DESC_SUCESS'
export const USER_DESC_FAIL = 'USER_DESC_FAIL'
export const USER_DESC_RESET = 'USER_DESC_RESET'

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST'
export const USER_FORGOT_PASSWORD_SUCESS = 'USER_FORGOT_PASSWORD_SUCESS'
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCESS = 'USER_RESET_PASSWORD_SUCESS'
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCESS = 'USER_PROFILE_SUCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'

export const USER_REGISTER_LP_REQUEST = 'USER_REGISTER_LP_REQUEST'
export const USER_REGISTER_LP_SUCESS = 'USER_REGISTER_LP_SUCESS'
export const USER_REGISTER_LP_FAIL = 'USER_REGISTER_LP_FAIL'

export const USER_ADDRESS_LP_REQUEST = 'USER_ADDRESS_LP_REQUEST'
export const USER_ADDRESS_LP_SUCESS = 'USER_ADDRESS_LP_SUCESS'
export const USER_ADDRESS_LP_FAIL = 'USER_ADDRESS_LP_FAIL'

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_SUCESS = 'OTP_VERIFY_SUCESS'
export const OTP_VERIFY_FAIL = 'OTP_VERIFY_FAIL'
export const OTP_VERIFY_RESET = 'OTP_VERIFY_RESET'

export const USER_ONBOARD_REQUEST = 'USER_ONBOARD_REQUEST'
export const USER_ONBOARD_SUCESS = 'USER_ONBOARD_SUCESS'
export const USER_ONBOARD_FAIL = 'USER_ONBOARD_FAIL'
