import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { getMyStoryReading } from '../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Story from '../../components/Story'

const ReadingsScreen = ({ history }) => {
  const dispatch = useDispatch()

  const [title, setTitle] = useState('')
  const [content, SetContent] = useState('')
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const myReadingStory = useSelector((state) => state.myReadingStory)
  const { stories, loading, error, suceess } = myReadingStory

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    } else if (!userLoggedIn.isSubscribed) {
      history.push(`/app/profile/billing`)
    }
    dispatch(getMyStoryReading())
  }, [dispatch, history, userLoggedIn])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <h1>Readings</h1>
          <Row>
            {stories.map((story) => (
              <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                <Route
                  render={({ history }) => (
                    <Story history={history} story={story} />
                  )}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  )
}

export default ReadingsScreen
