import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { userAddressList, deleteUserAddress } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import Paginate from '../components/Paginate'
const UserAddressListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const cancelOrders = match.params.cancel

  const userAddressListData = useSelector((state) => state.userAddressList)
  const { addressList, loading, success, error } = userAddressListData

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(userAddressList())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, cancelOrders, match])
  const deleteUserAddressById = (addressId) => {
    dispatch(deleteUserAddress(addressId))
  }
  return (
    <Container>
      <h1>Addresses</h1>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            {addressList.length == 0 && (
              <Message>No Addresses have been added to your Profile!</Message>
            )}
            {addressList.map((address) => (
              <Col xs={12} md={4} key={address._id}>
                <Card className=' rounded py-1' key={address._id}>
                  <Card.Body>
                    <Card.Text className='text-pointer'>
                      <span>
                        {address.name}
                        <br />
                        {address.address},{address.address1}
                        <br />
                        {address.city},{address.state},<br />
                        {address.zipcode}
                        <br />
                        {address.phone}
                      </span>
                    </Card.Text>
                    <Button
                      className='btn-sm'
                      variant='dark'
                      onClick={() => deleteUserAddressById(address._id)}
                    >
                      <i className='fas fa-trash-alt'></i> Remove
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  )
}

export default UserAddressListScreen
