import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUser } from '../actions/userActions'
import Paginate from '../components/Paginate'
import { getBookFairList } from '../actions/bookFairActions'
const BookFairListScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const listBookFair = useSelector((state) => state.listBookFair)
  const { loading, error, success, bookfairs } = listBookFair

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getBookFairList())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  const deleteHandler = (id) => {
    if (window.confirm('are you Sure')) {
      dispatch(deleteUser(id))
    }
  }
  return (
    <Container>
      <h1>Book Fair List</h1>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row className='align-items-center'>
            <Col className='text-right'>
              <Link className='my-3' to='/admin/bookfairNew'>
                <i className='fas fas-plus'>Create Book Fair</i>
              </Link>
            </Col>
          </Row>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {bookfairs.map((bookfair) => (
                <tr key={bookfair._id}>
                  <td>{bookfair.name}</td>
                  <td>
                    <small>
                      {bookfair.startDate.substring(0, 10)}
                      <br />
                      {bookfair.endDate.substring(19, 11)}
                    </small>
                  </td>
                  <td>{bookfair.address}</td>

                  <td>
                    <LinkContainer
                      to={`/admin/bookfair/intrest/${bookfair._id}`}
                    >
                      <Button className='btn-sm' variant='light'>
                        Intrest
                      </Button>
                    </LinkContainer>
                    <LinkContainer to={`/admin/bookfair/${bookfair._id}`}>
                      <Button className='btn-sm' variant='light'>
                        View
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default BookFairListScreen
