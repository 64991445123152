import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Container,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordUser } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import SubNav from '../components/SubNav'
import { TextField } from '@mui/material'

const PasswordResetScreen = ({ location, history, match }) => {
  const uid = match.params.id || null
  const rid = match.params.reset || null
  const [password, setPassword] = useState('')
  const [rpassword, setRpassword] = useState('')
  const [showResetPassMessage, setShowResetPassMessage] = useState('')

  const [passwordMessage, setPasswordMessage] = useState('')
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const resetPasswordRed = useSelector((state) => state.resetPassword)
  const {
    loading: rploading,
    error: rperror,
    result,
    success: rpsuccess,
  } = resetPasswordRed

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userInfo) {
      history.push(redirect)
    }
    if (rpsuccess) {
      if (result.error && result.errorMessage) {
        setShowResetPassMessage(result.errorMessage)
      } else {
        setShowResetPassMessage('Password has been updated')
        setPassword('')
        setRpassword('')
      }
    }
  }, [history, userInfo, redirect, rpsuccess, showResetPassMessage])

  const rpsubmitHandler = (e) => {
    setPasswordMessage('')
    e.preventDefault()
    if (password.length < 6) {
      setPasswordMessage('Password is too short')
    } else {
      if (password == rpassword) {
        dispatch(resetPasswordUser(uid, rid, password))
      } else {
        setPasswordMessage('Password does not match')
      }
    }
  }

  return (
    <Container>
      <SubNav></SubNav>
      <Row className='login-register'>
        <Col md={12}>
          <div style={{ padding: '20px' }} className='login-box'>
            <FormContainer>
              <h1>Reset Your Password</h1>
              {showResetPassMessage && (
                <span className='registerErrorMessage'>
                  {showResetPassMessage} <Link to={'/login'}>Login</Link>
                </span>
              )}
              {passwordMessage && (
                <div className='registerErrorMessage'>{passwordMessage}</div>
              )}
              {/* {loading && <Loader></Loader>} */}
              <Form onSubmit={rpsubmitHandler}>
                <Form.Group controlId='password'>
                  <TextField
                    id='outlined-basic'
                    label='Password'
                    variant='outlined'
                    required
                    type='Password'
                    autoComplete='off'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                </Form.Group>
                <Form.Group controlId='rpassword'>
                  <TextField
                    id='outlined-basic'
                    label='Re Enter Password'
                    variant='outlined'
                    required
                    type='Password'
                    autoComplete='off'
                    value={rpassword}
                    onChange={(e) => setRpassword(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                </Form.Group>
                <Button
                  type='submit'
                  varaint='primary'
                  style={{ float: 'right' }}
                >
                  Reset Password
                </Button>
                <div>
                  <p className='text-muted'>
                    <br />
                    By continuing, you agree to NexNuggets{' '}
                    <Link to='/pages/terms-and-condition'>
                      Terms of Condition
                    </Link>{' '}
                    & <Link to='/pages/privacy-policy'>User Policy</Link>.
                  </p>
                </div>
              </Form>
              <br />
            </FormContainer>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordResetScreen
