import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {
  listStory,
  listStoryByCat,
  storyListByGoal,
} from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
} from 'react-bootstrap'
import { makeTitle } from '../../../helpers/helpers'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../../../components/Loader'
import AppStory from '../../../components/AppStory'
import AppPlotListPaginate from '../../../components/litplots/AppPlotListPaginate'
const AppGoalListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const goal = match.params.goal || ''
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const { stories, loading, error, suceess, pages, page } = useSelector(
    (state) => state.storyListByGoal
  )
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    }
    dispatch(storyListByGoal(makeTitle(goal), pageNumber, 10))
  }, [dispatch, pageNumber, goal, history])
  return (
    <>
      <Container>
        {loading && !suceess ? (
          <Loader></Loader>
        ) : (
          <Container>
            <h1>{makeTitle(goal)} Books</h1>
            <Row>
              {stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <AppStory history={history} story={story} />
                      )}
                    />
                  </Col>
                ))}
              <AppPlotListPaginate
                pages={pages}
                page={page}
                category={match.params.goal}
                url={'/app/en/goal'}
              ></AppPlotListPaginate>
            </Row>
          </Container>
        )}
      </Container>
    </>
  )
}
{
}

export default AppGoalListScreen
