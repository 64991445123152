import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { getAllStories, deleteStory } from '../../actions/storyActions'
import { deleteEpisode } from '../../actions/episodeActions'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Story from '../../components/Story'
import { FaBookReader, FaStar, FaList, FaClock } from 'react-icons/fa'
import { convertToSlug2, getCategory, storyStatus } from '../../helpers/helpers'
import PaginateStory from '../../components/litplots/PaginateStory'

const AllStoryListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const pageNumber = match.params.pageNumber || 1
  const [title, setTitle] = useState('')
  const [content, SetContent] = useState('')
  const listMyStory = useSelector((state) => state.listMyStory)
  const { stories, loading, error, suceess, pages, page } = listMyStory
  const deleteStoryRed = useSelector((state) => state.deleteStory)

  const {
    story: deletedStory,
    loading: deleteLoading,
    error: deleteError,
    suceess: deleteSuccess,
  } = deleteStoryRed
  const deleteEpisodeRed = useSelector((state) => state.deleteEpisode)

  const {
    story: deletedEpisode,
    loading: deleteEpisodeLoading,
    error: deleteEpisodeError,
    suceess: deleteEpisodeSuccess,
  } = deleteEpisodeRed
  const deleteStoryHandler = (e, id) => {
    e.preventDefault()
    dispatch(deleteStory(id))
  }
  const deleteEpisodeHandler = (e, id) => {
    e.preventDefault()
    dispatch(deleteEpisode(id))
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getAllStories(pageNumber))
  }, [
    dispatch,
    deleteSuccess,
    deletedStory,
    deletedEpisode,
    deleteEpisodeSuccess,
    pageNumber,
  ])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <Row>
            <Col xs={6}>
              <h1>My Stories</h1>
            </Col>
            <Col xs={6}>
              <Link to={`/create/episode/`} className='newbuttons float-right'>
                New Story
              </Link>
            </Col>
          </Row>
          <Row>
            {stories.map((story) => (
              <Col key={story._id} sm={12} md={6} lg={6} xs={12}>
                <Row key={story._id} style={{ padding: '6px' }}>
                  <Col xs={4} className='story-cover'>
                    <Link
                      to={`/app/en/book/${convertToSlug2(story.title)}/${
                        story._id
                      }`}
                    >
                      <amp-img
                        src={story.coverImage}
                        layout='responsive'
                        height='180'
                        width='100'
                      />
                    </Link>
                  </Col>
                  <Col xs={8} className='story-cover'>
                    <h6>
                      <Link
                        to={`/app/en/book/${convertToSlug2(story.title)}/${
                          story._id
                        }`}
                      >
                        {!story.title ? 'Untitled Story' : story.title}
                      </Link>
                    </h6>
                    <div>by {story.user && story.user.name}</div>
                    {/* <div className='storystatslist'>
                      <FaBookReader></FaBookReader> 33 <FaStar></FaStar> 55{' '}
                      <FaList></FaList> {story.episodes.length}
                    </div> */}
                    <div>{getCategory(story.category)}</div>
                    <div>{storyStatus(story.status)}</div>
                    <div className='storyeditoptions'>
                      <Link
                        to={`/write/story/${
                          story.episodes.length > 0
                            ? story.episodes[0]._id
                            : 'nullEpisode'
                        }/${story._id}`}
                        className='newbuttons'
                      >
                        Edit
                      </Link>
                      <Link
                        to={``}
                        onClick={(e) => deleteStoryHandler(e, story._id)}
                        className='newbuttons'
                      >
                        Delete
                      </Link>
                      <Link
                        to={`/create/episode/${story._id}`}
                        className='newbuttons'
                      >
                        Add Chapter
                      </Link>
                    </div>
                    <div className='storychaptersection'>
                      <h6>Chapters</h6>
                      {story.episodes.map((episode, index) => (
                        <>
                          <div key={episode._id} className='chaptersection'>
                            {!episode.title
                              ? 'Untitled Chapter'
                              : episode.title}{' '}
                            <br />
                            {episode.duration}{' '}
                            <Link
                              to={`/write/episode/${story.episodes[index]._id}/${story._id}`}
                              className='newbuttons'
                            >
                              Edit
                            </Link>
                            <Link
                              to={``}
                              onClick={(e) =>
                                deleteEpisodeHandler(
                                  e,
                                  story.episodes[index]._id
                                )
                              }
                              className='newbuttons'
                            >
                              Delete
                            </Link>
                          </div>
                        </>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <PaginateStory pages={pages} page={page}></PaginateStory>
        </>
      )}
    </Container>
  )
}

export default AllStoryListScreen
