import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'

const UserSettingScreen = ({ location, history, getState }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!userInfo.name) {
        dispatch(getUserDetails('profile'))
      } else {
        setName(userInfo.name)
        setEmail(userInfo.email)
        setPhone(userInfo.phone)
      }
    }
  }, [dispatch, history, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Password Do not Match')
    } else {
      dispatch(updateUserProfile({ id: userUpdateProfile._id, name, email }))
    }
  }
  return (
    <Container>
      <Row>
        <Col>
          <h4>Setting</h4>
        </Col>
        <Col>
          <Link to='/profile' className='btn btn-light float-right'>
            Go back
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {message && <Message variant='danger'>{message}</Message>}
          {error && <Message variant='danger'>{error}</Message>}
          {success && <Message variant='success'>UPDATED sUCCESSFULLY</Message>}
          {loading && <Loader></Loader>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                readOnly
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
              ></Form.Control>
            </Form.Group>
            {/* <Form.Group controlId='password'>
              <Form.Label>Phone</Form.Label>
              <Form.Control type='text' readOnly value={phone}></Form.Control>
            </Form.Group> */}
            {/* <Button type='submit' varaint='primary'>
              Update
            </Button> */}
          </Form>
        </Col>
        <Col md={6}>
          <div>
            <small>Add or Remove Addresses</small>
          </div>
          <Link to='/profile/addresses' className='btn btn-primary'>
            Addresses
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default UserSettingScreen
