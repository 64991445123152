import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Card, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import { SHIPPING_STATE } from '../constants/orderConstants'
import OrderSummary from '../components/OrderSummary'
import { Link } from 'react-router-dom'
import { userAddressList, deleteUserAddress } from '../actions/userActions'
import Loader from '../components/Loader'

const AddressSelectionScreen = ({ history }) => {
  const firstRender = useRef(true)

  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { shippingAddress, loading, cartItems, cartSuccess } = cart

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userAddressListData = useSelector((state) => state.userAddressList)
  const {
    addressList,
    loading: userAddressListLoading,
    success: userAddressListSucess,
  } = userAddressListData
  const deleteUserAddressData = useSelector((state) => state.userAddressDelete)
  const { success: userAddressDeleteSucess } = deleteUserAddressData
  let cEmail = ' '
  if (userInfo) {
    cEmail = userInfo.email
  } else {
    cEmail = shippingAddress ? shippingAddress.email : ' '
  }

  const [email, setEmail] = useState(cEmail)
  const [name, setName] = useState(shippingAddress.name)
  const [address, setAddress] = useState(shippingAddress.address)
  const [address2, setAddress2] = useState(shippingAddress.address2)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [state, setState] = useState(shippingAddress.state)
  const [disable, setDisabled] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)

  const [addNew, setAddNew] = useState(false)

  // const [country, setCountry] = useState(shippingAddress.country)

  let country = 'India'

  useEffect(() => {
    if (!userInfo) {
      history.push('/login?redirect=/checkout/basic-information')
    }
    window.scrollTo(0, 0)
    dispatch(userAddressList())
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    setDisabled(formValidation())
  }, [dispatch, loading, userAddressDeleteSucess, addNew])

  const formValidation = () => {
    if (email.length > 4) {
      setEmailError(null)
      return false
    }
  }
  const deleteUserAddressById = (addressId) => {
    dispatch(deleteUserAddress(addressId))
  }

  const selectShippingAddressHandler = (address) => {
    dispatch(
      saveShippingAddress(
        {
          name: address.name,
          address: address.address,
          address2: address.address2,
          city: address.city,
          postalCode: address.zipcode,
          state: address.state,
          country: 'IN',
          phone: address.phone,
          email: userInfo.email,
          guest: userInfo ? false : true,
        },
        true
      )
    )

    setTimeout(() => {
      history.push('/checkout/shipping')
    }, 1000)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      dispatch(
        saveShippingAddress({
          name,
          address,
          address2,
          city,
          postalCode,
          state,
          country,
          phone: phone,
          email,
          guest: userInfo ? false : true,
        })
      )
      setTimeout(() => {
        history.push('/checkout/shipping')
      }, 1000)
    } else {
      setPhoneError('Enter Valid Phone Number')
    }
  }
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
  }
  return (
    <Container>
      <FormContainer>
        {/* <OrderSummary></OrderSummary> */}
        {/* <CheckoutSteps step1 /> */}
        <h1 className='cart-header'>Shipping Address</h1>
        <br />
        {!userInfo || addNew ? (
          <>
            <Form onSubmit={submitHandler} className='cart-form'>
              <Form.Group controlId='guestemail'>
                <Form.Text className='text-muted'>
                  {emailError && <span>{emailError}</span>}
                </Form.Text>
                {addNew ? (
                  <>
                    <Form.Control
                      type='hidden'
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Enter Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <small>Already have an Account?</small>{' '}
                    <Link to='/login'>Login</Link>
                  </>
                )}
              </Form.Group>
              <Form.Group controlId='name'>
                <Form.Control
                  type='text'
                  placeholder='Full Name'
                  required
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='address'>
                <Form.Control
                  type='text'
                  placeholder='Enter Address'
                  required
                  value={address || ''}
                  onChange={(e) => setAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='address2'>
                <Form.Control
                  type='text'
                  placeholder='Apartment, Suite, etc (Optional)'
                  value={address2 || ''}
                  onChange={(e) => setAddress2(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='city'>
                <Form.Control
                  type='text'
                  placeholder='City'
                  required
                  value={city || ''}
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='postalCode'>
                <Form.Control
                  type='text'
                  placeholder='Zip Code'
                  required
                  value={postalCode || ''}
                  onChange={(e) => setPostalCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='state'>
                <Form.Control
                  as='select'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                >
                  <option key='Select your State' value={''}>
                    State
                  </option>
                  {SHIPPING_STATE.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId='phone'>
                <Form.Text className='text-muted'>
                  {phoneError && <span>{phoneError}</span>}
                </Form.Text>
                <Form.Control
                  type='tel'
                  placeholder='Phone Number'
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br />
              <Button
                type='submit'
                variant='primary'
                disabled={disable}
                className='float-right'
              >
                Continue
              </Button>
            </Form>
            <br />
          </>
        ) : (
          <>
            {!userAddressListSucess ? (
              <Loader></Loader>
            ) : (
              <>
                <Row>
                  {addressList.length > 0 && (
                    <small>Click on address to select</small>
                  )}
                  {addressList.map((address) => (
                    <Col xs={12} key={address._id}>
                      <Card className=' rounded py-1' key={address._id}>
                        <Card.Body>
                          <Card.Text
                            onClick={() =>
                              selectShippingAddressHandler(address)
                            }
                            className='text-pointer'
                          >
                            <span>
                              {address.name}
                              <br />
                              {address.address},{address.address1}
                              <br />
                              {address.city},{address.state},<br />
                              {address.zipcode}
                              <br />
                              {address.phone}
                            </span>
                          </Card.Text>
                          <Button
                            variant='danger'
                            className='btn-sm mr-auto float-right'
                            onClick={() => deleteUserAddressById(address._id)}
                          >
                            <i className='fas fa-trash-alt '></i> Remove
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Button
                    type='button'
                    variant='primary'
                    className='float-right'
                    onClick={(e) => setAddNew(true)}
                  >
                    Add New Address
                  </Button>
                </Row>
              </>
            )}
          </>
        )}
      </FormContainer>
    </Container>
  )
}

export default AddressSelectionScreen
