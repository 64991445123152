import { useState, useEffect } from 'react'

const LoadingStep = ({ handleNextStep }) => {
  const [progress, setProgress] = useState(0)
  const [randomNumber, setRandomNumber] = useState(null)
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const id = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(id)
          return 100
        }
        return Math.min(oldProgress + 100 / 9, 100)
      })
    }, 500) // Update every second

    setIntervalId(id)

    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    if (progress === 100) {
      // Generate a random number between 200 and 400
      setRandomNumber(Math.floor(Math.random() * 201) + 200)
    }
  }, [progress])

  return (
    <div
      className='text-center'
      style={{
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className='progress-circle'>
        <svg
          viewBox='0 0 36 36'
          className='circular-chart'
          style={{ width: '100px', height: '100px' }}
        >
          <path
            className='circle-bg'
            d='M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831'
            fill='none'
            stroke='#eee'
            strokeWidth='4'
          />
          <path
            className='circle'
            strokeDasharray={`${progress}, 100`}
            d='M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831'
            fill='none'
            stroke='#0074D9'
            strokeWidth='4'
            style={{ transition: 'stroke-dasharray 0.5s ease 0s' }}
          />
          <text
            x='18'
            y='20.35'
            className='percentage'
            fill='#0074D9'
            fontSize='4'
            textAnchor='middle'
          >{`${Math.round(progress)}%`}</text>
        </svg>
      </div>
      <br />
      {progress === 100 && (
        <div>
          <p style={{ fontSize: '16px' }}>
            We’ve found {randomNumber} microbooks for you
          </p>
        </div>
      )}
      <br />
      <button
        className='btn btn-primary mt-3'
        onClick={handleNextStep}
        disabled={progress !== 100}
      >
        Continue
      </button>
    </div>
  )
}

export default LoadingStep
