import axios from 'axios'
import {
  CREATE_SUBSCRIPTION_FAIL,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCEES,
  LIST_SUBSCRIPTION_FAIL,
  LIST_SUBSCRIPTION_REQUEST,
  LIST_SUBSCRIPTION_SUCCEES,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCEES,
} from '../constants/subscriptionConstants'
import Cookies from 'universal-cookie'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCESS,
} from '../constants/userConstants'
import {
  ORDER_PAYMENT_CREATE_FAIL,
  ORDER_PAYMENT_CREATE_REQUEST,
  ORDER_PAYMENT_CREATE_SUCCESS,
} from '../constants/orderConstants'
const cookies = new Cookies()
export const createSubscription =
  (selectedOption) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_SUBSCRIPTION_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      var { data } = await axios.get(
        `/api/subscription/?option=${selectedOption}`,
        config
      )

      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCEES,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CREATE_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const postOrderPaymentOneTime =
  (order) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAYMENT_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()
      let config = {}
      if (userInfo) {
        config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        const { data } = await axios.post(
          `/api/subscription/onetime`,
          order,
          config
        )

        dispatch({
          type: ORDER_PAYMENT_CREATE_SUCCESS,
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: ORDER_PAYMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const updateSubscriptions = (data1) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_SUBSCRIPTION_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/subscription`, data1, config)

    dispatch({
      type: UPDATE_SUBSCRIPTION_SUCCEES,
      payload: data,
    })
    cookies.remove('_incf', {
      path: '/',
    })
    cookies.set('_incf', data.user, {
      path: '/',
      maxAge: 34550000,
    })
    dispatch({
      type: USER_LOGIN_SUCESS,
      payload: data.user,
    })
    localStorage.setItem('userInfo', JSON.stringify(data.user))
  } catch (error) {
    dispatch({
      type: UPDATE_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listSubscriptions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_SUBSCRIPTION_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/subscription/list`, config)

    dispatch({
      type: LIST_SUBSCRIPTION_SUCCEES,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LIST_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
