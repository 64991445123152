import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import FooterMenu from '../../../components/litplots/FooterMenu'

import CategoriesApp from '../../../components/CategoriesApp'
import SearchBox from '../../../components/litplots/SearchBox'
import OnBoard from '../../../components/litplots/OnBoard'
import HProductCarosel from '../../../components/HProductCarosel'
import StepFunnel from '../../../components/litplots/StepFunnel'
import { convertToSlug } from '../../../helpers/helpers'
import Collection from '../../../components/litplots/Collection'
const AppHomeScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()
  const url = `https://www.nexnuggets.com/stories/${match.params.id}`
  const [title, setTitle] = useState('')
  const [content, SetContent] = useState('')
  const [isReadMore, setIsReadMore] = useState(true)
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success: registerSuccess, userInfo } = userRegister
  const goals = [
    'Have a successful career',
    // 'Build a close-knit family',
    'Increase productivity',
    'Achieve life balance',
    'Lead a healthy lifestyle',
    'Become confident',
    'Improve sex life',
    'Develop healthy relationships',
    'Boost intelligence',
    'Reach happiness',
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!userLoggedIn) {
      history.push(`/register`)
    }

    // if (userLoggedIn && !userLoggedIn.isSubscribed) {
    //   history.push('/app/profile/billing')
    // }
  }, [dispatch, userLoggedIn, history])
  return (
    <>
      <h1 className='home-page-header'>Discover the world's best ideas</h1>
      {/* {userLoggedIn &&
        !userLoggedIn.isOnboarded &&
        !localStorage.getItem('onBorded') && <OnBoard></OnBoard>} */}

      <Container>
        <Row>
          {/* <Container className='text-center'>
            {userLoggedIn &&
            userLoggedIn.intrests &&
            userLoggedIn.intrests.length > 0 ? (
              <>
                <h1 className='home-page-header'>Your Intrests</h1>
                <div className='intrests-container'>
                  {userLoggedIn.intrests.map((intrest) => (
                    <Link
                      to={`/app/en/goal/${convertToSlug(intrest)}`}
                      className='topic-cat'
                    >
                      {intrest}
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </Container>
          <Container className='text-center'>
            <h1 className='text-center'>Goals</h1>
            {goals.map((goal) => (
              <Link
                to={`/app/en/goal/${convertToSlug(goal)}`}
                className='topic-cat'
              >
                {goal}
              </Link>
            ))}
          </Container> */}
          {/* <Container>
            <SearchBox history={history}></SearchBox>
          </Container> */}
          {/* <HProductCarosel></HProductCarosel> */}
          <Collection />
          <CategoriesApp></CategoriesApp>
          {/* <FooterMenu></FooterMenu> */}
        </Row>
      </Container>
    </>
  )
}
{
}

export default AppHomeScreen
