import React, { useState, useEffect } from 'react'
import { Form, Button, Col, Row, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import {
  getCartDetailsFromStorage,
  saveShippingMethodToStorage,
} from '../actions/cartActions'
import { Link } from 'react-router-dom'
import CheckoutSteps from '../components/CheckoutSteps'
import OrderSummary from '../components/OrderSummary'
import { FaTruck } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import Razorpay from 'razorpay'
import Loader from '../components/Loader'
import { addDiscountCode } from '../actions/discountCodeActions'
import Cookies from 'universal-cookie'

import {
  createOrder,
  payOrder,
  postOrderPayment,
} from '../actions/orderActions'
import {
  saveDiscountPrice,
  saveDiscountCode,
  removeDiscountFromCart,
} from '../actions/cartActions'
const cookies = new Cookies()
function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}
const ShippingScreen = ({ history }) => {
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const dispatch = useDispatch()
  const [isBox, setIsBox] = useState(false) 
  const [codCost, setCodCost] = useState('')
  const [payButtonClick, setPayButtonClick] = useState(false)
  const [payS, SetPayS] = useState(true)
  const [processingOrder, setProcessingOrder] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [isCodeApplied, setIsCodeApplied] = useState(false)
  const [isCodeA, setIsCodeA] = useState(false)
  const [displayCartError, setDisplayCartError] = useState(false)
  const [couponCodeError, setCouponCodeError] = useState(false)
  const [isCodeB, setIsCodeB] = useState(false)

  const userCart = useSelector((state) => state.userCart)
  const { cart, loading: cartLoading, success: cartSuccess } = userCart

  const [paymentMethod, setPaymentMethod] = useState('')
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate

  const discountCodeGet = useSelector((state) => state.discountCodeGet)
  const {
    discountCode,
    success: discountCodeSuccess,
    error: discountCodeError,
    loding: discountCodeLoading,
  } = discountCodeGet

  const removedDiscount = useSelector((state) => state.removeCartDiscount)
  const { discountCode: updatedCart, success: discountRemoveSuccess } =
    removedDiscount

  const setCartPaymentMethod = useSelector(
    (state) => state.setCartPaymentMethod
  )
  const {
    discountCode: updatedCartPaymentMethod,
    success: paymentmethodSuccess,
  } = setCartPaymentMethod

  const paymentROrder = useSelector((state) => state.orderPayRazor)
  const {
    orderPayDetails,
    success: paySuccess,
    error: payError,
    loading: payLoading,
  } = paymentROrder
  var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  var date = new Date()
  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  const submitHandler = (e) => {
    e.preventDefault()
    setPayButtonClick(false)
    setProcessingOrder(true)
    setPaymentMethod(e.target.value)
    if (e.target.value == 'cod') {
      const paymentResult = {
        id: uuidv4(),
        rid: null,
        signature: null,
      }
      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: 'cod',
          shippingMethod: 'Standard',
          itemPrice: cart.cartPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
          codPrice: 49,
          discountPrice: cart.discountPrice,
          isPaid: false,
          paidAt: null,
          paymentResult: paymentResult,
          cartId: cart._id,
        })
      )
      // setPayButtonClick(true)
    } else if (e.target.value == 'online') {
      SetPayS(true)
      dispatch(
        postOrderPayment({
          id: uuidv4(),
        })
      )
      setPayButtonClick(true)
    }
  }
  if (cart) {
    var options = {
      key: 'rzp_live_04AG18531Cjx9B',
      amount: cart.totalPrice,
      currency: 'INR',
      name: userInfo ? userInfo.name : cart.shippingAddress.name,
      description: '99bookscart',
      // "order_id":orderPayDetails.id,
      image:
        'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
      handler: function (response) {
        const paymentResult = {
          id: response.razorpay_payment_id,
          rid: response.razorpay_order_id,
          signature: response.razorpay_signature,
        }
        dispatch(
          createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            paymentMethod: 'online',
            shippingMethod: 'Standard',
            itemPrice: cart.cartPrice,
            shippingPrice: cart.shippingPrice,
            taxPrice: cart.taxPrice,
            totalPrice: cart.totalPrice,
            codPrice: 0,
            isPaid: response.razorpay_order_id ? true : false,
            paidAt: response.razorpay_order_id ? Date.now() : '',
            paymentResult: paymentResult,
            cartId: cart._id,
          })
        )
        timeout(1000)
      },
      prefill: {
        name: userInfo ? userInfo.name : cart.shippingAddress.name,
        email: userInfo ? userInfo.email : cart.shippingAddress.email,
        contact: userInfo ? userInfo.phone : cart.shippingAddress.phone,
      },
      theme: {
        color: '#4267b2',
      },
    }
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
    dispatch(getCartDetailsFromStorage())

    if (paySuccess && payButtonClick && payS) {
      setProcessingOrder(false)
      SetPayS(false)
      options['order_id'] = orderPayDetails.id
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
    // if (orderSuccess && order.codPrice > 0) {
    //   cookies.remove('_cid', {
    //     path: '/',
    //   })
    //   setProcessingOrder(false)
    //   dispatch(payOrder(order._id))
    // }

    // if (orderSuccess && order && order.codPrice == 0) {
    //   cookies.remove('_cid', {
    //     path: '/',
    //   })
    //   dispatch(payOrder(order._id))
    // }
    if (order && orderSuccess) {
      cookies.remove('_cid', {
        path: '/',
      })
      if (order.codPrice > 0) {
        setProcessingOrder(false)
        history.push(`/order/${order._id}/orderconfirm/cod`)
      } else {
        history.push(
          `/order/${order._id}/orderconfirm/${order.paymentResult.rid}`
        )
      }
    }
    if (cart) {
      if (discountCodeSuccess && discountCode) {
        if (discountCode.error) {
          setCouponCodeError('Not a Valid Code')
        } else {
          setCouponCodeError(' ')
          setIsCodeA(true)
        }
      }
      if (discountRemoveSuccess && updatedCart) {
        setIsCodeA(false)
      }
      if (paymentmethodSuccess && updatedCartPaymentMethod) {
        setIsCodeB(true)
      }
    }
  }, [
    history,
    orderSuccess,
    paySuccess,
    order,
    discountCodeSuccess,
    discountCode,
    discountRemoveSuccess,
    isCodeA,
    updatedCart,
    paymentmethodSuccess,
    updatedCartPaymentMethod,
    ,
  ])
  const couponCodeHandler = (e) => {
    e.preventDefault()
    dispatch(saveDiscountCode(couponCode))
  }
  const removeDiscount = (e) => {
    e.preventDefault()
    dispatch(removeDiscountFromCart())
  }

  const setPaymentMethodToStorage = (val) => {
    setPaymentMethod(val)
    dispatch(saveShippingMethodToStorage(val))
  }
  return (
    <>
      <Container>
        {/* <CheckoutSteps step1 step2 step3 /> */}
        <h1 className='cart-header'>Payment Method</h1>
        {cartLoading && !cartSuccess ? (
          <Loader></Loader>
        ) : (
          <>
            <OrderSummary cart={cart}></OrderSummary>
            <small>Knowledge and inspiration that never depreciate.</small>
            <Row className='checkout-shipping-address'>
              <Col xs={12} md={6}>
                <div style={{ float: 'left' }}>
                  <h6>Shipping Address</h6>
                  <p>
                    {cart.shippingAddress && cart.shippingAddress.name}
                    <br />
                    {cart.shippingAddress && cart.shippingAddress.address},
                    {cart.shippingAddress && cart.shippingAddress.address2},
                    <br />
                    {cart.shippingAddress && cart.shippingAddress.city},
                    {cart.shippingAddress && cart.shippingAddress.state},
                    {cart.shippingAddress && cart.shippingAddress.postalCode}
                    <br />
                    {cart.shippingAddress && cart.shippingAddress.phone}{' '}
                  </p>
                  <p className='delivery-date'>
                    <FaTruck></FaTruck> Delivery by{' '}
                    {days[deliveryDate.getDay()]},{deliveryDate.getDate()}{' '}
                    {months[deliveryDate.getMonth()]}
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <>
                  <div style={{ color: '#000' }}>
                    <h6>Total</h6>
                    <div>Cart Total: &#8377;{addDecimals(cart.cartPrice)}</div>
                    <div>
                      Shipping Charges: &#8377;{addDecimals(cart.shippingPrice)}
                      <br />
                      <small style={{ color: '#000', textTransform: 'none' }}>
                        Each Bookscart box is 6kg!
                      </small>
                    </div>
                    {cart.codPrice > 0 && (
                      <>
                        <div>
                          COD Charges: &#8377;{addDecimals(cart.codPrice)}
                        </div>
                      </>
                    )}{' '}
                    {cart.discountPrice > 0 && (
                      <>
                        <div>Discount: ₹ {addDecimals(cart.discountPrice)}</div>
                        <Link to='' onClick={removeDiscount}>
                          Remove Discount
                        </Link>
                      </>
                    )}
                    <div>
                      <span style={{ fontSize: '16px' }}>
                        Total: &#8377;
                        {cart.totalPrice}
                      </span>
                    </div>
                    {!isCodeA && cart.discountPrice == 0 && (
                      <Form onSubmit={couponCodeHandler}>
                        {couponCodeError && couponCodeError}
                        {discountCodeSuccess && discountCode.name}
                        <Row>
                          <Col>
                            <Form.Group controlId='couponCode'>
                              <Form.Control
                                type='text'
                                placeholder='Coupon Code'
                                required
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Button
                              type='submit'
                              varaint='primary'
                              className='couponbutton'
                              disabled={isCodeApplied}
                            >
                              Apply
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </div>
                </>
              </Col>
            </Row>

            <Container>
              <Row>
                <FormContainer>
                  <Form onSubmit={submitHandler} className='cart-form'>
                    <Form.Group>
                      <small style={{ textAlign: 'center' }}>
                        Pay Online for Contactless Delivery and to avoid COD
                        Charges{' '}
                      </small>
                      <br />
                      {processingOrder && <Loader></Loader>}
                      {/* <Row>
                        <Col xs={1}>
                          <Form.Check
                            type='radio'
                            id='Online'
                            name='paymentMethod'
                            value='online'
                            checked={cart.codPrice == 0 ? true : false}
                            onChange={(e) =>
                              setPaymentMethodToStorage(e.target.value)
                            }
                          ></Form.Check>
                        </Col>
                        <Col xs={11}>
                          <Form.Label>
                            <i className='fas fa-credit-card'></i> Online
                            Payment (FREE)
                          </Form.Label>
                        </Col>
                      </Row> */}
                      <Row>
                        <Button
                          className='ldp-variant2'
                          id='Online'
                          name='paymentMethod'
                          value='online'
                          checked='checked'
                          onClick={(e) => submitHandler(e)}
                        >
                          <i className='fas fa-credit-card'></i> Online Payment
                          (FREE & Secure)
                        </Button>
                      </Row>
                      {/* <Row>
                        <Col xs={1}>
                          <Form.Check
                            type='radio'
                            id='COD'
                            name='paymentMethod'
                            value='cod'
                            checked={cart.codPrice > 0 ? true : false}
                            onChange={(e) =>
                              setPaymentMethodToStorage(e.target.value)
                            }
                          ></Form.Check>
                        </Col>
                        <Col xs={11}>
                          <Form.Label>
                            <i className='fas fa-truck'></i> Cash On
                            Delivery(COD Charges &#8377;49)
                          </Form.Label>
                        </Col>
                      </Row> */}
                      <Row>
                        <Button
                          className='ldp-variant2'
                          id='COD'
                          name='paymentMethod'
                          value='cod'
                          onClick={(e) => submitHandler(e)}
                        >
                          <i className='fas fa-truck'></i> Cash On Delivery (COD
                          Charges &#8377;49)
                        </Button>
                      </Row>
                    </Form.Group>
                    <div style={{ textAlign: 'center' }}>
                      LEAD, READ & SUCCEED
                    </div>
                    {/* <br />
                    <Button
                      type='submit'
                      variant='primary'
                      className='placeorder-bt'
                    >
                      Place Order
                    </Button> */}
                  </Form>
                </FormContainer>
              </Row>
            </Container>
          </>
        )}
      </Container>
    </>
  )
}

export default ShippingScreen
