import React, { useCallback, useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { register } from '../actions/userActions'
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'
const SocialLogin = ({ history, userInputs }) => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(false)
  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success: registerSuccess, userInfo } = userRegister
  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState('')

  const onLoginStart = useCallback(() => {
    alert('login start')
  }, [])

  const onLogoutSuccess = useCallback(() => {
    setProfile(null)
    setProvider('')
    alert('logout success')
  }, [])

  const onLogout = useCallback(() => {}, [])

  const handleSuccess = (response) => {
    if (response.data) {
      var isSocial = true
      var params = {
        name: response.data.name,
        email: response.data.email,
        isSocial: isSocial,
        source: response.provider,
        externalId: response.data.id,
        accessToken: response.data.accessToken,
        userInputs: userInputs,
      }
      if (response.provider == 'google') {
        params.profileImage = response.data.picture
      } else {
        params.profileImage = response.data.picture.data
          ? response.data.picture.data.url
          : ''
      }
      dispatch(register(params))
    }
  }

  const handleError = (error) => {
    console.log(error)
  }
  useEffect(() => {
    if (registerSuccess && userInfo) {
      if (userInfo.error) {
        setErrorMessage(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch])
  return (
    <>
      {/* <LoginSocialFacebook
        appId={'703773024770476'}
        fieldsProfile={'name,email,gender,picture'}
        onResolve={handleSuccess}
        onReject={(err) => {
          console.log(err)
        }}
      >
        <FacebookLoginButton>
          <span style={{ fontSize: '17px' }}> Login with Facebook </span>
        </FacebookLoginButton>
      </LoginSocialFacebook> */}

      <LoginSocialGoogle
        client_id={
          '460556147769-9rj7n365fn9hgq8jv0cfsrsi0j0o7r6u.apps.googleusercontent.com'
        }
        scope='https://www.googleapis.com/auth/userinfo.email'
        onResolve={handleSuccess}
        onReject={(err) => {
          console.log(err)
        }}
      >
        <GoogleLoginButton />
      </LoginSocialGoogle>
    </>
  )
}

export default SocialLogin
