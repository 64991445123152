import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { createNewBookFair } from '../actions/bookFairActions'
import { SHIPPING_STATE } from '../constants/orderConstants'
import axios from 'axios'

const BookFairNewScreen = ({ location, history, getState }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [googleLink, setGoogleLink] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()
  const createBookfair = useSelector((state) => state.createBookFair)
  const { loading, error, bookfair, success } = createBookfair

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (success) {
        history.push('/admin/BookFairList')
      }
    }
  }, [dispatch, history, userInfo])
  const uploadHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createNewBookFair({
        name,
        startDate,
        endDate,
        address,
        description,
        image,
        startTime,
        endTime,
        city,
        state,
        zipCode,
        googleLink,
      })
    )
  }
  return (
    <Container>
      <Row>
        <Col md={6}>
          <h2>Create New Book Fair</h2>
          {error && <Message variant='danger'>{error}</Message>}
          {success && <Message variant='success'>UPDATED sUCCESSFULLY</Message>}
          {loading && <Loader></Loader>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={10}
                placeholder='Enter Event Description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='image'>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type='hidden'
                placeholder='Enter Image'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.File
                id='image-file'
                custom
                onChange={uploadHandler}
              ></Form.File>
              {uploading && <Loader></Loader>}
            </Form.Group>
            <Form.Group controlId='address'>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='city'>
              <Form.Label>City</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter City'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='zipCode'>
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter ZipCode'
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='state'>
              <Form.Label>State</Form.Label>
              <Form.Control
                as='select'
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                className='checkoutForm'
              >
                <option key='Select your State' value={''}>
                  Select your state
                </option>
                {SHIPPING_STATE.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='googelink'>
              <Form.Label>Google Link</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Google Link'
                value={googleLink}
                onChange={(e) => setGoogleLink(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='startDate'>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type='date'
                placeholder='Enter Start Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='endDate'>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type='date'
                placeholder='Enter End Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='startTime'>
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Start Time'
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='endTime'>
              <Form.Label>End Time</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter End Time'
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' varaint='primary'>
              Create New
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default BookFairNewScreen
