import React, { useState, useEffect } from 'react'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Container,
  ListGroup,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { myOrderDetailsReducer } from '../reducers/orderReducers'
import { LinkContainer } from 'react-router-bootstrap'
import { scanProduct, upsertProductDetails } from '../actions/productActions'
import { Link } from 'react-router-dom'

const ProductScanScreen = ({ location, history, getState }) => {
  const [isbn, setIsbn] = useState('')
  const [blocation, setBlocation] = useState('')
  const [price, setPrice] = useState('')
  const [mrp, setMrp] = useState(0)
  const [cover, setCover] = useState('')
  const [language, setLanguage] = useState('')
  const [type, setType] = useState('Used')
  const [inventory, setInventory] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [flag, setFlag] = useState(false)
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productScan = useSelector((state) => state.productScan)
  const { loading, success, error, product } = productScan

  const productUpsertDetails = useSelector((state) => state.productUpsert)
  const { success: upsertSuccees } = productUpsertDetails

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    if (isbn.length == 13 && !loading && !flag) {
      setFlag(true)
      dispatch(scanProduct(isbn))
    }
    if (success && product) {
      setFlag(false)
      setIsbn('')
      setPrice(product.book.salePrice)
      setInventory(product.book.countInStock)
      setBlocation(product.book.location)
    }
  }, [dispatch, history, userInfo, success, product])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(scanProduct(isbn))
  }

  const updateHandler = (e) => {
    e.preventDefault()
    dispatch(
      upsertProductDetails(
        product.book.isbn,
        price,
        inventory,
        blocation,
        cover,
        language,
        type,
        mrp
      )
    )
  }
  return (
    <Container>
      <Form onSubmit={submitHandler}>
        <Row>
          <h5>Scan</h5>

          <Col xs={9}>
            <Form.Group controlId='name'>
              <Form.Control
                type='text'
                placeholder='SCAN ISBN'
                value={isbn}
                autoFocus
                onChange={(e) => setIsbn(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Button type='submit' varaint='primary'>
              Scan
            </Button>
          </Col>
        </Row>
      </Form>
      {loading && <Loader></Loader>}
      {success && !product.book && (
        <Message variant='danger'>Not Found</Message>
      )}
      {product && upsertSuccees && <Message>Updated</Message>}
      {success && product.book && (
        <>
          <Row>
            <Col xs={4}>
              <img src={product.book.image} width='100px' />
            </Col>
            <Col xs={8}>
              <h4>{product.book.name}</h4>
              <div>
                OnWebsite: <strong>{product.isNew ? 'YES' : 'NO'}</strong>{' '}
                {!product.isNew && (
                  <small>
                    <br />
                    Qty: <strong>{product.book.countInStock}</strong>
                    <br />
                    Location: <strong>{product.book.location}</strong>
                  </small>
                )}
                <br />
                Price: <strong>{product.book.salePrice}</strong>
                <br />
                Rating :{' '}
                <strong>
                  {product.book.grating}/({product.book.gnumReviews})
                </strong>
                <br />
              </div>
            </Col>
          </Row>
          <Row>
            {product.book.categories.length > 0 && (
              <ListGroup.Item key='category'>
                <div>
                  Genre:
                  {product.book.categories.map((category) => (
                    <Link
                      to={`/category/${category.slug}`}
                      key={`${category.slug}`}
                      className='topic'
                    >
                      {' '}
                      {category.name}
                    </Link>
                  ))}
                </div>
              </ListGroup.Item>
            )}
          </Row>
          <Row>
            <Col>
              <Form onSubmit={updateHandler}>
                <Row>
                  <Col xs={6}>
                    <Form.Group controlId='price'>
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Price'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='price'>
                      <Form.Label>MRP</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter MRP'
                        value={mrp}
                        onChange={(e) => setMrp(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId='location'>
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        type='String'
                        placeholder='Enter Location'
                        value={blocation}
                        onChange={(e) => setBlocation(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Group controlId='inventory'>
                      <Form.Label>Inventory</Form.Label>
                      <Form.Control
                        type='Number'
                        placeholder='Enter Inventory'
                        value={inventory}
                        onChange={(e) => setInventory(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId='isEbook'>
                      <Form.Label>Book Cover</Form.Label>
                      <Form.Control
                        as='select'
                        aria-label='Select Book Type'
                        onChange={(e) => setCover(e.target.value)}
                      >
                        <option value='paperback' selected>
                          PaperBack
                        </option>
                        <option value='hardcover'>Hardcover</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Group controlId='isEbook'>
                      <Form.Label>Language</Form.Label>
                      <Form.Control
                        as='select'
                        aria-label='Select Book Type'
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        <option value='eng' selected>
                          English
                        </option>
                        <option value='kannada'>Kannada</option>
                        <option value='hindi'>Hindi</option>
                        <option value='tamil'>Tamil</option>
                        <option value='telugu'>Telugu</option>
                        <option value='malayalam'>Malayalam</option>
                        <option value='marathi'>Marathi</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId='isEbook'>
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        as='select'
                        aria-label='Select Book Type'
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value='Used' selected>
                          Used
                        </option>
                        <option value='New'>New</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Button type='submit' varaint='primary'>
                  Update
                </Button>
                {/* <Form.Group controlId='isActive'>
                  <Form.Check
                    type='checkbox'
                    value={true}
                    label='Is Active'
                    checked={true}
                    onChange={(e) => setIsActive(e.target.value)}
                  ></Form.Check>
                </Form.Group> */}
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default ProductScanScreen
