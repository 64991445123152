import { React, useEffect } from 'react'
import { adminOrderList } from '../actions/orderActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SearchBox from '../components/litplots/SearchBox'
import { listStoryReads } from '../actions/storyActions'
import { convertTimeAgo, timeConvert } from '../helpers/helpers'
import { Row, Col, Spinner, Alert, Container } from 'react-bootstrap' // Make sure to import Bootstrap components if using react-bootstrap

export const AdminDashboardScreen = ({ history }) => {
  const orderStats = useSelector((state) => state.adminOrderList)
  const { loading, error, data } = orderStats

  const storyReadList = useSelector((state) => state.storyReadList)
  const { reads, loading: readsloading, error: readserror } = storyReadList

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(adminOrderList())
    dispatch(listStoryReads())
  }, [dispatch])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>error</Message>
      ) : (
        <Container>
          <h3>Dashboard</h3>
          <Row>
            <h5>Orders</h5>
            <div className='adminBox'>
              <>
                <p>Today</p>
                <h6>
                  Orders:{data.thisDay.count}
                  <br />
                  {(
                    (data.thisDay.count / data.thisDay.abandonCartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>
                  Abandon Cart:{data.thisDay.abandonCartCount}
                  <br />
                  {(
                    (data.thisDay.abandonCartCount / data.thisDay.cartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>Cart:{data.thisDay.cartCount}</h6>

                <h4>{data.thisDay.sum.toLocaleString()}</h4>
              </>
            </div>
            <div className='adminBox'>
              <>
                <p>This week</p>
                <h6>
                  Orders:{data.thisWeek.count}
                  <br />
                  {(
                    (data.thisWeek.count / data.thisWeek.abandonCartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>
                  Abandon Cart:{data.thisWeek.abandonCartCount}
                  <br />
                  {(
                    (data.thisWeek.abandonCartCount / data.thisWeek.cartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>Cart:{data.thisWeek.cartCount}</h6>
                <h4>{data.thisWeek.sum.toLocaleString()}</h4>
              </>
            </div>
            <div className='adminBox'>
              <>
                <p>This Month</p>
                <h6>
                  Orders:{data.thisMonth.count}
                  <br />
                  {(
                    (data.thisMonth.count / data.thisMonth.abandonCartCount) *
                    100
                  ).toFixed(2)}
                </h6>
                <h6>
                  Abandon Cart:{data.thisMonth.abandonCartCount}
                  <br />
                  {(
                    (data.thisMonth.abandonCartCount /
                      data.thisMonth.cartCount) *
                    100
                  ).toFixed(2)}
                  %
                </h6>
                <h6>Cart:{data.thisMonth.cartCount}</h6>
                <h4>{data.thisMonth.sum.toLocaleString()}</h4>
              </>
            </div>
          </Row>
          <Row>
            <SearchBox history={history}></SearchBox>
          </Row>
          <Row>
            {readsloading ? (
              <Col>
                <Spinner animation='border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              </Col>
            ) : readserror ? (
              <Col>
                <Alert variant='danger'>Error: {readserror}</Alert>
              </Col>
            ) : (
              reads.map((read) => (
                <Col key={read._id} sm={12} className='mb-3'>
                  <div className='p-3 border rounded'>
                    <h6 className='mb-0'>
                      {read.user ? read.user.email : 'Anonymous'} reading{' '}
                      {read.story.title} (
                      {read.user && read.user.isSubscribed ? 'Paid' : 'Free'}) -{' '}
                      {convertTimeAgo(read.createdAt)}
                    </h6>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Container>
      )}
    </Container>
  )
}
