import axios from 'axios'
import {
  EBOOK_LIST_FAIL,
  EBOOK_LIST_REQUEST,
  EBOOK_LIST_SUCCESS,
} from '../constants/ebookConstants'

export const geteBooksList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EBOOK_LIST_REQUEST,
    })
    
    const { data } = await axios.get(`/api/ebook`)

    dispatch({
      type: EBOOK_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: EBOOK_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
